import EventTracker from '@puig/event-tracker/browser'

export const eventTracker = new EventTracker({
  appId: 'nocibe',
  apiURL: process.env.REACT_APP_APIURL,
  uuid: localStorage.getItem('nocibeId')
})
let previousLocation = null
export const trackViewEventHandler = (location, ...args) => {
  const { pathname, search } = location
  const searchParams = new Proxy(new URLSearchParams(search), {
    get: (searchParams, prop) => searchParams.get(prop)
  })
  let viewName

  if (previousLocation && previousLocation.pathname === pathname) {
    switch (pathname) {
      case '/recommendations':
      case '/profile':
        return
      default:
    }
  }
  switch (pathname) {
    case '/wizard':
      const step =
        searchParams.i_step !== null ? searchParams.i_step : searchParams.step
      switch (step) {
        case null:
          viewName = 'LANDING_PAGE'
          break
        case '0':
          viewName = 'LANDING_PAGE'
          break
        case '1':
          viewName = 'EXPLORE_BY_PERFUMES'
          break
        case '2':
          viewName = 'SET_CONCEPTS'
          break
        default:
      }
      break
    case '/profile':
      viewName = 'OWN_PROFILE'
      break
    case '/evaluations':
      viewName = 'EVALUATE'
      break
    case '/recommendations':
      viewName = 'RECOMMENDATIONS_VIEW'
      break
    case '/explorer':
      viewName = 'EXPLORE'
      break
    case '/howto':
      viewName = 'HELP'
      break
    default:
  }
  viewName && eventTracker.emitEvent('VIEW_OPENED', { type: viewName })

  previousLocation = location
}
