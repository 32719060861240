import React, { PureComponent } from "react";
import * as d3 from "d3";

class FamilyWedge extends PureComponent {

  render(){
    const arc = d3.arc()
      .outerRadius(this.props.radius)
      .innerRadius(this.props.radius - this.props.dr)
      .padAngle(0.007)

    // const offset = this.props.offset
    // const sdTheta = this.props.sdTheta
    const familyData = this.props.familyData

    // var startAngle = offset*sdTheta
    // var endAngle = startAngle + familyData.notes.length*sdTheta
    var startAngle = this.props.startAngle
    var endAngle = this.props.endAngle
    // startAngle = Math.PI-startAngle+sdTheta/2
    // endAngle = Math.PI-endAngle+sdTheta/2

    // const textOffset = 100*(offset+familyData.notes.length/2)*sdTheta/(2*Math.PI)

    // console.log('rendering family arc',this.familyColors(familyData.id))
    // const startAngle = angle-0.1
    // const endAngle = angle+0.1
    arc.startAngle(startAngle).endAngle(endAngle)
    // var path = 'M0 '+textRadius
    // path+= ' A '+textRadius+' '+textRadius+' 0 0 0 1 -'+textRadius
    // path+= ' A -'+textRadius+' '+textRadius+' 0 0 1 1 '+textRadius
    //
    // console.log("rendering family with d ",path)

    return (
      <g>

        <path
            d={arc()}
            // fill={this.props.familyColors(familyData.family.name)}
            fill={familyData.family.color}
            stroke="none"
            opacity = "1"
        />


      </g>
    );
  }
}

export default class FamiliesRing extends PureComponent {
  constructor(){
    super()
    this.instanceId = new Date().getTime()
  }

  render() {
    // console.log("                            ->(Disk:)FamiliesRing:render")
    const textRadius = this.props.radius - this.props.dr/2 - 1
    const textCirclePath =
      'M0 '+textRadius+' A '+textRadius+' '+textRadius+' 0 0 1 0 -'+textRadius+
      ' A '+textRadius+' '+textRadius+' 0 0 1 0 '+textRadius+
      ' A '+textRadius+' '+textRadius+' 0 0 1 0 -'+textRadius+
      ' A '+textRadius+' '+textRadius+' 0 0 1 0 '+textRadius

    var familyOffset = 0;
    let allNotes = this.props.families.reduce((res,f)=>res.concat(f.notes),[])
    return (<g>
      <defs>
        <path
              id={"text-circle-"+this.instanceId}
              d={textCirclePath}
        />
      </defs>
      {this.props.families.map((family, i) => {
        const currentOffset = familyOffset

        let startAngle = familyOffset*this.props.sdTheta
        familyOffset+=family.notes.length
        let endAngle = familyOffset*this.props.sdTheta


        // let textOffset = 50*(currentOffset+(family.notes.length-1)/2)*this.props.sdTheta/(2*Math.PI)
        let textOffset = 50*(currentOffset+(family.notes.length-1)/2+0.5)*this.props.sdTheta/(2*Math.PI) + 25
        let wordLength = family.family.name.length*8
        // let wedgeLength = (family.notes.length-1)*12
        let wedgeLength = (family.notes.length)*((2*Math.PI*this.props.radius)/allNotes.length)
        //ESTO VA MAL EN EL SIMPLIFIED!!?!??!?!
        //
        // console.log("family naming",family,wordLength,wedgeLength)
        let familyName = wordLength<wedgeLength?family.family.name:''//family.name[0]
        // console.log("familing",family.name,wordLength,wedgeLength,'->',familyName)
        wordLength = familyName.length*5

        return (
          <g key = {i}>
            <FamilyWedge
              // scents = {this.props.notes}
              // defs = {this.props.defs}
              // sdTheta = {this.props.sdTheta}
              // radius = {this.props.radius}
              // families = {this.props.families}
              // familyColors = {this.props.familyColors}
              // levelScale = {this.props.levelScale}
              {... this.props}
              familyData = {family}
              startAngle = {startAngle}
              endAngle = {endAngle}
              // offset = {currentOffset}
            />
            <text
              className="family-ring-label"
              // fontSize="14"
              dx={-wordLength}
            >
                <textPath
                  href={"#text-circle-"+this.instanceId}
                  startOffset={textOffset+'%'}
                  fontWeight="bold"
                  alignmentBaseline="middle"
                  dominantBaseline="middle"
                  fill="white"
                  stroke="none"
                >
                    {familyName.toUpperCase()}
                </textPath>
            </text>
          </g>
        )
      })}
    </g>)
  }

}