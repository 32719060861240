import React, { Component } from 'react'
import {connect} from 'react-redux'
import Donut from './Donut'
import './Profile.scss'
import NocibeButton from '../../common/NocibeButton'
import EvaluationView from './EvaluationView'
import {getUserFamilies} from '../../../store/selectors/getUserFamilies'
import YourFragrancesView from './YourFragrancesView'
import { withSize } from 'react-sizeme'
import IngItem from '../../common/IngItem'
import qs from 'qs'
import * as Utils from '../../../utils'
import t from '../../../i18n/strings'
import ProfileDesc from './ProfileDesc'
import PerfumesList from '../../common/PerfumesList'
import { isMobile } from 'react-device-detect'

class Profile extends Component {

  state = {}
  showEvaluate = () => {
    this.setState({showEvaluate: true})
  }
  hideEvaluate = () => {
    this.setState({showEvaluate: false})
  }
  goToRecos = () => {
    this.props.history.push('/recommendations')
  }
  showResetPopup = () => {
    let query = qs.parse(this.props.location.search.slice(1))
    query.reset = true
    this.props.history.push({search:qs.stringify(query)})
  }
  showSharePopup = () => {
    let query = qs.parse(this.props.location.search.slice(1))
    query.share = true
    this.props.history.push({search:qs.stringify(query)})
  }
  render() {
    let donutRadius
    if(isMobile) donutRadius = Math.min(this.props.size.width*0.9,this.props.size.height*1)
    else donutRadius = Math.min(this.props.size.width*0.4,this.props.size.height*0.75)
    let topIngs = Utils.getProfileMainIngredients(this.props.profile)
    if(topIngs.length<6) topIngs = Utils.getProfileIngredients(this.props.profile).slice(0,6)
    // let textIngs = otherIngs.map((i)=>{
    //   return <div className="ing-text" key={i.id}>{i.name}</div>
    // })

    let donutBlock = <div className="column main-donut-column">
        {/* <div className="title">{t("your_olfactive_profile")}</div> */}
        <Donut data={this.props.userFamilies} showNotes={false} showNames={true} width={donutRadius} height={donutRadius}></Donut>
      </div>
    // let stickyBlock = <div className="sticky-button">
    //   <Link className="link-button" to="/recommendations">
    //     <NocibeButton label={t('view_recomendations')}></NocibeButton>
    //   </Link>
    // </div>
    let ingredientsBlock = <div className="column ingredients-column">
      <div className="ingredients-title">{t('ingredients')} {`(${topIngs.length})`}</div>
        <div className="ingredients-list">
          {topIngs.map((i)=>{
            return <IngItem data={i} key={i.id}></IngItem>
          })}
        </div>
        {/* {textIngs.length >= 1 &&
          <div className="ingredients-less-dominant-wrapper">
            <div className="ingredients-less-dominant-title">{t("less_dominant")}</div>
            <div className="ingredients-less-dominant-list">
              {textIngs}
            </div>
          </div>
        } */}
      </div>
    let descriptionBlock = <div className="column description-column">
        <ProfileDesc></ProfileDesc>
      </div>

    let bottomLeftBlock = this.props.mode==='wizardProfile'?
        <div className="left"/>
      :
        <div className="left">
          <NocibeButton mode='empty' label={t('create_new_profile')} onClick={this.showResetPopup}></NocibeButton>
          {/* <NocibeButton mode='text' label={t('share')} onClick={this.showSharePopup}></NocibeButton> */}
        </div>

    let bottomRightBlock = this.props.mode==='wizardProfile'?
        <div className="right">
          <NocibeButton onClick={this.goToRecos} label={t('view_recomendations')}></NocibeButton>
        </div>
      :
        <div className="right">
          <NocibeButton label={t('evaluate_my_recomendations')} onClick={this.props.onShowEvaluate}></NocibeButton>
          <NocibeButton label={t('edit_my_profile')} onClick={this.props.onShowYourFragrances}></NocibeButton>
      </div>
    
    let perfumesBlock = this.props.profile.perfumes &&
      (<div className="selected_fragrances">
        <p>{t('selected_fragrances')} {`(${this.props.profile.perfumes.length})`}</p>
        <PerfumesList
          data={this.props.profile.perfumes.map(p => p.perfume)}
          showCheckbox={false}
        />
      </div>)
      

    if(isMobile){
      return <div className="section profile">
        <div className="content">
          <div className="top">
            {descriptionBlock}
            {donutBlock}
            {perfumesBlock}
          </div>
          <div className="bottom">
            {topIngs.length > 0 && ingredientsBlock}
            {this.props.mode!=='wizardProfile' && bottomRightBlock}
            {this.props.mode==='wizardProfile' && 
              <NocibeButton onClick={this.goToRecos} label={t('view_recomendations')}></NocibeButton>
             }
            {bottomLeftBlock}
          </div>
        </div>
        {this.props.state==='showEvaluate' &&
          <EvaluationView onClose={this.props.onHideEvaluate}></EvaluationView>
        }
        {this.props.state==='showYourFragrances' &&
          <YourFragrancesView onClose={this.props.onHideYourFragrances}></YourFragrancesView>
        }
      </div>
    } else {
      return <div className="section profile">
        <div className="content">
          <div className="top">
            <div className="desc-ing">
              {descriptionBlock}
              {topIngs.length > 0 && ingredientsBlock}
            </div>
              {donutBlock}
              {perfumesBlock}
          </div>
          <div className="bottom">
            {bottomLeftBlock}
            {bottomRightBlock}
          </div>
        </div>
        {this.props.state==='showEvaluate' &&
          <EvaluationView onClose={this.props.onHideEvaluate}></EvaluationView>
        }
        {this.props.state==='showYourFragrances' &&
          <YourFragrancesView onClose={this.props.onHideYourFragrances}></YourFragrancesView>
        }
      </div>
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    userFamilies: getUserFamilies(state),
  }
}
export default connect(mapStateToProps)(withSize({monitorHeight: true})(Profile));