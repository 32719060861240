import React, { Component } from 'react'
import * as Utils from '../../utils'
import PriceRanges from '../../assets/data/priceRanges.json'
import t from '../../i18n/strings'
// import Dropdown from 'react-dropdown'
import ReactSelect from 'react-select'
import './PerfumesFilter.scss'

export default class PerfumesFilter extends Component {
  state = {
    filters: {},
  }
  getFamilies = (perfumes) => {
    return [{value:'all',label:t('all')}].concat(Utils.getPerfumesFamilies(perfumes).map((f)=>{return {value:f.id,label:f.name}}))
  }
  getBrands = (perfumes) => {
    return [{value:'all',label:t('all')}].concat(Utils.getPerfumesBrands(perfumes).map((b)=>{return {value:b.id,label:b.name}}).sort((a,b)=>b.label<a.label?1:-1))
  }
  getPrices = (perfumes) => {
    return [{value:'all',label:t('all')}].concat(Utils.getPerfumesPrices(perfumes).map((p)=>{return {value:p,label:p}}))
  }
  getSeasons = (perfumes) => {
    return [{value:'all',label:t('all')}].concat(Utils.getPerfumesSeasons(perfumes).map((s)=>{return {value:s,label:t(s)}}))
  }
  getOptionsWithoutSelf = (type) => {
    let getPerfumeData = (p)=>{
      return p.perfume || p
    }

    let filtersWithoutSelf = {...this.state.filters}
    delete filtersWithoutSelf[type]
    let perfumesWithoutSelf = this.getFilteredData(filtersWithoutSelf, this.props.data)
    let optionsWithoutSelf, allOptions
    if(type==='family') {
      optionsWithoutSelf = this.getFamilies(perfumesWithoutSelf)
      allOptions = this.getFamilies(this.props.data)
    } else if(type==='brand') {
      optionsWithoutSelf = this.getBrands(perfumesWithoutSelf)
       allOptions = this.getBrands(this.props.data)
    } else if(type==='price') {
      optionsWithoutSelf = this.getPrices(perfumesWithoutSelf)
      let priceRanges = PriceRanges.filter((r)=>perfumesWithoutSelf.find((p)=>r.min<getPerfumeData(p).price && getPerfumeData(p).price<r.max))
      optionsWithoutSelf = [{value:'all',label:t('all')}].concat(priceRanges.map((r)=>{return {value:r.id,label:r.label}}))

      allOptions = this.getPrices(this.props.data);
      priceRanges = PriceRanges.filter((r)=>this.props.data.find((p)=>r.min<getPerfumeData(p).price && getPerfumeData(p).price<r.max))
      allOptions = [{value:'all',label:t('all')}].concat(priceRanges.map((r)=>{return {value:r.id,label:r.label}}))
    } else if(type==='season') {
      optionsWithoutSelf = this.getSeasons(perfumesWithoutSelf);
      allOptions = this.getSeasons(this.props.data);
    }

    allOptions.forEach((o)=>{
      if(optionsWithoutSelf.find((oo)=>oo.value===o.value)) o.disabled = false;
      else o.disabled = true
    })

    return allOptions
  }

  getFiltersOptions = () => {
    // let perfumes = this.props.data
    let options = {
      family: this.getOptionsWithoutSelf('family'),
      brand:  this.getOptionsWithoutSelf('brand'),
      price: this.getOptionsWithoutSelf('price'),
      season: this.getOptionsWithoutSelf('season'),
    }
    return options
  }
  onFilterChange = (key,option) => {
    let newFilters = {...this.state.filters}
    if(option.value === 'all') delete newFilters[key]
    else newFilters[key]=option
    this.setState({filters: newFilters})
    this.applyFilters(newFilters, this.props.data)
  }

  applyFilters = (newFilters, perfumes)=>{
    let result = this.getFilteredData(newFilters, perfumes)
    this.props.onChange(result)
  }

  getFilteredData = (filters,perfumes) => {
    let getPerfumeData = (p)=>{
      return p.perfume || p
    }
    let result = perfumes.filter((p)=>{
      if(filters.brand && getPerfumeData(p).brand.id !== filters.brand.value) return false
      if(filters.family && getPerfumeData(p).family.id !== filters.family.value) return false
      if(filters.price){
        let range = PriceRanges.find((r)=>r.id === filters.price.value)
        return range.min<getPerfumeData(p).price && range.max>getPerfumeData(p).price
      }
      // if(filters.season && Utils.computeSeason(getPerfumeData(p)) !== filters.season.value) return false
      if(filters.season && Utils.computeSeason(getPerfumeData(p)).indexOf(filters.season.value)<0) return false
      return true
    })
    return result
  }
  render () {
    let options = this.getFiltersOptions()
    let showBrand = !this.props.filterIds || this.props.filterIds.indexOf('brand')>=0
    let showFamily = !this.props.filterIds || this.props.filterIds.indexOf('family')>=0
    let showPrice = !this.props.filterIds || this.props.filterIds.indexOf('price')>=0
    let showSeason = !this.props.filterIds || this.props.filterIds.indexOf('season')>=0
    return (
      <div className="filters-wrapper">
        <div className={"filters expanded"}>
          {showBrand && <ReactSelect className="react-select" classNamePrefix="react-select" isOptionDisabled={(o)=>o.disabled} options={options.brand} onChange={(d)=>this.onFilterChange('brand',d)} value={this.state.filters.brand} placeholder={t('brand')} isSearchable={false}/>}
          {showFamily && <ReactSelect className="react-select" classNamePrefix="react-select" isOptionDisabled={(o)=>o.disabled} options={options.family} onChange={(d)=>this.onFilterChange('family',d)} value={this.state.filters.family} placeholder={t('olfactive_family')} isSearchable={false}/>}
          {showPrice && <ReactSelect className="react-select" classNamePrefix="react-select" isOptionDisabled={(o)=>o.disabled} options={options.price} onChange={(d)=>this.onFilterChange('price',d)} value={this.state.filters.price} placeholder={t('price')} isSearchable={false}/>}
          {showSeason && <ReactSelect className="react-select" classNamePrefix="react-select" isOptionDisabled={(o)=>o.disabled} options={options.season} onChange={(d)=>this.onFilterChange('season',d)} value={this.state.filters.season} placeholder={t('season')} isSearchable={false}/>}
        </div>
      </div>
    )
  }
}
