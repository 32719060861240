const lang = {
  //Wizard.jsx

  what_are_you_looking_for: 'Je recherche un parfum...',

  something_for_myself: 'Pour moi',

  a_gift_for_someone_else: 'Pour offrir',

  you_are_looking_for: 'Je recherche un parfum...',

  masculine: 'Masculin',

  femenine: 'Féminin',

  both: 'Les deux',

  $pronoun_self: 'vous',

  $pronoun_other: 'il/elle',

  $pronoun_other_F: 'elle',

  $pronoun_other_M: 'il',

  $possessive_singular_self: 'votre',

  $possessive_refl_singular_self: 'mon',

  $possessive_plural_self: 'vos',

  $possessive_refl_plural_self: 'mes',

  $possessive_singular_other: 'son',

  $possessive_singular_prep_self: 'vôtre',

  $possessive_singular_prep_other: 'sien',

  $possessive_singular_liste_self: 'votre',

  $possessive_singular_liste_other: 'sa',

  $possessive_plural_other: 'ses',

  $possessive_refl_singular_other: 'son',

  $possessive_refl_plural_other: 'ses',

  $you_self: 'vous',

  $you_other_F: 'elle',

  $you_other_M: 'il',

  $you_prep_self: 'vous',

  $you_prep_other_F: 'elle',

  $you_prep_other_M: 'lui',

  $do_you_have_self: 'avez vous',

  $do_you_have_other: 'a-t-il/elle',

  $do_you_have_other_F: 'a-t-il/elle',

  $do_you_have_other_M: 'a-t-il/elle',

  $that_you_love_self: 'que vous aimez',

  $that_you_love_other: "qu'ill/elle aime",

  $that_you_love_other_F: "qu'elle aime",

  $that_you_love_other_M: "qu'ill aime",

  $you_love_self: 'vous aimez',

  $you_love_other_F: 'elle aime',

  $you_love_other_M: 'il aime',

  $you_adore_self: 'vous adorez',

  $you_adore_other_F: 'elle adore',

  $you_adore_other_M: 'il adore',

  $apreciate_self: 'appréciez',

  $apreciate_other_F: 'apprécie',

  $apreciate_other_M: 'apprécie',

  $you_have_self: 'vous avez',

  $you_have_other_F: 'elle a',

  $you_have_other_M: 'il a',

  ok_lets_build_1:
    'OK! Nous allons créer ensemble $possessive_singular profil olfactif,',

  ok_lets_build_2:
    'Commencez par renseigner $possessive_plural parfums préférés...',

  what_is_your_favourite_fragrance:
    'Dites-nous quel est $possessive_singular parfum préféré',

  results: 'results',

  would_you_like_to_addd_another: '$do_you_have un autre parfum favori?',

  want_to_add_more: 'Vous pouvez ajouter un autre parfum',

  loading: 'Chargement en cours...',

  no_results: 'Aucun résultat! Essayez une autre recherche',

  selected_fragrances: 'Parfums sélectionnés',

  which_of_theese_words:
    'Selon vous, quels mots ci-dessous correspondent le plus à $possessive_singular parfum idéal. Choisissez 3 mots, ou plus, qui décrivent le mieux votre parfum.',

  at_least_3: '3 minimum',

  next_step: 'Suivant',

  skip: 'passer cette étape',

  step: 'Etape',

  search: 'Rechercher',

  search_a_perfume: 'Rechercher un parfum',

  your_olfactive_profile: '$possessive_singular profil olfactif',

  my_olfactive_profile: '$possessive_refl_singular profil olfactif',
  //HEADER
  header_revenir_au_site: 'Revenir au site',
  header_my_olfactive_profile: 'Profil olfactif',
  header_evaluations: 'Evaluations',
  header_recomendations: 'Recommandations',
  header_fragrance_explorer: 'Explorer',
  //Profile.jsx

  your_favourite_ingredients: '$possessive_plural ingrédients favoris',

  your_profile_description: '$you_love ...',

  you_love: '$you_love les parfums',

  but_also: '$you_have également une affinité particuliére por les nuances',

  family: 'famille',

  subfamily: 'sous-famille',

  families: 'familles',

  you_love_many:
    '$you_have un goût riche et $apreciate différentes familles olfactives.',
  you_love_few: '$you_have un goût bien défini en terme de famille olfactive.',
  you_love_fams_singular:
    'Vous aimez les parfums appartenant à la famille %FAMILY%',
  you_love_fams_plural:
    'Vous aimez les parfums appartenant aux familles %FAMILY%',
  mostly_intense:
    '$you_love les parfums plutôt intenses et qui ont du caractère.',
  mostly_balanced:
    '$you_love les parfums bien équilibrés. Ni trop intenses, ni trop légers.',
  mostly_fresh:
    '$you_love les parfums essentiellement légers, frais et faciles à porter',
  furthermore: 'De plus',
  furthermore_i_detect_single:
    'De plus, je vois que $you_have une préférence pour les nuances %FAMILY%.',
  furthermore_i_detect_broad:
    'Cependant, je vois que $pronoun $apreciate une large palette de nuances: %FAMILY%.',
  // furthermore_i_detect_single: 'De même, nous détectons que $you_have une préférence pour les nuances %FAMILY% dans $possessive_plural parfums préférés',
  // furthermore_i_detect_broad: 'De même, nous détectons que $you_have une large palette de nuances qui complètent les parfums $that_you_love',
  ingredeints_are_favourite_singular:
    '%INGS% est clairement $possessive_singular ingrédient favori.',
  ingredeints_are_favourite_plural:
    '%INGS% sont clairement $possessive_plural ingrédients favoris.',
  repeated_perfumer:
    'Saviez-vous que le parfumeur %PERFUMER% a signé %COUNT% de $possessive_plural parfums préférés?', // (%PERFUMES%)',

  reco_desc_title: 'Nous vous recommandons ce parfum car',
  possible_reco_title: 'Peut-être que $you_love ce parfum parce que',
  expert_reco: 'Recommandation des experts',
  recommended_by_hero: "$you_adore l'ingrédient <b>%HERO%</b>",

  recommended_by_fam:
    'appartient à une de $possessive_plural familles préférées, <b>%FAMILY%</b>',
  // not_recommended_by_fam: "Bien qu'il ait un profil olfactif différent du $possessive_singular_prep, il pourrait être intéressant pour $you_prep de le tester",
  not_recommended_by_fam:
    "Bien qu'il ait un profil olfactif différent du $possessive_singular_prep, ",

  // recommended_by_ings: 'contient certains de $possessive_plural ingrédients favoris: <b>%INGS%</b>',
  // recommended_by_ings_short: '(??)certains de ses ingrédients moins dominants: <b>%INGS%</b>',
  recommended_by_ings:
    'certains des ingrédients $that_you_love dominent dans ce parfum:',

  it: 'il',
  also: 'également',
  and: 'et',

  view_recomendations: 'Voir $possessive_refl_plural recommandations',

  share: 'Partager',
  share_this_experience: 'Partagez cette experience avec vos amis',
  or_copy_link: 'Ou copier le lien',
  copy: 'Copier',

  create_new_profile: 'Recréer $possessive_refl_singular profil',

  evaluate_my_recomendations: 'Evaluer $possessive_refl_plural recommandations',

  edit_my_profile: 'Affiner $possessive_refl_singular profil',

  view_my_profile: 'Passer à $possessive_refl_singular profil',

  my_recomendations_to_evaluate:
    'Evaluer vos recommandations pour affiner $possessive_singular profil et recevoir de nouvelles recommandations',

  apply: 'Appliquer',

  update_ratings: 'Appliquer',

  ratings_updated: 'Évaluations mises à jour',

  //Recomendations.jsx

  all: 'Tout',

  brand: 'Marque',

  olfactive_family: 'Famille Olfactive',

  price: 'Prix',

  season: 'Saison',

  top_6_recomendations: 'Top 4 Recommandations',

  all_recomendations: 'Toutes $possessive_plural recommandations',

  recomendations_according:
    'Sélectionnées en fonction de $possessive_singular profil olfactif',

  if_you_didnt_find:
    "Si vous n'avez pas trouvé $possessive_singular parfum idéal, modifiez $possessive_singular profil pour obtenir de nouvelles recommandations",

  //Explorer

  by_name: 'Explorer par marque',

  by_ingredients: 'Explorer par ingrédient',

  by_concepts: 'Explorer par mots clé',

  by_: 'Explorer par',

  _name: 'marque',

  _ingredients: 'ingrédient',

  _concepts: 'mots clé',

  back_to_brands: 'Voir marques',

  back_to_ingredients: 'Voir ingrédients',

  back_to_concepts: 'Voir mots clé',

  find_perfumes: 'Trouver des parfums',

  //PerfumeRow.jsx

  add_to_profile: 'Ajouter à $possessive_refl_singular profil',

  remove_from_profile: 'Supprimé de $possessive_refl_singular profil',

  view_details: 'En savoir +',

  select: 'Ajouter',

  from: 'à partir de',

  buy_it_now: 'Je decouvre!',

  //PerfumeCard

  profile_similarity: 'de concordance',

  evaluate_recomendation: 'Evaluer cette recommandation',

  add_to_wishlist: 'Ajouter aux coups de Coeur',
  remove_from_wishlist: 'Supprimer de coups de Coeur',

  added_to_wishlist: 'Ajouté aux coups de Coeur',
  removed_from_wishlist: 'Supprimé e coups de Coeur',

  wished: 'Ajouté aux coups de Coeur',
  new_recomendation: 'Nouvelle recommandation',

  //PerfumeFullView

  ingredients: 'Ingredients',

  description: 'En savoir plus',

  olfactive_families: 'Familles olfactives',

  your_favourite_ingredients_present:
    '$possessive_plural ingredients favoris présents dans ce parfum',

  //header/wishlist

  fragrance_explorer: "Explorer l'univers des parfums",

  recomendations: '$possessive_refl_plural recommandations',

  evaluations: '$possessive_refl_plural évaluations',

  wishlist: 'Coup de coeur',

  your_wish_list: 'Coup de coeur',

  your_wish_list_is_empty: '$possessive_singular_liste liste est vide',

  you_have_new_recomendations:
    '$possessive_singular profil et $possessive_plural recommandations ont été mis à jour',

  are_you_sure_you_want_to_create:
    'Etes-vous certain(e) de vouloir recréer $possessive_singular profil? Toutes vos précédentes données seront perdues?',

  save_my_profile: 'Sauvegarder',

  yes_create_new: 'Confirmer',

  yes_remove: 'Confirmer',

  cancel: 'Annuler',

  your_evaluations: '$possessive_refl_plural évaluations',

  your_fragrances: '$possessive_refl_plural parfums favoris',

  your_fragrances_list: '$possessive_refl_plural parfums favoris',

  your_fragrances_subtitle:
    'Pour obtenir de nouvelles recommandations, ajoutez ou retirez des parfums à $possessive_singular profil, modifiez leur évaluation ou ajoutez de nouvelles descriptions',

  add_fragrance: 'Ajouter un parfum',

  words_that_describe_you:
    'Les mots qui décrivent le mieux les parfums $that_you_love',

  add_descriptor: 'Ajouter une description',

  like_it: "J'adore",

  dont_like_it: 'Je déteste',

  let_us_know: 'Dites nous si vous aimez ce parfum!',

  your_recs_will_change:
    'Vos recommandations changeront en fonction de vos évaluations',

  thanks_for_rating: 'Merci pour votre évaluation',

  view_new_recs: 'Voir les nouvelles recommandations',

  less_dominant: 'Ingrédients moins dominant',

  also_less_dominant: "Ainsi que d'autres, présents plus subtilement",

  are_you_sure_remove_perfume:
    'Etes vous certain de vouloir supprimer ce parfum?',

  this_will_modify:
    'Cela modifiera $possessive_singular profil olfactif et $possessive_plural recommandations.',

  view_more_recs: 'Voir plus de recommandations',

  //MOBILE
  selected_perfumes: 'Selection',

  //////////////HOW IT WORKS/////////////
  how_it_works: 'Comment ça marche?',

  what_is_our_purpose: 'Quelle est</br>notre ambition?',
  make_the_choosing:
    "OFFRIR UN NOUVEAU SERVICE QUI PERMETTE AUX UTILISATEURS DE <a href='http://nocibe.fr' target='_blank'>NOCIBE.FR</a> DE CRÉER LEUR PROFIL OLFACTIF ET RECEVOIR DES RECOMMANDATIONS EN FONCTION DE CE PROFIL",
  how_dows_it_work: 'Comment ça marche?',
  your_profile_is_composed:
    'Votre profil olfactif est composé de vos familles olfactives préférées, de vos ingrédients favoris et des mots qui décrivent le parfum de vos rêves.',

  about_the_families: 'A propos des familles olfactives',
  each_perfume_belongs:
    'Chaque parfum appartient à une famille qui définit le caractère et la personnalité du parfum. Cette personnalité est la plupart du temps complexe et possède de multiples facettes. Sa facette principale est représentée dans le rond extérieur.</br>Cette information provient d’une base de données indépendante reconnue internationalement et pionnière dans la classification des fragrances.',

  about_the_ingredients: 'A propos des ingrédients',
  the_quadrificatoin_shows:
    'L’assemblage de cadres visuels montre les ingrédients dominants dans un parfum à savoir les ingrédients qui définissent le plus un parfum. Plus l’image est grande, plus l’ingrédient est dominant. Dans cet exemple, la « Fève Tonka » et le « Cacao » sont les ingrédients dominants, suivis par le « Jasmin », la « Tubéreuse » et « l’Amande ».<br>Y a-t-il plus d’ingrédients dans un parfum?</br>Oui, beaucoup plus!</br>Nous nous concentrons sur ceux qui donnent le caractère, la singularité à un parfum et qui une fois votre odorat entrainé sont reconnaissables.',

  are_ther_more_ingredients:
    'La qualité des ingrédients est-elle identique? Pas du tout. Il y a une grande diversité de qualité, spécialement dans les ingrédients naturels liées à leur rareté, leur concentration, leur processus de production entre autres. Un parfum c’est comme un manteau, il peut être fait avec de la laine de 1er catégorie ou avec le plus fin des cachemires. La différence précise de qualité n’est pas toujours facile à percevoir.',

  expert_diagnostic:
    'Un diagnostic expert, totalement indépendant, élaboré avec notre partenaire',

  the_recomendations_are_100: 'Les recommandations sont 100% indépendantes',
  scents_are_recomended:
    'Les parfums sont proposés à chaque utilisateur du diagnostic parfum en fonction de ses préférences et de son profil olfactif.',

  hundredpercent_perfumistic_point_of_view:
    'Un point de vue 100% expert parfums.',
  perfumes_are_the_heart:
    'Les experts parfums sont au cœur à la fois de la recommandation et de la visualisation de chaque proposition de parfum.</br> Comment pouvons-nous le faire? Chaque parfum est examiné et calibré par un expert parfums. Notre partenaire passe en revue rigoureusement les parfums les plus connus pour vérifier l’exactitude des recommandations. Dans de nombreux cas, la technologie (comme la Chromatographie) est utilisée pour étayer l’opinion des experts.',

  // bring_the_craft: "RAPPROCHEZ L'ARTISANAT DE LA PARFUMERIE SUR MESURE DES AMOUREUX DE PARFUMS.",
  // the_starting_point_was: "Notre point de départ était la soif de connaissances et de conseils d'experts dans le monde de la parfumerie que beaucoup de personne ont exprimé. « J'adore les parfums mais c'est compliqué de choisir et de savoir ce que j'aime ». Nous avons identifié que nous pouvions transposer le savoir-faire des  artisans parfumeurs d'une manière pédagogique et simple.",
  // how_does_it_work: "Mode de fonctionnement de l’application?",
  // the_quadrification_shows: "L’assemblage de cadres visuels montre les ingrédients dominants dans un parfum à savoir les ingrédients qui définissent le plus un parfum. Plus l'image est grande, plus l’ingrédient est dominant. Dans cet exemple, la « fève Tonka » et le « Cacao » sont les ingrédients dominants, suivis par le « Jasmin », la « Tubéreuse » et l’« Amande ».",
  // q_and_a: "Q&A",
  // are_there_more_ingredients: "Y a-t-il plus d'ingrédients dans un parfum? Oui, beaucoup plus!. Nous nous concentrons sur ceux qui donnent le caractère, la singularité à un parfum et qui une fois votre odorat entrainé sont reconnaissables.",
  // are_all_ingredients_the_same: "La qualité des ingrédients est-elle identique? Pas du tout. Il y a une grande différence de qualité, spécialement dans les ingrédients naturels liée à leur rareté, leur concentration, leur processus de production entre autres. Un parfum c’est comme un manteau gris, il peut être fait avec de la laine de première catégorie ou avec le plus fin des cachemires. La différence précise de qualité n’est facile à percevoir.",
  // expret_reviewed_means:"« Revu et analysé par notre expert » signifie que l'un de nos évaluateurs parfums a examiné la composition du parfum et sa classification au sein des  familles olfactives. Nous passons en revue les parfums systématiquement afin de nous assurer que la classification et la description soient exactes. Il s'agit d'un examen d’expert indépendant.",
  // this_field_shows_the_scent:"Le champ « Familles Olfactives » est structuré à partir de la classification « Fragrances of the World ».  Plusieurs modèles de classification des parfums sont disponibles et il n’existe pas d'accord universel à ce sujet. Nous avons opté pour le modèle « Fragrances of the world » parce qu’il est  la  source de données pour wikiparfum et également parce qu'il est pionnier dans le développement d'un langage commun pour les parfums.",
  // an_independent_perfumistic: "Point de vue</br>d’un expert parfums</br>indépendant",
  // the_recomendations_are_100: "Les recommandations sont 100% indépendantes",
  // scents_are_recomended: "Les parfums sont proposés à chaque utilisateur de l’application en fonction de ses préférences, de son profil olfactif et/ou de ses idées.",
  // hundredpercent_perfumistic_point_of_view: "Un point de vue 100%  expert parfums.",
  // perfumes_are_the_heart: "Les experts parfums sont au cœur à la fois de la recommandation et de la visualisation de chaque proposition de parfum. Comment pouvons-nous le faire? Chaque parfum est examiné et calibré par un expert parfums. Spécifiquement, nous passons en revue rigoureusement les parfums les plus populaires pour vérifier l'exactitude des recommandations. Dans de nombreux cas, nous utilisons la technologie (comme un chromatographe) pour étayer l'opinion des experts.",
  // partnerships: "Partenariat",
  // we_have_collaborated: "Nous avons collaboré avec « Fragrances of the World », le plus grand guide indépendant de classification des parfums. Publié pour la première fois en 1984 par Michael Edwards, c'est la «bible des parfums», la plus pédagogique et la plus qualitative qui existe aujourd'hui. Michael et son équipe évaluent chaque nouveau lancement de parfum et le classifient. Précisons que cette classification n’est appliquée que pour les produits pour lesquels Michael Edwards a fourni les informations.",
  // what_about_the_ideas: "Qu'en est-il de la section</br>« recherche par idées ».</br>Est-ce objectif ?  ",
  // yes_as_much_as_language: "Oui !  Tout d'abord, le choix des mots ou l’expression d’une idée est totalement indépendant de sorte qu'il ne favorise aucune marque ni s’appuie sur aucun “jargon marketing”. Deuxièmement, les experts parfums ont travaillé intensément à la liste de tous les mots proposés qui correspond aux mots qu’ils utilisent eux-mêmes pour décrire les familles olfactives, les ingrédients, les nuances et les intensités dans leur pratique professionnelle. Enfin, nous avons lié ce travail à un projet de neurosciences de MindSpeller qui permet de relier les mots utilisés par un consommateur aux mots utilisés dans l’univers du parfum. Nous sommes convaincus que cet angle de recherche aide à  comprendre et donc à cibler ce qui est précisément recherché.",
  // is_the_visualization: "La visualisation des proportions d’ingrédients est-elle vraiment objective, permet-elle de se projeter dans l’olfaction du parfum?",
  // as_much_as_it_can_be: "Elle l’est autant que possible car faite de façon indépendante et objective. Nous représentons les ingrédients les plus dominants dans le parfum. Ce sont les ingrédients principaux qui peuvent être « aisément » identifiés par un odorat aiguisé. Bien sur, le parfum final va contenir plus d’ingrédients que ceux présentés en dominance. De plus, les ingrédients varient en qualité, en  concentration et donc dans la façon dont ils vont impactés l’olfaction finale du parfum. Cet aspect qualité, concentration n'est pas visualisé dans WikiParfum.",
  // do_users_have_a_say: "Les utilisateurs peuvent-ils s’exprimer et donner leurs avis?",
  // we_gather_user_ratings: "Nous recueillons les évaluations des utilisateurs qui sont publiées une fois qu'elles atteignent la masse critique afin de communiquer une information représentative. Cependant, nous sommes sélectifs et nous nous focalisons sur les avis et feedbacks par famille olfactive. Nous pensons que ce qui compte pour un passionné de la famille “florale” est de lire ce que d'autres passionnés pensent d’un ingrédient, d’une note, d’un parfum particulier au sein de cette même famille.",
  welcome_to_explorer: 'Bienvenue sur Fragrance Explorer',

  concept_or: 'ou',

  new_product: 'nouveauté',
  why_is_this_recommendation: 'Nous vous recommandons ce parfum car...',

  //SEASONS
  WINTER: 'Hiver',
  SUMMER: 'Eté'
}

export default lang
