import React, { Component } from 'react'
import './ConceptTag.scss'

export default class ConceptTag extends Component {
  state = {}
  onClick = () => {
    this.props.onClick && this.props.onClick()
  }
  render () {
    return (
      <div
        className={'concept-tag' + (this.props.className ? ' '+this.props.className:'') + (this.props.selected ? ' selected' : '')}
        onClick={this.onClick}
      >
        <div className='text'>
          {(this.props.data.label || this.props.data.name).toUpperCase()}
        </div>
      </div>
    )
  }
}
