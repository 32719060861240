/* eslint-disable no-cond-assign */
import React, { Component } from "react";
// import * as d3 from "d3";
// import * as Utils from '../../../utils'
import "./MiniDonut.scss";

// import * as Utils from "../../utils.js"
export default class MiniDonut extends Component {

  render () {
    let mainColor = this.props.perfume.family.color //'#da0'
    let secondaryColor = this.props.perfume.secondaryFamily.color //'#49a'
    return (<div className="mini-donut-wrapper">
      <div className="mini-donut">
        <div className="outter-ring" style={{backgroundColor: mainColor}}></div>
        <div className="inner-ring" style={{backgroundColor: secondaryColor}}></div>
        <div className="center"></div>
      </div>
      <div className="labels">
        <div className="main">{this.props.perfume.family.name}</div>
        <div className="secondary">{this.props.perfume.secondaryFamily.name}</div>
      </div>
    </div>)
  }
}