import React, { Component } from 'react';
// import SquarePacking from './SquarePackingJSON'
import './QuadPack.scss'
import * as d3 from 'd3';
// import * as Utils from '../../../../utils'
import NicePacking from './NicePacking'
// import noImage from '../../../../assets/no-img.png'
// import ImageProvider from '../../ImageProvider'
// let imageProvider = ImageProvider.getInstance()
class QuadPack extends Component {
  constructor(props) {
    super(props);
    this.updateQuadPack = this.updateQuadPack.bind(this);
  }

  componentDidMount(){
    this.updateQuadPack()
  }

  componentDidUpdate(){
    this.updateQuadPack()
  }

  updateQuadPack() {


    const packMargin = 1
    const node = this.node;

    var root = d3.select(node)//.style('border','solid 1px #f00')
    //TODO: SHOULD EXTRACT THE CHILDREN THAT IS "INGREDIENT-BRANCH" TYPE INSTEAD OF JUST THE FIRST ONE
    var data = JSON.parse(JSON.stringify(this.props.data))
    // console.log(data)

    const height = 100;
    const width = 100;

    data.sort((a,b) => b.proportion - a.proportion )
    data = data.filter((d)=>d.proportion>0).slice(0,5)

    let pack = NicePacking().size([width, height]).value((d)=>d.proportion)
    let packData = pack(data)

    root.style('position','relative') //.style('height','100%')


    let items = root.selectAll(".item")
      .data(packData,function(d,i) {
        return d.data.ingredient.id //+'-'+d.data.ingredient.image
      })

    let itemsEnter = items.enter().append('div').attr('class','item')

    itemsEnter
      .on('click',(d)=>{
        this.props.onSelect && this.props.onSelect(d.data.ingredient.id)
      })



    // itemsEnter.append('text').style('font-weight','bold').attr('fill','#f00').text((d)=>d.data.id)
    // let newColor = "#"+((1<<24)*Math.random()|0).toString(16)
    itemsEnter
      // .attr('fill','rgba(0,0,0,0.1)')
      // .attr('fill','transparent')
      .style('position','absolute')
      .style("left", function(d) { return 'calc( '+(d.x0) + '% + '+ packMargin/2+'px)';})
      .style("top", function(d) { return 'calc( '+(d.y0) + '% + '+ packMargin/2+'px)';})
      .style("width", function(d) { return 'calc( '+(d.x1 - d.x0) + '% - '+ packMargin+'px)';})
      .style("height", function(d) { return 'calc( '+(d.y1 - d.y0) + '% - '+ packMargin+'px)';})
      // .style('background-color', newColor)
      // .style("background-image", (d) =>  d.data.ingredient.imageUrl ? 'url('+Utils.getIngredientImageUrl(d.data.ingredient.imageUrl, d.useNonSquareImage)+')' : '')
      // .style("background-size", 'cover')
      .style('opacity',0)
      // .transition().duration(600).delay((d,i)=>i*100).style('opacity',1)

    itemsEnter.append('div').attr('class','ing-name').append('div').attr('class','ing-name-label').text((d)=>d.data.ingredient.name)
    let defaultColorClass = this.props.defaultColor==='HEAVY'?' black':' white'
    itemsEnter.classed(defaultColorClass,true)
    itemsEnter.each(function (d) {
      let img = new Image()
      img.onload = ()=>{
        d3.select(this).append(()=>img)
      }
      img.onerror = ()=>{
        // d3.select(this).style('background-image',`url(${noImage})`)
        d3.select(this).classed('no-image',true)
        // d3.select(this).append('div').attr('class','ing-name').text(d.data.ingredient.name)
      }
      img.src = d.data.ingredient.imageUrl
      // img.src = imageProvider.getImageUrl('ingredients/'+d.data.ingredient.imageUrl)
    })
    items.exit().transition().duration(600).style('opacity',0).remove()

    items = items.merge(itemsEnter)
    items.classed('selected',(d)=>{return this.props.selection && d.data.ingredient.id===this.props.selection})
    items.classed('unselected',(d)=>{return this.props.selection && d.data.ingredient.id!==this.props.selection})
    items
      .style("left", function(d) { return 'calc( '+(d.x0) + '% + '+ packMargin/2+'px)';})
      .style("top", function(d) { return 'calc( '+(d.y0) + '% + '+ packMargin/2+'px)';})
      .style("width", function(d) { return 'calc( '+(d.x1 - d.x0) + '% - '+ packMargin+'px)';})
      .style("height", function(d) { return 'calc( '+(d.y1 - d.y0) + '% - '+ packMargin+'px)';})
      .transition().duration(600).delay((d,i)=>i*100).style('opacity',1)
  }

  render() {
    return (
      <div className="quad-pack">
        <div className="root"
          ref={node => this.node = node}

          >
	      </div>
	    </div>

    )
  }
}

export default QuadPack;
