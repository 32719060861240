import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
// import { connectRouter, routerMiddleware } from 'connected-react-router'
// import thunk from 'redux-thunk'
// import createHistory from 'history/createBrowserHistory'
import rootReducer from './modules'
import { Tracker } from 'react-tracker'
import trackingMiddleware from '../tracking/trackingStoreMiddleware'
import TagManager from 'react-gtm-module'
import pageView from '../tracking/listeners/pageView'
import gaEvent from '../tracking/listeners/gaEvent'

const tagManagerArgs = {
    gtmId: 'GTM-WBSR8W7'
}
 
TagManager.initialize(tagManagerArgs)

const tracker = new Tracker([pageView,gaEvent])

const initialState = {}
const enhancers = []
// const middleware = [
//   thunk,
//   routerMiddleware(history)
// ]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(thunk),
  applyMiddleware(trackingMiddleware(tracker)),
  ...enhancers
)

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
)

store.tracker = tracker
export default store