import React, { Component } from 'react'
import './Wizard.scss'
import BetterInput from '../../common/BetterInput'
import PerfumesList from '../../common/PerfumesList'
import { addPerfume, addConcepts, removePerfume, removeConcepts } from '../../../store/modules/profile'
import { setGender } from '../../../store/modules/session'
import { setForWho } from '../../../store/modules/session'
import Api from '../../../services/Api'
import Axios from 'axios'
import qs from 'qs'
import {withRouter} from 'react-router'
// import * as Utils from '../../../utils'
import { connect } from 'react-redux'

import PerfumeRow from '../../common/PerfumeRow'
import { getUserFamilies } from '../../../store/selectors/getUserFamilies'
import DonutColumn from '../profile/DonutColumn'
import NocibeButton from '../../common/NocibeButton'
import { CSSTransition } from 'react-transition-group'
import t from '../../../i18n/strings';
import ConceptSelectionSplit from '../profile/ConceptSelectionSplit'
import { isMobile } from 'react-device-detect'
import { eventTracker } from '../../../services/tracker'
function parseHTML (string) {
  return { __html: string }
}

class ButtonsQuestion extends Component {
  state = {
    currentAnswer: null,
    showCurrentPerfumes: false
  }
  onAnswer (question,answer) {
    if(question.value === 'gender') this.props.dispatch( setGender(answer.value) )
    if(question.value === 'for-who') this.props.dispatch( setForWho(answer.value) )
    this.setState({currentAnswer: answer})
    setTimeout(()=>{
      this.props.onAnswer(question,answer)
    },800)
  }
  render () {
    let options = this.props.data.options.map(o => {
      let answerClass = this.state.currentAnswer?(this.state.currentAnswer.value === o.value ? 'right-answer':'wrong-answer'):''
      return (
        <div
          className={'option '+answerClass}
          key={o.value}
          onClick={() => this.onAnswer(this.props.data, o)}
        >
          <div className='label' dangerouslySetInnerHTML={parseHTML(o.label)} />
        </div>
      )
    })
    return (
      <div className={'question buttons-question ' + this.props.data.value}>
        {this.props.data.label && this.props.data.label !== '' &&  <div className='title'>{this.props.data.label}</div>}
        <div className='options'>{options}</div>
      </div>
    )
  }
}
ButtonsQuestion = connect()(ButtonsQuestion)

let cancelReq;
class PerfumesQuestion extends Component {
  // signal = Axios.CancelToken.source()
  state = {
    query: null,
    finishedQuery: null,
    currentQuery: null,
    results: [],
    // showExplanation: !this.props.skipIntro
    showExplanation: false
  }
  onChange = value => {
    this.setState({
      query: value
    })
  }
  componentDidMount () {
    if(this.state.showExplanation){
      setTimeout(()=>{
        this.setState({showExplanation: false})
      },6000)
    }
  }
  componentDidUpdate () {
    if(this.state.query === this.state.currentQuery || this.state.query === this.state.finishedQuery){
      return
    }
    if (this.state.query === '' || !this.state.query) {
      this.setState({
        results: [],
        isLoading: false,
        finishedQuery: this.state.query
      })
      return
    } else if(this.state.query && this.state.query.length>1) {
      this.setState({
        isLoading: true,
        results: [],
        currentQuery: this.state.query
      })
      this.requestPerfumes()
    }
  }
  async requestPerfumes () {

    cancelReq && cancelReq()
    try {
      const response = await Api.get(
        // ahora va sin gender, porque se pide luego...
        // 'http://localhost:3001/api/nocibe'+
        // 'https://i9ssyfh0k6.execute-api.us-east-2.amazonaws.com/api/search?strategy=nocibe'+
        process.env.REACT_APP_APIURL+`/v7/search?`+
        `&q=${this.state.query}`,
        // process.env.REACT_APP_APIURL +  `/perfumes/?searchTerm=${this.state.query}`, //&gender=${this.props.session.gender}
        { cancelToken: new Axios.CancelToken(function executor(c){cancelReq = c}), params:{hostId:'1'}, withCredentials: false }
      )
      const results = await response.data.data
      eventTracker.emitEvent('SEARCH_PERFUMES',{
        topResults: results.map(perfume => perfume.id),
        data:{
          queryString:this.state.query
        },
        type:'TEXT'
      })
      this.setState({
        results: results,
        isLoading: false,
        finishedQuery: this.state.query,
        currentQuery: null
      })
    } catch (error) {
      // this.setState ({error: error.message, isLoading: false});
      if (Axios.isCancel(error)) {
        console.log('Error: ', error.message) // => prints: Api is being canceled
      } else {
        this.setState({
          error: error.message,
          isLoading: false,
          finishedQuery: this.state.query
        })
      }
    }
  }
  onPerfumeSelect = perfume => {
    if (this.props.profile.perfumes.find(p => p.id === perfume.id)) {
      this.props.dispatch(
        removePerfume(perfume)
      )
    } else {
      this.props.dispatch(
        addPerfume({ id: perfume.id, perfume: perfume, value: 5, from: 'wizard' })
      )
    }
    // this.input.resetQuery()
    // this.setState({query: null})
  }
  onPerfumeRemove = perfume => {
    this.props.dispatch(removePerfume(perfume))
  }
  render () {
    let titleClass =
      this.state.query === '' || !this.state.query ? 'large' : 'small'

    let questionTitle = t('what_is_your_favourite_fragrance')
    if (titleClass === 'large' && this.props.profile.perfumes.length > 0) {
      questionTitle = t('would_you_like_to_addd_another')
    }

    let resultsToRender = this.state.results

    if (this.props.profile && this.props.profile.perfumes && this.props.profile.perfumes.length > 0) {
      resultsToRender = resultsToRender.filter(r => !this.props.profile.perfumes.map(p => p.id).includes(r.id))
    }

    return (
      <div className='question perfumes-question'>
        <div className='perfumes-left'>
          <div className={'title ' + titleClass}>{questionTitle}</div>
          <BetterInput
            ref={el => (this.input = el)}
            onSearchQueryChange={this.onChange}
            placeholder={t('search_a_perfume')}
          />
          <div className="results-wrapper">
            {this.state.results.length > 0 &&
              <p className="results-count">{this.state.results.length} {t('results')}</p>
            }
            <PerfumesList
              data={resultsToRender}
              onSelect={this.onPerfumeSelect}
              showCheckbox={true}
              showSelectButton={!isMobile}
              onCheckboxChange={this.onPerfumeSelect}
            />
            {this.props.profile.perfumes.length > 0 && !this.state.finishedQuery && !this.state.isLoading &&
              <div className="loading">
                <div className="reminder-message">
                  <div className="text">{t('want_to_add_more')}</div>
                </div>
              </div>
            }
            {this.state.isLoading &&
              <div className="loading">
                <div className="text">{t('loading')}</div>
              </div>
            }
            {(this.state.finishedQuery && !this.state.isLoading && this.state.results.length===0) &&
              <div className="no-results">
                <div className="text">{t('no_results')}</div>
              </div>
            }
          </div>



          {isMobile && this.props.profile.perfumes.length>0 &&
            <div className="mobile-actions">
              <NocibeButton
                className='button'
                label={t('selected_perfumes')+' ('+this.props.profile.perfumes.length+')'}
                onClick={()=>this.setState({showCurrentPerfumes:true})}
              />
              <NocibeButton
                className='button'
                label={this.props.skipIntro?t('view_my_profile'):t('next_step')}
                onClick={this.props.skipIntro?()=>this.props.history.replace('/profile'):this.props.onAnswer}
              />
            </div>
          }
        </div>
        <div className="perfumes-right-wrapper">
          {isMobile &&
            <div className={"panel-background "+(this.state.showCurrentPerfumes?'':'hidden')} onClick={()=>this.setState({showCurrentPerfumes: false})}>
              <div className="close-button">&times;</div>
            </div>
          }
          <div className={'perfumes-right '+(((!isMobile && this.props.profile.perfumes.length) || (isMobile && this.state.showCurrentPerfumes))?'':'hidden')}>
            <div className='title'>
              {t('selected_fragrances')} ({this.props.profile.perfumes.length})
            </div>
            <div className='selection'>
              {this.props.profile.perfumes.map(p => {
                let perfume = p.perfume
                return (
                  <PerfumeRow
                    data={perfume}
                    key={perfume.id}
                    onClick={this.props.onClick}
                    showRemove
                    onRemove={this.onPerfumeRemove}
                    showExtraInfo={false}
                    highlightable={false}
                  />
                )
              })}
            </div>
          </div>
        </div>
        <CSSTransition
          in={this.state.showExplanation}
          timeout={500}
          classNames="cover-transition"
          unmountOnExit
        >
          <div className="explanation-cover" onClick={()=>this.setState({showExplanation: false})}>
            <div className="explanation-content">
              {t('ok_lets_build_1')} <br/> {t('ok_lets_build_2')}
            </div>
            <NocibeButton
              className='button'
              label={t('next_step')}
              onClick={()=>this.setState({showExplanation: false})}
            />
          </div>
         </CSSTransition>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    ...state,
    userFamilies: getUserFamilies(state)
  }
}

PerfumesQuestion = connect(mapStateToProps)(withRouter(PerfumesQuestion))

class ConceptsQuestion extends Component {

  state = {
    isLoading: true,
    concepts:null
  }
  async requestConcepts () {
    this.setState({
      isLoading: true,
    })
    try {
      const response = await Api.get(
        process.env.REACT_APP_APIURL +  `/nocibe/main_concepts/`
      )
      const results = await response.data
      // console.log(results)
      this.setState({
        concepts: results.slice(0,30), //FAKE, cuando vengan los que tocan ya no hara falta
        isLoading: false
      })
    } catch (error) {
      // this.setState ({error: error.message, isLoading: false});
      if (Axios.isCancel(error)) {
        console.log('Error: ', error.message) // => prints: Api is being canceled
      } else {
        this.setState({
          error: error.message,
          isLoading: false
        })
      }
    }
  }
  componentDidMount () {
    this.requestConcepts()
  }
  onConceptClick = concept => {
    let currentSelection = this.props.profile.concepts
    let candidate = currentSelection.find(cs => cs.id === concept.id)
    if (candidate) {
      this.props.dispatch(removeConcepts(concept))
    }else{
      this.props.dispatch(addConcepts(concept))
    }
  }
  onSkip = () => {
    this.props.onAnswer(null)
  }

  render () {
    return (
      <div className='question concepts-question'>
        <div className="title large">{t('which_of_theese_words')}</div>
        {this.props.profile.perfumes.length<2 && <div className="subtitle">{t('at_least_3')}</div>}
        {/* <ConceptSelection concepts={this.state.concepts} onClick={this.onConceptClick} conceptsSelected={this.props.profile.concepts}/> */}

        {/* Commented for showing just the mocked concepts */}
        {this.state.isLoading &&
          <div className="loading">
            <div className="text">{t('loading')}</div>
          </div>
        }

        {!this.state.isLoading&&
          // <div style={{maxHeight:window.innerHeight-333, overflowY:'auto'}}>
          <div className="concepts-wrapper">
            <ConceptSelectionSplit concepts={this.state.concepts} onClick={this.onConceptClick} conceptsSelected={this.props.profile.concepts}/>
          </div>
        }

        <NocibeButton
          className='button next-button'
          label={this.props.profile.perfumes.length<2?t('next_step'):(this.props.profile.concepts.length<3?t('skip'):t('next_step'))}
          enabled={this.props.profile.perfumes.length<2?this.props.profile.concepts.length>2:true}
          onClick={this.props.onAnswer}
        />

      </div>
    )
  }
}

ConceptsQuestion = connect(mapStateToProps)(ConceptsQuestion)

class Wizard extends Component {
  questions = [
    {
      value: 'for-who',
      type: 'buttons',
      label: t('what_are_you_looking_for'),
      index: 0,
      options: [
        {
          label: t('something_for_myself'),
          value: 'self',
          icon: 'icon-person.svg'
        },
        {
          label: t('a_gift_for_someone_else'),
          value: 'gift',
          icon: 'icon-gift.svg'
        }
      ]
    },
    {
      value: 'gender',
      type: 'buttons',
      label: '',
      index: 0,
      options: [
        {
          label: t('femenine'),
          value: 'F',
          icon: 'icon-femenine.svg'
        },
        {
          label: t('masculine'),
          value: 'M',
          icon: 'icon-masculine.svg'
        },
        {
          label: t('both'),
          value: 'U',
          icon: 'icon-both.svg'
        }
      ]
    },
    {
      value: 'perfumes',
      type: 'perfumes',
      index: 1,
      showDonut: true
    },
    {
      value: 'concepts',
      type: 'concepts',
      index: 2,
      showDonut: true
    }
  ]
  state = {
    currentStep: 0,
    answered: []
  }
  onAnswer = (question, questions) => {
    const nextAnswered = this.state.answered.indexOf(question.value) !== -1 ? this.state.answered : [...this.state.answered, question.value]
    if (questions.length === nextAnswered.length) {
      this.setState({ answered: [] })
      if (this.state.currentStep < this.getIndexes().length - 1) {
        // this.setState({ currentStep: question.index + 1 })
        this.props.history.push({ search: 'i_step=' + (question.index + 1), state: { from: this.props.location.state && this.props.location.state.from } })
      } else {
        this.props.onEnd()
      }
    } else {
      this.setState({ answered: nextAnswered })
    }
  }
  UNSAFE_componentWillMount(){
    this.parseQueryParams(this.props)
  }
  UNSAFE_componentWillReceiveProps(props){
    this.parseQueryParams(props)
  }
  parseQueryParams(props){
    let forced = false
    let queryStep = qs.parse(props.location.search.slice(1)).step
    if(queryStep) forced = true
    else queryStep = qs.parse(props.location.search.slice(1)).i_step
    if(queryStep){
      this.setState({currentStep: +queryStep, forcedStep: forced})
    }
  }
  onContinue() {
    if (this.props.skipIntro) {
      this.props.history.replace('/profile')
    } else {
      this.props.history.replace('wizard?i_step=2')
    }
  }

  getIndexes() {
    return this.questions.reduce((acc, curr) => {
      if (acc.indexOf(curr.index) === -1) {
        return [...acc, curr.index]
      }
      return acc
    }, [])
  }

  getQuestionsData() {
    return this.questions.filter(q => q.index === this.state.currentStep)
  }

  render () {
    let currentStep = null
    let questionsData = this.getQuestionsData()
    let backgroundClass = ''
    currentStep = (
      <CSSTransition classNames="question-transition" timeout={1400} key={questionsData[0].index}>
        <div className="questions-wrapper">
          {questionsData.map(questionData => {
            let q
            if (questionData.type === 'buttons') {
              if (questionData.value === 'for-who') backgroundClass = 'bkg-1'
              else backgroundClass = 'bkg-3'
              q = (
            
                <ButtonsQuestion
                  data={questionData}
                  key={questionData.value}
                  onAnswer={() => this.onAnswer(questionData, questionsData)}
                />
              )
            } else if (questionData.type === 'perfumes') {
              backgroundClass = 'white'
              q = (
                <PerfumesQuestion
                  data={questionData}
                  key={questionData.value}
                  onAnswer={() => this.onAnswer(questionData, questionsData)}
                  skipIntro={this.state.forcedStep}
                />
              )
            } else if (questionData.type === 'concepts') {
              backgroundClass = 'bkg-2'
              q = (
                <ConceptsQuestion
                  data={questionData}
                  key={questionData.value}
                  onAnswer={() => this.onAnswer(questionData, questionsData)}
                />
              )
            }
            return q
          })}
        </div>
      </CSSTransition>
    )

    const showDonut = this.getQuestionsData().some(q => q.showDonut)

    return (
      <div className='section wizard' key={this.props.location.state}>
        <div className='wizard-header'>
          {this.getIndexes().map((q, i) => {
            return <div
              key={i}
              className={'step' + (this.state.currentStep + 1 === i + 1 ? ' selected' : '') + (this.state.currentStep < i ? ' disabled' : '') }
              onClick={()=>{
                if (this.state.currentStep > i) {
                  this.props.history.push({
                    pathname: '/wizard',
                    search: `?i_step=${i}`
                  })
                }
              }}
              >
                {t('step')} {i + 1}
              </div>
          })}
          <div className='bar' style={{ width: ((this.state.currentStep + 1) / this.getIndexes().length) * 100 + '%' }}
          />
        </div>
        <div className='wizard-content'>
          <div className='left'>
            <div className={'background '+backgroundClass}></div>
            {[currentStep]}
          </div>
          {!isMobile && this.state.currentStep === 1 &&
            <div className={'right '+(showDonut?'visible':'hidden')}>
              <div className="cut-wrapper">
                <DonutColumn showTitle={true} skipIntro={this.props.skipIntro} showDesc={true} onContinue={this.onContinue.bind(this)} />
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Wizard)
