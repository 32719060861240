import React, { Component } from 'react'
import SearchBy from './SearchBy'
import Slider from './Slider'
import {debounce} from 'throttle-debounce';
import './FindBy.scss'
import SearchPerfumeResults from './SearchPerfumeResults';
import NocibeButton from '../../common/NocibeButton';
import PerfumeFullView from '../../common/PerfumeFullView';
import PerfumeAndPanel from '../../common/PerfumeAndPanel';
import t from '../../../i18n/strings';
import { isMobile } from 'react-device-detect';
import { eventTracker } from '../../../services/tracker';
class SearchSelection extends Component {
  _onChange = (item) => {
      // this.state.selection.find((s) => s.id === item.id).proportion = item.proportion
      // this.setState ({
      //   selection: this.state.selection.slice(0)
      // })
    this.props.onChange(item.id,item.proportion)
  }

  onChange = debounce(400, this._onChange)

  render () {
    let items = this.props.data.map((item)=>{
      return  <Slider
        key={item.id}
        name={item.ingredient.name}
        imageUrl={item.ingredient.imageUrl}
        id={item.id}
        proportion={item.proportion}
        onChange={this.onChange}
        onRemove={this.props.onRemove}
      />
    })
    return <div className="selection-list">
      {items}
    </div>
  }
}

export default class FindByIngredients extends Component {
  state = {
    selection: []
  }
  componentDidMount(){
    eventTracker.emitEvent('VIEW_OPENED', { type: 'EXPLORE_BY_INGREDIENTS' })
  }
  handleSelection = (selection)=> {
    this.setState({selection: selection.map((i)=>{
      if(i.proportion) return i
      else return {proportion: 100,ingredient: i,id:i.id}
    })})
  }
  updateSelection = (ingredientId,proportion) => {
    this.setState({selection: this.state.selection.map((i)=>{
      if(i.id !== ingredientId) return {ingredient:i.ingredient,id:i.id,proportion:i.proportion}
      else return {ingredient:i.ingredient,id:i.id,proportion:proportion}
    })})
  }
  removeFromSelection = (ingredientId) => {
    this.setState({selection: this.state.selection.filter((i)=>i.id !== ingredientId)})
  }

  getPerfumes = () => {
    this.setState({query:this.state.selection})
    this.props.onSetQuery("true")
  }
  onPerfumeSelect = (perfume) => {
    this.setState({currentPerfume: perfume})
  }
  onPerfumeClose = (perfume) => {
    this.setState({currentPerfume: null})
  }
  UNSAFE_componentWillReceiveProps = (newProps) =>{
    if(this.state.query && !newProps.currentQuery) this.setState({query: null})
  }
  render () {
    return <div className="sub-section by-ingredients">
      <div className={'search-cols '+(isMobile && this.state.query?'mobile-hidden':'')}>
        {isMobile && <div className="back-col">
          <div className="back-button" onClick={()=>{this.setState({query:null})}}>{t('back_to_ingredients')}</div>
        </div>}
        <div className="main-col">
          <SearchBy endpoint="/ingredients" groupBy="family.name" onSelect={this.handleSelection} selection={this.state.selection} clientFilterBoolean={true}></SearchBy>
        </div>
        <div className={"second-col "+(this.state.selection.length>0?'visible':'hidden')}>
          <div className="cut-content">
            <SearchSelection data={this.state.selection} onChange={this.updateSelection} onRemove={this.removeFromSelection}></SearchSelection>
            <div className="go-button-wrapper">
              <NocibeButton label={t("apply")} onClick={this.getPerfumes}></NocibeButton>
            </div>
          </div>
        </div>
      </div>
      <SearchPerfumeResults query={this.state.query && `/perfume_search/by_ingredients/`} body={this.state.query} onPerfumeSelect={this.onPerfumeSelect}></SearchPerfumeResults>
      <PerfumeAndPanel in={this.state.currentPerfume!=null} data={this.state.currentPerfume} onBackgroundClick={this.onPerfumeClose}>
        <PerfumeFullView data={this.state.currentPerfume} onClose={this.onPerfumeClose}></PerfumeFullView>
      </PerfumeAndPanel>
    </div>
  }
}