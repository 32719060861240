import React, {Component} from 'react';
import PerfumesList from '../../common/PerfumesList'
import Api from '../../../services/Api'
import Axios from 'axios';
import './SearchPerfumeResults.scss'
import 'react-dropdown/style.css'
import t from '../../../i18n/strings';
import PerfumesFilter from '../../common/PerfumesFilter';
import {connect} from 'react-redux'
import { isMobile } from 'react-device-detect';
import { eventTracker } from '../../../services/tracker';

class SearchPerfumeResults extends Component {
  signal = Axios.CancelToken.source ();

  state = {
    isLoading: false,
    error: null,
    data: [],
    filtered: null
  };

  async componentDidUpdate (prevProps) {
    // GUARRERO....
    if (prevProps && prevProps.query === this.props.query){
      if (prevProps && prevProps.body === this.props.body) return;
      else if (!this.props.body) return
    }
    if(this.props.query) this.setState({isLoading: true, error: false})
    else {
      this.setState({isLoading: false, error: false})
      return
    }

    try {
      let response;
      if(this.props.body){
        let propperyBody = this.props.body.map (q => {
          return {ingredientId: q.id, proportion: q.proportion / 10};
        });
        /*let sum = propperyBody.reduce ((s, pq) => {
          return s + pq.proportion;
        }, 0);
        if (sum > 1) {
          propperyBody.forEach (pq => (pq.proportion = pq.proportion / sum));
        }*/
        let url = process.env.REACT_APP_APIURL+this.props.query+'?hostId=1'
        //if(this.props.session.gender && this.props.session.gender !== 'U') url = url + `&gender=` + this.props.session.gender+',U'
        response = await Api.post (
          // process.env.REACT_APP_APIURL+this.props.query+'?hostId=1&gender='+this.props.session.gender,
          url,
          propperyBody
        );
      }
      else{
        let url = process.env.REACT_APP_APIURL+this.props.query+'&hostId=1'
        //if(this.props.session.gender && this.props.session.gender !== 'U') url = url + `&gender=` + this.props.session.gender+',U'
        response = await Api.get (
          // process.env.REACT_APP_APIURL+this.props.query+'&hostId=1&gender='+this.props.session.gender,
          url,
          // '/data/212.search.json',
          {cancelToken: this.signal.token}
        );
      }
      let data = await response.data;
      // POR SI LLEGA WRAPPEADO CON UN "similarity"
      if (data[0] && data[0].similarity) data = data.map((d)=>d.perfume)
      //CAPAMOS a saco...
      // data = data.slice(0,20)
      this.setState({ data: data, isLoading: false, filtered: null });
      let type;
      if (this.props.query.indexOf('by_brand') !== -1)
        type = 'TEXT'
      if (this.props.query.indexOf('by_ingredients') !== -1)
        type = 'INGREDIENT'
      if (this.props.query.indexOf('by_concepts') !== -1)
        type = 'CONCEPTS'
      if (data && data.length > 0) {
        eventTracker.emitEvent('SEARCH_PERFUMES', { type, topResults: data.map(p => p.id) })
      }

    } catch (error) {
      // this.setState ({error: error.message, isLoading: false});
      if (Axios.isCancel (error)) {
        console.log ('Error: ', error.message); // => prints: Api is being canceled
      } else {
        this.setState ({error: error.message, isLoading: false});
      }
    }
  }

  componentWillUnmount () {
    this.signal.cancel ('Api is being canceled');
  }

  onListClicked = () => {
    if (this.props.collapsed) this.props.onListRestore ();
  };


  refilter = (results) =>{
    this.setState({filtered: results})
  }
  render () {
    const {data, isLoading, error} = this.state;

    let filtered = this.state.filtered || data

    // Habria que hacer un content/error_layer/loading_layer, pero esta chungo con los estilos como estan...
    if (error) {
      return <div>{error}</div>;
    }
    if (!this.props.query){
      return <div></div>
    }
    if (isLoading) {
      return (
        <div className="search-perfume-results">
          <div className="loading"><div className="text">{t('loading')}</div></div>
        </div>
      );
    }
    if (data.length === 0) {
      return (
        <div className="search-perfume-results">
          <div className="no-results">
            <div className="text">{t('no_results')}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="search-perfume-results">
          <div className="filter-bar">
            <PerfumesFilter data={data} ref={(d)=>this.filters=d} onChange={this.refilter}></PerfumesFilter>
          </div>
          <PerfumesList
            data={filtered}
            // collapsed={this.props.collapsed}
            // comparing={this.props.comparing}
            selectedPerfume={this.props.selectedPerfume}
            onSelect={this.props.onPerfumeSelect}
            showViewDetails={!isMobile}
            showCheckbox={false}
            onViewDetails={this.props.onPerfumeSelect}
            showAddToFavorites={true}
            // highlightable = {false}
            // useTiles={false}
            // onClick={this.onListClicked}
            >
          </PerfumesList>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return state
}
export default connect(mapStateToProps)(SearchPerfumeResults);
