import qs from 'qs'
import Api from '../../services/Api'
import { replaceProfile } from './profile'
import { setGender, setForWho } from './session'
import { v4 as uuidv4 } from 'uuid'
import { eventTracker } from '../../services/tracker'
export const SET_DATA = 'user/SET_DATA'
export const RESET_USER = 'user/RESET_USER'

//BASURA! como no poemos acceder al "react-router-dom" i el window.location.search va mal con hashtags...
// let search = qs.parse(window.location.search.slice(1))

let search = qs.parse(window.location.href.split('?')[1])
let externalId = search.tempid || search.userId || search.userid
let rcuId = search.id
if (externalId) externalId = externalId.split('#')[0]
if (rcuId) rcuId = rcuId.split('#')[0]

const initialState = {
  nocibeId: externalId || localStorage.getItem('nocibeId') || uuidv4(),
  linked: rcuId !== undefined || localStorage.getItem('linked') === 'true',
  rcuId: rcuId || localStorage.getItem('rcuId') || null
}
eventTracker._uuid = initialState.nocibeId
localStorage.setItem('nocibeId', initialState.nocibeId)
localStorage.setItem('linked', initialState.linked)
if (initialState.rcuId) localStorage.setItem('rcuId', initialState.rcuId)

// if (search.id) {
//   let linkId = search.id.split('#')[0]
//   const linkItem = {
//     rcuid: linkId,
//     tempid: initialState.nocibeId
//   }
//   Api.postLinkItem(linkItem)
// }

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        ...action.data
      }
    case RESET_USER: {
      // Va con la guarrada de "store/modules/index.js"
      let newId = uuidv4()
      localStorage.setItem('nocibeId', newId)
      eventTracker._uuid = newId
      return {
        nocibeId: newId
      }
    }
    default:
      return state
  }
}
export const resetUser = () => {
  return function (dispatch) {
    dispatch({
      type: RESET_USER
    })
  }
}
export const getUserData = () => {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.

  return function (dispatch) {
    // First dispatch: the app state is updated to inform
    // that the API call is starting.

    // dispatch(requestUserData())

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.

    return Api.getUser().then(
      async response => {
        // return response.data
        console.log('Existing user. Will load')
        let localProfile = {}
        localProfile.perfumes = response.data.favoritePerfumes.map(p => {
          return {
            id: p.perfume.id,
            value: p.rating,
            perfume: p.perfume
          }
        })
        // localProfile.perfumes =  localProfile.perfumes.filter((p)=>p.perfume)
        // delete response.data.favoritePerfumes
        // delete response.data.favoriteFullPerfumes

        localProfile.wishes = response.data.wishListPerfumes.map(p => {
          return {
            id: p.id,
            perfume: p
          }
        })
        // localProfile.wishes =  localProfile.wishes.filter((p)=>p.perfume)
        // delete response.data.wishList
        // delete response.data.wishListFullPerfumes

        localProfile.concepts = response.data.favoriteConcepts
        delete response.data.favoriteConcepts

        // SET USER DATA
        await dispatch(setData({ ...response.data, loaded: true }))

        // OVERRIDE PROFILE DATA
        let action = replaceProfile(localProfile)
        await dispatch(action)

        // SET SESSION IF PRESENT
        if (response.data.gender !== null)
          dispatch(setGender(response.data.gender))
        if (response.data.gift !== null)
          response.data.gift === true
            ? dispatch(setForWho('other'))
            : dispatch(setForWho('self'))

        // Lo quitamos de aqui! y que se haga "manuamente" despues de cada "getUserData" o no....
        // dispatch(getRecomendations())
      },
      error => {
        console.log('User doesnt exist! Will create a new one...', error)
        dispatch(setData({ loaded: true, newUser: true }))
      }
    )
    // .then(data => {
    //   data.loaded = true
    //   dispatch(setData(data))
    // })
  }
}
export const setData = data => {
  return dispatch => {
    dispatch({
      type: SET_DATA,
      data: data
    })
  }
}
