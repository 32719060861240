import React, { Component } from 'react'
import './PerfumeCard.scss'
import ConnectedWithRating from './ConnectedWithRating'
import * as Utils from '../../utils'
import NocibeButton from './NocibeButton'
import MiniDonut from './MiniDonut'
import t from '../../i18n/strings'
// import TextTruncate from 'react-text-truncate'
import Truncate from 'react-truncate';
import { eventTracker } from '../../services/tracker'
import FamiliesWithIcon from './FamiliesWithIcon'
class PerfumeCard extends Component {
  state = {}
  similarityString = () => {
    return this.props.similarity
      ? Math.round(100 * this.props.similarity) + '%'
      : 'n.a.'
    // VERY FAKE:
    // return this.props.similarity?Math.round(100*this.props.similarity)+'%':Math.round(Math.random()*30)+70+'%'
  }
  onNewHover = () => {
    this.setState({hoveringNew: true})
    setTimeout(()=>{
      this.setState({hoveringNew: false})
    },600)
  }

  buyItNow = () => {
    eventTracker.emitEvent('ACTION', {
      type: 'BUY', data: { perfumeId: this.props.data.id }
    })
    return window.open(Utils.addTrackingInfo(this.props.data.productUrl))
  }

  render () {
    let nameAndBrand = (
      <div className='name-and-brand'>
        <div className='brand'>
          {this.props.data.brand.name +
            (this.props.data.productLine
              ? ' - ' + this.props.data.productLine
              : '')}
        </div>
        <div className='name'>
          {/* <span>{this.props.data.name}</span>
        <span>{this.props.data.perfumeType}</span> */}
          {/* <TextTruncate
            line={2}
            element='span'
            truncateText='…'
            text={this.props.data.name}
            // textTruncateChild={<a href="#">Read on</a>}
          /> */}
          <Truncate lines={2} ellipsis="…">
            {this.props.data.name}
          </Truncate>
        </div>
        {this.props.showStars && (
          <div className='stars'>
            <div className='star'></div>
            <div className='star'></div>
            <div className='star'></div>
            <div className='star'></div>
            <div className='star'></div>
          </div>
        )}
      </div>
    )
    return (
      <div
        className='perfume-card'
        onClick={() =>
          this.props.onViewDetails && this.props.onViewDetails(this.props.data)
        }
      >
        <div
          className={
            'card-content ' + (this.props.isNew ? 'is-new-recommendation' : '')
          }
        >
          <div className='info-top'>
            <div className='info-left'>
              {this.props.nameOnTop && nameAndBrand}
              {this.props.data.newProduct && <div className="new-tag">{t('new_product')}</div>}
              {this.props.familiesTop && <FamiliesWithIcon family={this.props.data.family} subfamily={this.props.data.secondaryFamily} />}
            </div>

            <div className='info-right'>
              {/* NOT USED {this.props.showHeart &&
                <div className="heart-wrapper" onClick={this.props.onToggleWished}>
                  <div className={"heart "+(this.props.wished?'checked':'')}></div>
                  {this.props.wished && <div className="tooltip">{t('remove_from_wishlist')}</div>}
                  {!this.props.wished && <div className="tooltip">{t('add_to_wishlist')}</div>}
                </div>
              } */}
              {this.props.showSimilarity && (
                <div className='similarity-wrapper'>
                  <div className='circle'>
                    <div className='value'>{this.similarityString()}</div>
                  </div>
                  {/* <div className="description">{t('profile_similarity')}</div> */}
                </div>
              )}
              {this.props.showMiniDonut && (
                <MiniDonut perfume={this.props.data}></MiniDonut>
              )}
            </div>
          </div>
          <div
            className='image'
            onClick={this.props.onClickImage}
            style={{
              backgroundImage:
                'url(' +
                Utils.getPerfumeImageUrl(this.props.data.imageUrl) +
                ')'
            }}
          />
          <div className='info-bottom'>
            {this.props.isNew && (
              <div className={'new-recomendation-box '+(this.state.hoveringNew?'hover':'')} onMouseLeave={this.onNewHover}>
                <div className='marker'></div>
                <div className='tooltip'>{t('new_recomendation')}</div>
              </div>
            )}
            {!this.props.nameOnTop && nameAndBrand}
            {!this.props.familiesTop && <FamiliesWithIcon family={this.props.data.family} subfamily={this.props.data.secondaryFamily} />}
            {!this.props.hidePrice && <div className='price'>
              <div className='from'>{t('from')}</div>
              <div className='euros'>{Utils.formatPrice(this.props.data)}</div>
            </div>}
            <div className='actions'>
              {this.props.showShowDetails && (
                <NocibeButton
                  mode='text'
                  className='details'
                  onClick={() => this.props.onViewDetails(this.props.data)}
                >
                  {t('view_details')}
                </NocibeButton>
              )}
              {this.props.showBuyIt && this.props.data.productUrl && (
                <NocibeButton
                  className='buy-it-button'
                  onClick={this.buyItNow}
                >
                  {t('buy_it_now')}
                </NocibeButton>
              )}
              {this.props.showEvaluate &&
                this.props.session['for-who'] === 'self' && (
                  <NocibeButton
                    mode='empty'
                    className='evaluate-button'
                    onClick={this.props.onEvaluatePerfume}
                  >
                    {t('evaluate_recomendation')}
                  </NocibeButton>
                )}
              {this.props.showAddToWishlist &&
                this.props.session['for-who'] === 'self' && (
                  <NocibeButton
                    mode='wish'
                    active={this.props.wished}
                    className='wishlist-button'
                    onClick={this.props.onToggleWished}
                  >
                    {!this.props.wished && t('add_to_wishlist')}
                    {this.props.wished && t('wished')}
                  </NocibeButton>
                )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ConnectedWithRating(PerfumeCard)
