import React, { Component } from 'react'
import BetterInput from '../../common/BetterInput'
import {connect} from 'react-redux'
import './SearchBy.scss'
import Api from '../../../services/Api'
import Axios from 'axios';
import t from '../../../i18n/strings';
import { getFieldFromString } from '../../../utils/objectUtils'

class SearchByBox extends Component {
  handleQueryChange = (query) => {
    this.setState({query})
  }


  render () {
    return (
      <div
        className={'search-container ' + this.props.className}
        onClick={this.props.onClick}
      >
        <div className="search-bar-wrapper">
          <BetterInput onSearchQueryChange={this.props.onChange}/>
        </div>
        {/* <div className="arrow" /> */}
      </div>
    );
  }
}
class SearchByResults extends Component{
  signal = Axios.CancelToken.source ();

  state = {
    isLoading: true,
    error: null,
    data: [],
  };

  componentDidMount () {
    this.componentDidUpdate()
  }
  async componentDidUpdate (prevProps) {
    if (prevProps && prevProps.query === this.props.query) return;

    try {
      let url = process.env.REACT_APP_APIURL+this.props.endpoint+`/?searchTerm=${this.props.query}&hostId=1&${this.props.searchParams}`
      // if(this.props.session.gender && this.props.session.gender !== 'U') url = url + `&gender=` + this.props.session.gender 
      const response = await Api.get (
        // process.env.REACT_APP_APIURL+this.props.endpoint+`/?searchTerm=${this.props.query}&hostId=1&gender=${this.props.session.gender}`,
        url,
        // '/data/212.search.json',
        {cancelToken: this.signal.token}
      );
      const data_raw = await response.data;

      let data = data_raw
      if (this.props.clientFilterBoolean){
        data=data_raw.filter((item)=>{
          if (typeof item.name == 'string' && typeof this.props.query == 'string') return item.name.toLowerCase().indexOf(this.props.query.toLowerCase())>=0
          return false
        })
      }

      this.setState ({data: data, isLoading: false});
    } catch (error) {
      // this.setState ({error: error.message, isLoading: false});
      if (Axios.isCancel (error)) {
        console.log ('Error: ', error.message); // => prints: Api is being canceled
      } else {
        this.setState ({error: error.message, isLoading: false});
      }
    }
  }

  componentWillUnmount () {
    this.signal.cancel ('Api is being canceled');
  }

  onListClicked = () => {
    if (this.props.collapsed) this.props.onListRestore ();
  };

  render () {
    const { data, isLoading, error } = this.state;
    let sortedData = data.sort((a,b)=>b.name>a.name?-1:1)
    if (this.props.groupBy){
      const getGroupKey = (obj) => {

        //let groupByKeys = this.props.groupBy.split('.')
        const result = getFieldFromString(obj,this.props.groupBy)
        return Array.isArray(result) ? result[0] : result
      }
      const groupBy = keyFunc => array =>
        array.reduce((objectsByKeyValue, obj) => {
          const value = keyFunc(obj);
          objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
          return objectsByKeyValue;
        }, {});
      let grouped = groupBy(getGroupKey)(sortedData)
      sortedData = Object.keys(grouped).sort((a,b)=>b<a?1:-1).reduce((result,groupKey)=>{
        result.push({name:groupKey,id:groupKey,type:"group",children: grouped[groupKey]})
        // result = result.concat(grouped[groupKey])
        return result
      },[])
    }

    let items
    if(this.props.groupBy){
      items = sortedData.map((d)=>{
        return <div className={"result-group " +((this.props.query || this.state.expandedGroup===d.id)?'expanded':'collapsed')} key={d.id} >
          <div className={"result-item group "} key={d.id} onClick={()=>this.setState({expandedGroup:d.id===this.state.expandedGroup ? null : d.id })}>{d.name}</div>
          <div className="group-children">
            {d.children.map((dd) => {
              return <div className={"result-item element"} key={dd.id} onClick={()=>this.props.onSelect(dd)}>{dd.name}</div>
            })}
          </div>
        </div>
      })
    } else {
      items = sortedData.map((d)=>{
        return <div className={"result-item"+(d.type?' '+d.type:' element')} key={d.id} onClick={()=>this.props.onSelect(d)}>{d.name}</div>
      })
    }


    // Habria que hacer un content/error_layer/loading_layer, pero esta chungo con los estilos como estan...
    if (error) {
      return <div>{error}</div>;
    }
    // if (!this.props.query){
    //   return <div></div>
    // }
    if (isLoading) {
      return (
        <div className="list">
          <div className="loading"><div className="text">{t('loading')}</div></div>
        </div>
      );
    }
    if (data.length === 0) {
      return (
        <div className="list">

          <div className="no-results">
            <div className="text">{t('no_results')}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={"list"+(this.props.groupBy?' grouped':'')}>
          {items}
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    ...state
  }
}
SearchByResults = connect(mapStateToProps)(SearchByResults)
export default class SearchBy extends Component {
  state = {
    // selection: [],
    query: '',
  }
  handleQuery = query => {
    this.setState({
      query: query
    })
  }
  onResultSelect = (d)=>{
    let newSelection = (this.props.selection && this.props.selection.slice()) || []
    if(newSelection.findIndex((sd)=>sd.id === d.id)>=0) newSelection = newSelection.filter((sd)=>sd.id!==d.id)
    else newSelection.push(d)
    this.props.onSelect(newSelection)
    // this.setState({selection:newSelection})
  }
  render () {
    return <div className="search-by">
      <SearchByBox onChange={this.handleQuery}/>
      <SearchByResults query={this.state.query} searchParams={this.props.searchParams} groupBy={this.props.groupBy} endpoint={this.props.endpoint} onSelect={this.onResultSelect} clientFilterBoolean={this.props.clientFilterBoolean}/>
    </div>
  }
}