export const SET_LANG = 'language/SET_LANG'


const initialState = {
  lang: 'fr'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANG:
      return {
        ...state,
        lang: action.data
      }

    default:
      return state
  }
}

// export const setLang = () => {
//   return dispatch => {
//     dispatch({
//       type: SET_LANG
//     })
//   }
// }
