import React from 'react';
// import PropTypes from 'prop-types';
// import {withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import './Slider.scss';
// import MuiThemeProvider from 'material-ui/lib/MuiThemeProvider'

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

// const styles = {
//   root: {
//     width: 300,
//     backgroundColor: '$dorado'
//   },

//   Slider: {
//     padding: '122px 0px',
//     fontFamily: 'Meta-Book',
//     color: '$dorado'

//   },
//   button: {
//     backgroundColor: '$dorado'
//   }
// };

class SimpleSlider extends React.Component {
  state = {
    value: this.props.proportion
  };

  handleChange = (event, value) => {
    this.setState({value});
    this
      .props
      .onChange({id: this.props.id, proportion: value})
  };

  render() {
    const {name, imageUrl} = this.props;
    const {value} = this.state;

    return (
      <div className="slider-wrapper">
        <div className="left">
          <div className="image" style={{backgroundImage:  'url('+imageUrl+')'}}></div>
        </div>
        <div className="right">
          <div className="top">
            <div className="slider-ingredient">{name}</div>

          </div>
          <div className="bottom">
            {/* <div className="value">{Math.round (value)} %</div> */}
            <Slider
              className="slider"
              value={value}
              aria-labelledby="label"
              onChange={this.handleChange}/>
            <div className="remove-button" onClick={()=>this.props.onRemove(this.props.id)}></div>
          </div>
        </div>

      </div>
    );
  }
}

// SimpleSlider.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default SimpleSlider
