import React, { Component } from 'react'
import Donut from './Donut'
import './DonutColumn.scss'
import { withSize } from 'react-sizeme'
import ProfileDesc from './ProfileDesc'
import t from '../../../i18n/strings';
import {isMobile} from 'react-device-detect'
import RecoDesc from '../recomendations/RecoDesc'
import NocibeButton from '../../common/NocibeButton'
import { connect } from 'react-redux'


class DonutColumn extends Component {

  isWizard(){
    return window.location.href.indexOf('wizard') !== -1
  }

  state = {
  }
  render (){
    let showDesc = this.props.showDesc || this.props.showDesc === undefined
    let showTitle = this.props.showTitle || this.props.showTitle === undefined
    if(showDesc) showTitle = false
    
    let descType = this.props.descType || 'profile'
    let descComponent
    if(descType === 'profile'){
      descComponent = <ProfileDesc shortVersion={isMobile}></ProfileDesc>
    } else {
      descComponent = <RecoDesc shortVersion={isMobile} data={this.props.reco}></RecoDesc>
    }
    let radius = this.props.size.width*(this.props.radiusPercent || 0.85)
    return (
      <div className={"donut-column" + (showTitle ? ' centered' : '')}>
          {showTitle && <div className="title">{t('your_olfactive_profile')}</div> }
          {showDesc && <div className="description-column">{descComponent}</div> }
          {descType==='profile' && <Donut showNotes={false} width={radius} height={radius}></Donut>}
          {descType === 'reco' && <Donut showNotes={false} width={radius} height={radius}></Donut>}
        {this.props.profile.perfumes.length > 0 && this.isWizard() && (
          <NocibeButton
            className='button'
            secondary={true}
            label={this.props.skipIntro?t('view_my_profile'):t('next_step')}
            onClick={this.props.onContinue}
          />
        )}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    ...state
  }
}
export default connect(mapStateToProps)(withSize()(DonutColumn))