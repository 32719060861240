import {getUserFamilies} from '../store/selectors/getUserFamilies'
import store from '../store/store'
// import * as Utils from '../utils'
/**
 * Simple redux middleware to use redux actions as input of tracking!
 * this will call the track function from the provided instance of tracker on every action
 * and use the action type as the event type and the action payload as the event data
 * @param {Object} tracker 
 */
const trackingMiddleware = tracker => (store) => next => action => {
  let trackingData
  switch (action.type) {
    //SESSION
    case 'session/SET_FORWHO':
      trackingData = {
        'event-category': 'Choix_moi_offrir',
        'event-action': action.data==='self'?'pour moi':'pour offrir',
        'event-label' : 'lancement test',
        'event': 'gaEvent'
      }
      break;
    case 'session/SET_GENDER':
      trackingData = {
        // Homme, Femme, Les deux
        'event-category': 'Choix_genre',
        'event-action': action.data==='F'?'Femme':(action.data==='M'?'Homme':'Les deux'),
        'event-label' : 'recherche_parfum',
        'event': 'gaEvent'
      }
      break;
      
    //PROFILE 
    case 'profile/ADD_PERFUME':
      if(action.data.from === 'wizard'){
        trackingData = {
          'event-category' : 'Produits_choisis',
          'event-action' : action.data.perfume.name,
          //TODO: SHOULD BE SKU and not perfume.id...
          'event-label' : action.data.perfume.id+'|'+action.data.perfume.brand.name,
          'event': 'gaEvent'
        }
      } 
      // else {
      //   trackingData = {
      //     'event-category' : 'Evaluations',
      //     'event-action' : action.data.perfume.name,
      //     'event-label' : action.data.value,
      //     'event': 'gaEvent'
      //   }
      // }
      break;
    case 'profile/ADD_CONCEPTS':
      trackingData = {
        'event-category' : 'Mots_choisis',
        'event-action' : action.data[0].name,
        'event-label' : 'choix',
        'event': 'gaEvent'
      }
      break;   
      
    // when getting recomendations we report the current profile
    // as well as "all" recomended perfumes??
    case 'profile/SET_RECOMENATIONS':
      // let state = store.getState()
      // trackingData = [{
      //     'event-category': 'profil_olfactif',
      //     'event-action': getUserFamilies(state).map((f)=>f.family.name).join('|'),
      //     'event-label' : 'resultat_test', //Utils.getProfileIngredients(state.profile).slice(0,6).map((i)=>i.name).join('|'),
      //     'event': 'gaEvent'
      //   }]
      // trackingData = trackingData.concat(action.data.slice(0,5).map((r)=>{
      //   return {
      //     'event-category' : 'Produits_proposes',
      //     'event-action' : r.name,
      //     'event-label' : r.id+'|'+r.brand.name,
      //     'event': 'gaEvent'
      //   }
      // }))
      break;
    
    default:
      trackingData = null
  }
  if(trackingData) {
    if(!Array.isArray(trackingData)) trackingData = [trackingData]
    trackingData.forEach((td)=>{
      console.log("tracker.trackEvent(",td,')')
      tracker.trackEvent({type: 'GA_EVENT', data: td});
    })
  }
  next(action);
};
export function manuallyTrackResult() {
  let state = store.getState()
  let trackingData = {
      'event-category': 'profil_olfactif',
      'event-action': getUserFamilies(state).map((f)=>f.family.name).join('|'),
      'event-label' : 'resultat_test', //Utils.getProfileIngredients(state.profile).slice(0,6).map((i)=>i.name).join('|'),
      'event': 'gaEvent'
    }
  
  console.log("tracker.trackEvent(",trackingData,')')
  store.tracker.trackEvent({type: 'GA_EVENT', data: trackingData});
  
}
export function manuallyTrackRecos() {
  let state = store.getState()
  let trackingData = state.profile.recomendations.slice(0,5).map((r)=>{
    return {
      'event-category' : 'Produits_proposes',
      'event-action' : r.name,
      'event-label' : r.id+'|'+r.brand.name,
      'event': 'gaEvent'
    }
  })
  trackingData.forEach((td)=>{
    console.log("tracker.trackEvent(",td,')')
    store.tracker.trackEvent({type: 'GA_EVENT', data: td});
  })
}
export default trackingMiddleware;