import React from 'react'
import './StoreInvitationPopup.scss'

import NocibeButton from '../NocibeButton'
import Popup from './Popup'
import discount from '../../../assets/discount_code_cut.png'
import PerfumeCard from '../PerfumeCard'
import { isMobile } from 'react-device-detect'

export default function (props) {

  const goToPerfumerie = () => {
    window.location.replace('https://www.nocibe.fr/parfumeries')
  }
  return (
    <>
      <div className='flower-background'></div>
      <Popup
        {...props}
        footer={
          <div className='store-invitation-footer'>
            <p>
              *Remise de 25% effectuée directement en caisse sur un des parfums
              recommandés à la suite de votre diagnostic. Offre valable une seule
              fois dans les magasins participants, pour tout diagnostic réalisé
              jusqu’au 31/12/2023, valable sur présentation
              d’une carte de fidélité en cours de validité (ou pour toute nouvelle
              souscription) et valable dans un délai de 15 jours à compter de la
              réalisation de votre diagnostic. Hors DOM TOM et Monaco. Non valable sur les produits "Petits prix",
              "Prix Web", "Prix Nocibé", "Points Rouges", "Produits Outlet", "Produits partenaires",
              "Coffrets Institut", "Cartes cadeau", "Cartes Institut", "prestations institut" et "emballages payants".
              Non cumulable et non substituable avec toutes offres promotionnelles, soldes en cours et les chèques fidélité Nocibé.
            </p>
          </div>
        }
      >
        <div className='store-container'>
          <h1>INVITATION À DÉCOUVRIR VOS PARFUMS EN MAGASIN</h1>
          <div className='store-container__content'>
            <div className='store-container__left'>
              <p>
                Venez poursuivre l'expérience en magasin et découvrir avec votre
                conseillère experte votre parfum idéal.
              </p>
              <p>
                Faites le bon choix parmi vos recommandations et profitez d'une remise
                sur votre fragrance!
              </p>
              <div className='text'>
                <span className='red-text'>
                  <b>-25% sur tous les Parfums</b>
                </span>
                <span> avec le code </span>
                <span className='red-text'>
                  <b>96925</b>
                </span>
              </div>
              <div className='code-container'>
                <div className='discount-code'>
                  <div className='content'>
                  </div>
                </div>
                <div className={isMobile ? 'images mobile' : 'images'}>
                  <img
                    alt='25% discout - code: 96925'
                    className='img'
                    src={discount}
                  ></img>
                </div>
                <p className='print-message'>
                  {isMobile
                    ? 'Sauvegarder mes recommandations pour les présenter à ma conseillère'
                    : 'Imprimer mes recommandations pour les présenter à ma conseillère'}
                </p>
              </div>
            </div>
            {props.tops && props.tops.length > 0 && <div className='store-container__right'>
              <div className="cards-wrapper">
                {props.tops.map((p) => {
                  // return <div className="perfume-card">{p.name}</div>
                  return <PerfumeCard
                    data={p}
                    key={p.id}
                    showShowDetails={false}
                    showMiniDonut={false}
                    hidePrice={true}
                    familiesTop={false}
                  />
                })}
              </div>
            </div>}
          </div>
          <div className="store-container__buttons">
            <NocibeButton
              className='store-invitation-button'
              onClick={props.onDownLoad}
              mode=''
            >
             Je télécharge
            </NocibeButton>
            {/* {isMobile ? 'Sauvegarder' : 'Je télécharge'} */}
            <NocibeButton
              className='store-invitation-button'
              onClick={goToPerfumerie}
              mode='dark'
            >
              Je cherche ma parfumerie
            </NocibeButton>
          </div>
        </div>
        </Popup>
      </>
  )
}
