import React from 'react'
import SearchPerfume from './SearchPerfume'
import Login from './Login'
import Api from '../../../services/Api'
import Popup from '../../common/popups/Popup'
import NocibeButton from '../../common/NocibeButton'
export default function () {
    const [user, setUser] = React.useState(null)
    const [open, setOpen] = React.useState(false)
    const [sent, setSent] = React.useState([])
    const [error, setError] = React.useState(null)
    const onLogin = React.useCallback(({ user, password }) => {
        setUser({ user, password })
    }, [setUser])
    const onDownload = React.useCallback(perfume => {
        let id = perfume.id || perfume.perfume.id
        return Api.downloadRecomendedUser(id, user.user, user.password).then(() => {
            setOpen(true)
            setSent([...sent, id])

        }).catch(err => {
            setOpen(true)
            setError({
                perfume: id,
                error: err
            })
        })
    }, [sent, user])
    const isDownloaded = React.useCallback((id) => {
        return sent.some(e => e === id)
    }, [sent])
    const onClose = React.useCallback(() => {
        if (error && error.error.response && error.error.response.status === 401)
            setUser(null)
        setOpen(false)
        setError(null)
    }, [error])
    const getMessageError = React.useMemo(() => {
        if (!error) return null
        if (error.error.response)
            switch (error.error.response.status) {
                case 401:
                    return "You don't have permissions!"
                case 409:
                    return "Process already running"
                case 500:
                    return "Internal error. Please contact with the admin."
                default:
                    return ''
            }
        else
            return "Internal error. Please contact with the admin."
    }, [error])
    return <React.Fragment>
        <Popup
            style={{ textAlign: 'center' }}
            open={open}
            onClose={onClose}
            actions={<NocibeButton onClick={onClose}>Close</NocibeButton>}>
            {error ? <React.Fragment>
                <h3><strong>{getMessageError}</strong></h3>
            </React.Fragment> : <React.Fragment>
                    <h3><strong>Your request is being processed</strong></h3>
                    <p>You will receive a CSV with the information in your email: {user ? user.user : ''}.</p>
                    <p>This can take about 45 minutes </p>
                </React.Fragment>}

        </Popup>
        {user ? <SearchPerfume isDownloaded={isDownloaded} onDownload={onDownload} /> : <Login onLogin={onLogin} />}

    </React.Fragment>
}