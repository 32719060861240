import React, { Component } from 'react'
import './IngItem.scss'

class IngItem extends Component {

  state = {
  }
  render () {
    return (
      <div className="ing-item" key={this.props.data.id}>
        <div className="image" style={{backgroundImage: `url(${this.props.data.lightImageUrl })`}}></div>
        {!this.props.hideLabels && <div className="label">{this.props.data.name}</div>}
      </div>
    )
  }
}
export default IngItem