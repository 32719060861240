// import {getRecomendations} from "./profile";

import { eventTracker } from '../../services/tracker'
import { getProfileFromState } from './profile'

export const SET_GENDER = 'session/SET_GENDER'
export const SET_FORWHO = 'session/SET_FORWHO'

const initialState = {
  gender: localStorage.getItem('gender') || 'U',
  'for-who': localStorage.getItem('forWho') || 'self'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GENDER:
      return {
        ...state,
        gender: action.data
      }
    case SET_FORWHO:
      return {
        ...state,
        'for-who': action.data
      }

    default:
      return state
  }
}

export const setGender = gender => {
  eventTracker.emitEvent('GENDER_CHANGED', { gender })

  return dispatch => {
    dispatch({
      type: SET_GENDER,
      data: gender
    })
    // TODO check with oslo if correct here
    // NO, mejor lo hago en el "onEnd" del wizard que creo qeu es equivalente
    // return dispatch(getRecomendations());
    localStorage.setItem('gender', gender)
  }
}
export const setForWho = (forWho, track = true) => {
  return (dispatch, getState) => {
    const state = getState()
    if (track)
      eventTracker.emitEvent('ADD_TO_PROFILE', {
        id: forWho === 'self' ? 'ME' : 'THEM',
        type: 'FOR_WHOM',
        resultingProfile: {
          ...getProfileFromState(state),
          forWhom: forWho === 'self' ? 'ME' : 'THEM'
        }
      })
    dispatch({
      type: SET_FORWHO,
      data: forWho
    })
    localStorage.setItem('forWho', forWho)
  }
}
