import React, { Component } from 'react'
import './Rater.scss'
import t from '../../i18n/strings'

export default class Rater extends Component {

  render () {
    return (
      <div className="rater">
        <div className="legend">
          <div className="legend-item">{t("dont_like_it")}</div>
          <div className="legend-item">{t("like_it")}</div>
        </div>
        <div className="buttons">
          {[1,2,3,4,5].map((i)=>{
            return <div className={"rate-button "+(this.props.value===i?'selected':'')} key={i} onClick={()=>this.props.onRated(i)}>{i}</div>
          })}
        </div>
      </div>
    )
  }

}