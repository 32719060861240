import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import { addPerfume, removePerfume, addConcepts,removeConcepts } from '../../../store/modules/profile'
import PerfumesList from '../../common/PerfumesList'
import './Evaluations.scss'
import {getUserFamilies} from '../../../store/selectors/getUserFamilies'
import NocibeButton from '../../common/NocibeButton'
import PerfumeFullView from '../../common/PerfumeFullView'
import PerfumeRemoveView from '../../common/PerfumeRemoveView'
import PerfumeAndPanel from '../../common/PerfumeAndPanel'
import t from '../../../i18n/strings';
import DonutColumn from '../profile/DonutColumn'
// import ConceptSelection from '../profile/ConceptSelection'
// import Api from '../../../services/Api'
// import Axios from 'axios'
import { isMobile } from 'react-device-detect'
import { eventTracker } from '../../../services/tracker'

class Evaluations extends Component {

  state = {
    currentRatings: [],
    toggledAllDescriptors: false,
    localConceptSelection: this.props.profile.concepts,
    allConcepts:[],
    isLoadingConcepts: false,
    // Temporary mocked concepts
  }

  componentDidMount () {

  }

  onPerfumeRated = (perfume,value) => {
    let oldRatings = this.state.currentRatings || []
    let newRatings = oldRatings.slice()
    let candidate = oldRatings.find((r)=>r.perfume.id===perfume.id)
    if(candidate) candidate.value = value
    else newRatings.push({ perfume: perfume, value: value })
    this.setState({currentRatings: newRatings})
  }
  submitRatings = () => {
    this.state.currentRatings.forEach((r) => {
      this.props.dispatch(addPerfume({id: r.perfume.id, perfume: r.perfume, value: r.value}))
      eventTracker.emitEvent('ACTION', { type: 'EVALUATE', data: { perfumeId: r.perfume.id, value: r.value  }})
    })
    this.setState({currentRatings: []})
  }
  onPerfumeRequestRemove = (perfume) => {
    this.setState({removingPerfume: perfume})

  }
  onCancelRemove = () => {
    this.setState({removingPerfume: null})
  }
  onPerfumeRemove = (perfume) => {
    this.props.dispatch(removePerfume(perfume))
  }
  onPerfumeSelect = (perfume) => {
    this.setState({currentPerfume: perfume})
  }
  onPerfumeClose = () => {
    this.setState({currentPerfume: null})
  }
  onAddDescriptor = ()=>{
    this.setState({toggledAllDescriptors: true})
    // if (this.state.allConcepts.length===0) this.requestConcepts()
  }
  // onConceptClick = concept => {
  //   this.setState((prevState)=>{
  //     let currentSelection = prevState.localConceptSelection//this.props.profile.concepts
  //     let candidate = currentSelection.find(cs => cs.id === concept.id)
  //     if (candidate) {
  //       return {...prevState,localConceptSelection:currentSelection.filter((cs)=>cs.id!==concept.id)}
  //     }else{
  //       return {...prevState,localConceptSelection:[...currentSelection,concept]}
  //     }
  //   })


  // }
  submitConcepts = () => {
    let newConcepts = this.state.localConceptSelection
    let pastConcepts = this.props.profile.concepts
    //console.log(newConcepts,pastConcepts)
    let conceptsToRemove = pastConcepts.filter(p => {
      return newConcepts.map(d=>d.id).indexOf(p.id)<0
    })
    let conceptsToAdd = newConcepts.filter(p => {
      return pastConcepts.map(d=>d.id).indexOf(p.id)<0
    })
    //console.log('add:',conceptsToAdd,'remove:',conceptsToRemove)
    if (conceptsToRemove.length>0)  this.props.dispatch(removeConcepts(conceptsToRemove))
    if (conceptsToAdd.length>0) this.props.dispatch(addConcepts(conceptsToAdd))
  }
  checkUpdatedProfile = ()=>{
    let sameConcepts = false
    if (this.props.profile && this.props.profile.concepts && this.props.profile.concepts.length===this.state.localConceptSelection.length){
      let idsLocalSelection = this.state.localConceptSelection.map((c)=>c.id)
      sameConcepts=this.props.profile.concepts.reduce((acc,c)=>acc&&idsLocalSelection.indexOf(c.id)>=0, true)
    }
    return this.state.currentRatings.length>0 || !sameConcepts
  }
  submitChanges = ()=>{
    this.submitRatings()
    this.submitConcepts()
    this.setState({toggledAllDescriptors:false,hasSubmitted: true})
  }
  render () {
    let perfumes = this.props.profile.perfumes
    let pendingInfo = this.checkUpdatedProfile()
    return (
      <div className="your-fragrances-view">
        <div className="your-fragrances-view-left">
          {/* <div className="title">{t("your_fragrances_subtitle")}</div> */}
          <div className="scroller">
            <div className="subtitle">
              <div className="label">{t("your_evaluations")}</div>
              {/* <NocibeButton mode="text" onClick={()=>this.props.history.push('/wizard?step=1')}>+ {t("add_fragrance")}</NocibeButton> */}
            </div>
            <div className="list">
              <PerfumesList
                data={perfumes}
                showRater={true}
                onRated={this.onPerfumeRated}
                showCheckbox={false}
                showRemove={true}
                onRemove={this.onPerfumeRequestRemove}
                onSelect={this.onPerfumeSelect}
              />
            </div>
            {/* <div className="subtitle">
              <div className="label">{t("words_that_describe_you")}</div>

            </div> */}
            {/* Commented for showing just the mocked concepts */}
            {/* <ConceptSelection concepts={this.state.allConcepts} onlySelected={!this.state.toggledAllDescriptors} onClick={this.onConceptClick} conceptsSelected={this.state.localConceptSelection}/>
            {this.state.isLoadingConcepts &&
              <div className="loading">
                <div className="text">{t('loading')}</div>
              </div>
            } */}
            {/* <ConceptSelection concepts={this.state.concepts} onClick={this.onConceptClick} conceptsSelected={this.state.localConceptSelection}/> */}
          </div>
          <NocibeButton
            enabled={pendingInfo || this.state.hasSubmitted===true}
            onClick={this.state.hasSubmitted?()=>this.props.history.push('/recommendations'):this.submitChanges}>
              {this.state.hasSubmitted?t("view_recomendations"):t("apply")}
            </NocibeButton>
          {/* <div className="close-button" onClick={this.props.onClose}></div> */}
        </div>
        {!isMobile &&
          <div className="your-fragrances-view-right">
            <DonutColumn showDesc={true}></DonutColumn>
          </div>
        }

        <PerfumeAndPanel in={this.state.currentPerfume!=null} data={this.state.currentPerfume} onBackgroundClick={this.onPerfumeClose}>
          <PerfumeFullView data={this.state.currentPerfume} onClose={this.onPerfumeClose}></PerfumeFullView>
        </PerfumeAndPanel>

        {this.state.removingPerfume &&
          <div className="perfume-view-wrapper">
            <PerfumeRemoveView data={this.state.removingPerfume} onConfirm={this.onPerfumeRemove} onClose={this.onCancelRemove}></PerfumeRemoveView>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    userFamilies: getUserFamilies(state)
  }
}
export default connect(mapStateToProps)(withRouter(Evaluations));
