const SPLIT_KEY = '.'
/**
 * recursive funcion
 * @param {Object | undefined} obj 
 * @param {string[]} array
 * @return {any[]}
 */
 function getFieldFromArray(obj,array,initArray){
  if(obj===undefined) {
    console.warn('obj is undefiend',initArray)
    if(array.length>0) console.warn('WARN: fields',array,'not found')
    return []
  }
  if(Array.isArray(obj)) {
    return obj.reduce((arrayConcat,element)=>arrayConcat.concat(getFieldFromArray(element,array,initArray)),[])
  }
  if(array.length===0) return [obj]
  const newArray = [...array]
  let key = newArray.shift()
  // validated key!== undefined in array.length===0
  return getFieldFromArray(obj[key],newArray,initArray)
  
}
/**
 * 
 * @param {T} obj 
 * @param {import('./typesUtils').RecursiveKeyOf<T,K>} str 
 * @param {K} splitKey default .
 * @template {Object,T}
 * @template {string,K}
 */
export function getFieldFromString(obj,str,splitKey = SPLIT_KEY ){
  const array = str.split(splitKey)
  return getFieldFromArray(obj,array,array)
}
