import React, { Component } from 'react'
import ConceptTag from '../../common/ConceptTag'
import './ConceptSelectionSplit.scss'
import t from '../../../i18n/strings'
class ConceptSelectionSplit extends Component {
  
  onConceptClick (target) {
    let index = this.props.concepts.indexOf(target)
    let other
    if(index%2===0) other=this.props.concepts[index+1]
    else other=this.props.concepts[index-1]
    // let targetSelected = this.props.conceptsSelected.find(cs => cs.id === target.id)
    let otherSelected = this.props.conceptsSelected.find(cs => cs.id === other.id)
    if(otherSelected){
      this.props.onClick(other)
      this.props.onClick(target)
    }
    else {
      this.props.onClick(target)
    }
  }  
  render () {
      let concepts = this.props.concepts
      if(!concepts){
        return (null)
      }
      if (this.props.onlySelected){
        if (!concepts || concepts.length===0){
          concepts=this.props.conceptsSelected
        }
        concepts = concepts.filter((c)=>{
          return this.props.conceptsSelected.map((cs)=>cs.id).indexOf(c.id)>=0
        })
      }
      let conceptPairs = concepts.reduce((result,c,i)=>{
        if(i%2===0) result.push([concepts[i],concepts[i+1]])
        return result
      },[])
      return (<div className='concepts-split'>
        
        {conceptPairs.map((cc,i) => {
          let c1 = cc[0]
          let c2 = cc[1]
          return (
            <div className="concept-pair" key={'concept-pair'+i}>
              <div className="concepts-tag-wrapper tag-wrapper-left">
                <ConceptTag
                  className="left-concept"
                  data={c1}
                  key={c1.id}
                  onClick={() => this.onConceptClick(c1)}
                  selected={this.props.conceptsSelected.find(
                    cs => cs.id === c1.id
                  )}
                />
              </div>
              <div className="or">{t("concept_or").toUpperCase()}</div>
              <div className="concepts-tag-wrapper">
                <ConceptTag
                  className="right-concept"
                  data={c2}
                  key={c2.id}
                  onClick={() => this.onConceptClick(c2)}
                  selected={this.props.conceptsSelected.find(
                    cs => cs.id === c2.id
                  )}
                />
              </div>
            </div>
          )
        })}
      </div>)
  }
}
export default ConceptSelectionSplit;