export function getFamiliesFromIngredientProportions(ingredientProportions) {
  let fams = ingredientProportions.filter (i => i.ingredient.family).map (i => {return {ingredient:i.ingredient, family:i.ingredient.family, value:i.proportion}});

  var counts = {};
  fams.forEach (x => {
    counts[x.family.id] = (counts[x.family.id] || {val:0,notes:[]})
    counts[x.family.id].val+=x.value;
    counts[x.family.id].notes.push(x.ingredient)
  });

  fams = fams.filter ((v, i, a) => a.findIndex (f => f.family.id === v.family.id) === i);
  fams = fams.map (f => Object.assign ({}, f, {value: Math.max(0, counts[f.family.id].val), notes: counts[f.family.id].notes}));
  fams.forEach((f)=>{
    delete f.ingredient
    f.notes = f.notes.filter ((v, i, a) => a.findIndex (f => f.id === v.id) === i);
    // f.value = f.notes.length
  })
  return fams;
}

export function getFamiliesFromIngredients (ingredients) {
  let fams = ingredients.filter (i => i.family).map (i => {return {ingredient:i, family:i.family}});

  var counts = {};
  fams.forEach (x => {
    counts[x.family.id] = (counts[x.family.id] || {val:0,notes:[]})
    counts[x.family.id].val+=1;
    counts[x.family.id].notes.push(x.ingredient)
  });

  fams = fams.filter ((v, i, a) => a.findIndex (f => f.family.id === v.family.id) === i);
  fams = fams.map (f => Object.assign ({}, f, {value: counts[f.family.id].val, notes: counts[f.family.id].notes}));
  fams.forEach((f)=>{
    delete f.ingredient
    f.notes = f.notes.filter ((v, i, a) => a.findIndex (f => f.id === v.id) === i);
    f.value = f.notes.length
  })
  return fams;
}


export function getProfileIngredients (profile) {
  return getPerfumesIngredients(profile.perfumes.filter((p)=>p.value>2))
}
export function getProfileMainIngredients (profile) {
  return getPerfumesMainIngredients(profile.perfumes.filter((p)=>p.value>2))
}
export function getProfileFamilies (profile) {
  let likedPerfumes = profile.perfumes.filter((p)=>p.value>2).map((p)=>p.perfume)
  let disliketPerfumes = profile.perfumes.filter((p)=>p.value<=2).map((p)=>p.perfume)
  let families = getFamiliesFromSignedPerfumes(likedPerfumes,disliketPerfumes) || []
  return families.splice(0,6)
}
export function getFamiliesFromSignedPerfumes (liked, disliked) {
  let fams = liked.filter (p => p.family && p.secondaryFamily).map (p => {return {secondaryFamily:p.secondaryFamily || p.family, family:p.family, inverted: false}});
  fams = fams.concat(disliked.filter (p => p.family && p.secondaryFamily).map (p => {return {secondaryFamily:p.secondaryFamily || p.family, family:p.family, inverted: true}}));
  var counts = {};
  fams.forEach (x => {
    counts[x.family.id] = (counts[x.family.id] || {val:0,positiveNotes:[],negativeNotes:[]})
    // counts[x.family.id].val+=x.inverted?-1:1;
    if(x.inverted) counts[x.family.id].negativeNotes.push(x.secondaryFamily)
    else counts[x.family.id].positiveNotes.push(x.secondaryFamily)
  });

  fams = fams.filter ((v, i, a) => a.findIndex (f => f.family.id === v.family.id) === i);
  fams = fams.map (f => Object.assign ({}, f, {positiveNotes: counts[f.family.id].positiveNotes,negativeNotes: counts[f.family.id].negativeNotes}));
  fams.forEach((f)=>{
    delete f.secondaryFamily
    var subcounts = {};
    f.positiveNotes.forEach (x => {
      subcounts[x.id] = (subcounts[x.id] || {val:0,id:x.id})
      subcounts[x.id].val+=1;
    });
    f.negativeNotes.forEach (x => {
      subcounts[x.id] = (subcounts[x.id] || {val:0,id:x.id})
      subcounts[x.id].val-=1;
    });

    let notes = f.positiveNotes
    notes = notes.filter ((v, i, a) => a.findIndex (f => f.id === v.id) === i);
    notes = notes.map (n => Object.assign ({}, n, {value: Math.max(0,subcounts[n.id].val)}));
    notes = notes.filter((n)=>n.value>0)
    f.notes = notes
    f.value = f.notes.length
  })
  return fams.sort((a,b)=>b.value-a.value)
}
export function getProfileFamiliesAndSubs (profile) {
  let likedPerfumes = profile.perfumes.filter((p)=>p.value>2).map((p)=>p.perfume)
  let disliketPerfumes = profile.perfumes.filter((p)=>p.value<=2).map((p)=>p.perfume)
  let families = getFamiliesAndSubsFromSignedPerfumes(likedPerfumes,disliketPerfumes) || []
  return families
}
export function getFamiliesAndSubsFromSignedPerfumes (liked, disliked) {
  // let fams = liked.filter (p => p.family && p.secondaryFamily).map (p => {return {secondaryFamily:p.secondaryFamily || p.family, family:p.family, inverted: false}});
  // fams = fams.concat(disliked.filter (p => p.family && p.secondaryFamily).map (p => {return {secondaryFamily:p.secondaryFamily || p.family, family:p.family, inverted: true}}));
  let fams = liked.filter (p => p.family && p.secondaryFamily).map (p => {return p.family});
  fams = fams.concat(liked.filter (p => p.family && p.secondaryFamily).map (p => {return p.secondaryFamily}));
  
  var counts = {};
  fams.forEach (x => {
    counts[x.id] = (counts[x.id] || 0)
    // counts[x.family.id].val+=x.inverted?-1:1;
    counts[x.id]+=1
  });
  fams = fams.filter ((v, i, a) => a.findIndex (f => f.id === v.id) === i);
  let result = fams.map (x => {
     return {family: x, value: counts[x.id]}
  })
  
  return result.sort((a,b)=>b.value-a.value)
}
export function getProfileSubs (profile) {
  let likedPerfumes = profile.perfumes.filter((p)=>p.value>2).map((p)=>p.perfume)
  let disliketPerfumes = profile.perfumes.filter((p)=>p.value<=2).map((p)=>p.perfume)
  let families = getSubsFromSignedPerfumes(likedPerfumes,disliketPerfumes) || []
  return families
}
export function getSubsFromSignedPerfumes (liked, disliked) {
  let subs = liked.filter (p => p.family && p.secondaryFamily).map (p => {return p.secondaryFamily})
  
  var counts = {};
  subs.forEach (x => {
    counts[x.id] = (counts[x.id] || 0)
    // counts[x.family.id].val+=x.inverted?-1:1;
    counts[x.id]+=1
  });
  subs = subs.filter ((v, i, a) => a.findIndex (f => f.id === v.id) === i);
  let result = subs.map (x => {
     return {family: x, value: counts[x.id]}
  })
  
  return result.sort((a,b)=>b.value-a.value)
}
export function getPerfumesIngredients (perfumes) {
  let likedPerfumes = perfumes.filter((p)=>p.value>3).map((p)=>p.perfume)
  let ingsOb = {}
  likedPerfumes.forEach((p)=>{
    p.ingredientProportions.forEach((ip)=>{
      ingsOb[ip.ingredient.id] ? ingsOb[ip.ingredient.id].proportion+=ip.proportion : (ingsOb[ip.ingredient.id] = {...ip})
    })
  })
  return Object.values(ingsOb).sort((a,b)=>b.proportion-a.proportion).map((ip)=>ip.ingredient)
}
export function getPerfumesMainIngredients (perfumes) {
  let likedPerfumes = perfumes.filter((p)=>p.value>3).map((p)=>p.perfume)
  let ingsOb = {}
  likedPerfumes.forEach((p)=>{
    p.ingredientProportions.slice(0,5).forEach((ip)=>{
      ingsOb[ip.ingredient.id] ? ingsOb[ip.ingredient.id].proportion+=ip.proportion : (ingsOb[ip.ingredient.id] = {...ip})
    })
  })
  return Object.values(ingsOb).sort((a,b)=>b.proportion-a.proportion).map((ip)=>ip.ingredient)
}
export function getHeroIngredients(profile) {
  let likedPerfumes = profile.perfumes.filter((p)=>p.value>3).map((p)=>p.perfume)
  let heroes = likedPerfumes.filter((p)=>p.heroIngredient).map((p)=>p.heroIngredient)
  return heroes
}
export function getDominantIngredients (profile) {
  let likedPerfumes = profile.perfumes.filter((p)=>p.value>3).map((p)=>p.perfume)
  let ingredients = likedPerfumes.map((p)=>p.ingredientProportions.sort((a,b)=>b.proportion-a.proportion).slice(0,5).map((i)=>i.ingredient)).flat()
  let counts = {}
  ingredients.forEach((i)=>{
    if(!counts[i.id]) counts[i.id] = 0
    counts[i.id] += 1
  })
  ingredients = ingredients.filter ((v, i, a) => a.findIndex (f => f.id === v.id) === i);
  let result = ingredients.map (x => {
     return {ingredient: x, value: counts[x.id]}
  })
  
  let heroes = getHeroIngredients(profile)
  result = result.filter((i)=>i.value >= 2 && i.value>=Math.floor(likedPerfumes.length/2))
  result.sort((a,b)=>b.value - a.value)
  
  // ADDING HEROES "si o si"
  result = result.map((i)=>i.ingredient)
  //Los metemos por delante para que no se pierdan con el slice
  result = heroes.concat(result)
  result = result.filter((r,idx)=>result.findIndex((rr)=>rr.id===r.id)===idx)
  
  return result
}
export function concatWithAnd (array,andString) {
  if(array.length===1) return array[0]
  if(array.length===2) return array.join( ' ' + andString + ' ')
  return array.slice(0,-1).join(', ') + ' ' + andString + ' ' + array[array.length-1]
}
// export function getFamiliesFromPerfumes (perfumes) {
//   let fams = perfumes.filter (p => p.family && p.secondaryFamily).map (p => {return {secondaryFamily:p.secondaryFamily || p.family, family:p.family}});

//   var counts = {};
//   fams.forEach (x => {
//     counts[x.family.id] = (counts[x.family.id] || {val:0,notes:[]})
//     counts[x.family.id].val+=1;
//     counts[x.family.id].notes.push(x.secondaryFamily)
//   });

//   fams = fams.filter ((v, i, a) => a.findIndex (f => f.family.id === v.family.id) === i);
//   fams = fams.map (f => Object.assign ({}, f, {value: counts[f.family.id].val, notes: counts[f.family.id].notes}));
//   fams.forEach((f)=>{
//     delete f.secondaryFamily
//     var subcounts = {};
//     f.notes.forEach (x => {
//       subcounts[x.id] = (subcounts[x.id] || {val:0,id:x.id})
//       subcounts[x.id].val+=1;
//     });

//     f.notes = f.notes.filter ((v, i, a) => a.findIndex (f => f.id === v.id) === i);
//     f.notes = f.notes.map (n => Object.assign ({}, n, {value: subcounts[n.id].val}));
//     f.value = f.notes.length
//   })
//   return fams;
// }

// export function getFamiliesFromIngredients(ingredients,defs){
//   var fams = ingredients.map(i => {
//     return defs.families.find(f => {
//         //return f.id == i.id;
//         return f.ingredients.indexOf(i.id || i.ingredient_id) >= 0;
//       });
//     });

//   // console.log(fams)

//   let dataObject = {};
//   fams.forEach(f => {
//     if (!dataObject[f.id]) {
//       dataObject[f.id] = { count: 1, family: f };
//     } else {
//       dataObject[f.id].count++;
//     }
//   });

//   let families = Object.keys(dataObject).map(function(key, index) {
//     // dataObject[key].family.value = dataObject[key].count;
//     // return dataObject[key].family;
//     return {
//       family_id: +key,
//       family: dataObject[key].family,
//       value: dataObject[key].count
//     }
//   });

//   return families

// }

export function getPerfumesFamilies (perfumes) {
  if(!perfumes || perfumes.length===0) return []
  if(perfumes[0].perfume) perfumes = perfumes.map((p)=>p.perfume)
  let families = getFamiliesFromSignedPerfumes(perfumes,[]) || []
  return families.map((f)=>f.family)
}

export function getPerfumesBrands (perfumes) {
  if(!perfumes || perfumes.length===0) return []
  if(perfumes[0].perfume) perfumes = perfumes.map((p)=>p.perfume)
  let counts = {}
  let brands = perfumes.map((p)=>p.brand).forEach (x => {
    counts[x.id] = (counts[x.id] || x)
  })
  brands = Object.values(counts)

  brands = brands.filter ((v, i, a) => a.findIndex (f => f.id === v.id) === i);
  return brands
}

export function getPerfumesPrices (perfumes) {
  if(!perfumes || perfumes.length===0) return []
  if(perfumes[0].perfume) perfumes = perfumes.map((p)=>p.perfume)
  return perfumes.map((p)=>p.price)
}

// export function getPerfumesYears (perfumes) {
//   if(!perfumes || perfumes.length===0) return []
//   if(perfumes[0].perfume) perfumes = perfumes.map((p)=>p.perfume)
//   return perfumes.map((p)=>p.year).filter((y)=>y!=null)
// }
export function computeSeason(perfume){
  // return perfume.season
  let perfumeIntensity = perfume.family.intensity*0.6 + perfume.secondaryFamily.intensity*0.4
  let result = []
  if (perfumeIntensity>3) result.push('WINTER')
  if (perfumeIntensity<6) result.push('SUMMER')
  return result
}
export function getPerfumesSeasons (perfumes) {
  if(!perfumes || perfumes.length===0) return []
  if(perfumes[0].perfume) perfumes = perfumes.map((p)=>p.perfume)
  let seasons = perfumes.map((p)=>computeSeason(p)).flat().filter((y)=>y!=null)
  seasons = seasons.filter ((v, i, a) => a.findIndex (f => f === v) === i);
  seasons = seasons.filter((s)=>s!=='EMPTY')
  return seasons
}
export function prettyNumber (value) {
  return Math.round (value);
}
export function capitalizeCartridge (string) {
  return string
    .split (' ')
    .map (w => {
      return w.charAt (0).toUpperCase () + w.slice (1).toLowerCase ();
    })
    .join (' ');
}

export function getIngredientImageUrl (filename, imageUrl) {
  if (filename && filename.length > 0) {
    return filename;
  }
}

export function getFamilyImageUrl (filename) {
  return process.env.PUBLIC_URL+'/images/families/'+filename
}

export function getPerfumeImageUrl (filename) {
  return filename;
  // return process.env.PUBLIC_URL+'/images/perfumes/perfume.png'
}


export function hexToRGB (hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export function LightenDarkenColor(col, amt) {

  if(col==='#000') return col
  var usePound = false;

  if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

}

export function arraysEqual(a, b, prop) {
  if(!prop) prop = (d)=>d.id
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.
  a = a.slice().sort((aa,bb)=>prop(bb)-prop(aa))
  b = b.slice().sort((aa,bb)=>prop(bb)-prop(aa))

  for (var i = 0; i < a.length; ++i) {
    if (prop(a[i]) !== prop(b[i])) return false;
  }
  return true;
}

export function formatPrice(perfume){
  if(perfume.price) return perfume.price.toFixed(2)+'€'
  else return 'n.a.'
}

export function addTrackingInfo(productUrl){
  let trackingInfo = 'utm_source=diagnostic_parfum&utm_medium=plateforme&utm_campagin=diagparfum'
  if(productUrl.indexOf('?')>=0) return productUrl+'&'+trackingInfo
  else return productUrl+'?'+trackingInfo
}