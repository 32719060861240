import React, { Component } from 'react'
import './App.scss'
import Header from './components/header/Header'
import { connect } from 'react-redux'
import {
  Route,
  withRouter,
  Redirect,
  HashRouter as Router
} from 'react-router-dom'
import Recomendations from './components/sections/recomendations/Recomendations'
import Evaluations from './components/sections/evaluations/Evaluations'
import Profile from './components/sections/profile/Profile'
import Explorer from './components/sections/explorer/Explorer'
import Bowser from 'bowser'
import Wizard from './components/sections/wizard/Wizard'
import WishListPanel from './components/common/WishListPanel'
import qs from 'qs'
import ResetPopup from './components/common/popups/ResetPopup'
import SharePopup from './components/common/popups/SharePopup'
import styles from './assets/styles/_main.scss'
import OverlayedView from './components/common/popups/OverlayedView'
import { isMobile } from 'react-device-detect'
import Api from './services/Api'
import { getUserData } from './store/modules/user'
import { getRecomendations } from './store/modules/profile'
import { markRecomendationsAsViewed } from './store/modules/profile'
import store from './store/store'
import {
  manuallyTrackRecos,
  manuallyTrackResult
} from './tracking/trackingStoreMiddleware'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import HowItWorks from './components/sections/howItWorks/HowItWorks'
import Admin from './components/sections/admin'
import { trackViewEventHandler } from './services/tracker'

const myTheme = createMuiTheme({
  palette: {
    primary: {
      main: styles.primaryThemeColor
    }
  }
})

window.bowser = Bowser.parse(window.navigator.userAgent)

window.familiesOrder = [
  'CITRUS',
  'GREEN',
  'AROMATIC FOUGERE',
  'WATERY',
  'ALDEHYDIC',
  'FLORAL',
  'FRUITY',
  'SPICY',
  'WOODY',
  'PATCHOULI CHYPRE',
  'TOBACCO',
  'ORIENTAL VANILLA',
  'GOURMAND',
  'LEATHER',
  'MUSK SKIN'
]

function closeOverView (location, history) {
  let query = qs.parse(location.search.slice(1))
  delete query.wishlist
  delete query.reset
  delete query.share
  history.push({ search: qs.stringify(query) })
}

store.dispatch(getUserData()).then(() => store.dispatch(getRecomendations()))

class LoggedApp extends Component {
  state = {
    firstEntry: true
  }
  UNSAFE_componentWillReceiveProps (props, prevprops) {
    // console.log(this.props.location.pathname,'to',props.location.pathname)
    this.state.firstEntry && this.setState({ firstEntry: false })
    // if(!this.props.profile.recosViewed && this.props.location.pathname.indexOf('recommendations')>=0) this.props.dispatch(markRecomendationsAsViewed())
    // if(this.props.location.pathname !== props.location.pathname && props.location.pathname.indexOf('recommendations')>=0) this.props.dispatch(markRecomendationsAsViewed())
    // if(this.props.location.pathname.indexOf('recommendations')<0 && props.location.pathname.indexOf('recommendations')>=0) {
    if (
      this.state.firstEntry ||
      this.props.location.pathname !== props.location.pathname ||
      this.props.location.search !== props.location.search
    )
      this.trackPageView(props)

    if (
      this.props.location.pathname !== props.location.pathname &&
      props.location.pathname.indexOf('recommendations') >= 0
    ) {
      this.props.dispatch(markRecomendationsAsViewed())
    }
  }

  componentDidMount () {
    trackViewEventHandler(this.props.history.location)
    this.unlisten = this.props.history.listen(trackViewEventHandler)
  }

  componentWillUnmount () {
    if (this.unlisten && typeof this.unlisten === 'function') {
      this.unlisten()
    }
  }

  trackPageView (newProps) {
    let pageName
    switch (newProps.location.pathname) {
      case '/wizard':
        let step =
          +(qs.parse(newProps.location.search.slice(1)).i_step || 0) + 1
        pageName = 'Etape_' + step
        break
      case '/profile':
        pageName = 'Resultat_profil'
        break
      case '/recommendations':
        pageName = 'Recommandations'
        break
      case '/explorer':
        pageName = 'Explorer'
        break
      case '/evaluations':
        pageName = 'Evaluations'
        break
      case '/howto':
        pageName = 'Fonctionnement'
        break
      default:
        return
    }
    let trackingData = {
      pageName: pageName,
      userId: newProps.user.nocibeId,
      userLogged: newProps.user.linked ? 'True' : 'False',
      event: 'contentLoaded'
    }
    console.log('tracker.trackEvent(', trackingData, ')')
    store.tracker.trackEvent({ type: 'PAGE_VIEW', data: trackingData })
  }
  render () {
    // console.log("Starting app with user",this.props.user)
    return (
      <div className={'app ' + (isMobile ? 'mobile' : '')}>
        <Route render={props => <Header {...props}></Header>} />
        <div className='app-content'>
          <Route
            exact
            path={[`/`, `/wizard`]}
            render={props => {
              if (
                this.state.firstEntry &&
                this.props.user.favoritePerfumes &&
                this.props.user.favoritePerfumes.length > 0
              ) {
                // props.history.replace('/profile')
                return (
                  <Redirect
                    to={{
                      pathname: '/profile',
                      state: { from: props.location }
                    }}
                  />
                )
              }
              if (props.location.pathname === '/') {
                // props.history.replace('/wizard')
                return (
                  <Redirect
                    to={{
                      pathname: '/wizard',
                      state: { from: props.location }
                    }}
                  />
                )
              }
              return (
                <Wizard
                  {...props}
                  key={props.location.state}
                  onEnd={() => {
                    // let gender = store.getState().session.gender
                    // let gift = store.getState().session['for-who']==='self'?0:1
                    Api.saveUserMeta().then(() => {
                      store.dispatch(getUserData()).then(() => {
                        manuallyTrackResult()
                        // chapuzilla, forceNotNew para que no aparezcan como new despues del wizard...
                        this.props
                          .dispatch(getRecomendations({ forceNotNew: true }))
                          .then(() => {
                            this.props.dispatch(markRecomendationsAsViewed())
                            manuallyTrackRecos()
                          })
                      })
                    })
                    props.history.push('/profile?mode=wizardProfile')
                  }}
                />
              )
            }}
          />
          <Route
            path={`/profile`}
            render={props => {
              let urlParams = qs.parse(props.location.search.slice(1))
              return (
                <Profile
                  {...props}
                  mode={urlParams.mode}
                  state={urlParams.state}
                  onHideYourFragrances={() => props.history.push('/profile')}
                  onShowYourFragrances={() =>
                    props.history.push('/profile?state=showYourFragrances')
                  }
                  onHideEvaluate={() => props.history.push('/profile')}
                  onShowEvaluate={() =>
                    props.history.push('/profile?state=showEvaluate')
                  }
                />
              )
            }}
          />
          <Route
            path={[`/recommendations`, `/recomendations`]}
            component={Recomendations}
          />
          <Route path={`/explorer`} component={Explorer} />
          <Route path={`/evaluations`} component={Evaluations} />
          <Route path={`/howto`} component={HowItWorks} />
          <Route path={'/admin'} component={Admin} />
          <Route
            render={props => {
              let overView = null
              let query = qs.parse(props.location.search.slice(1))
              if (query.wishlist) overView = <WishListPanel></WishListPanel>
              if (query.reset) overView = <ResetPopup></ResetPopup>
              if (query.share) overView = <SharePopup></SharePopup>
              return (
                <OverlayedView
                  in={overView != null}
                  onBackgroundClick={() =>
                    closeOverView(props.location, props.history)
                  }
                >
                  {overView}
                </OverlayedView>
              )
            }}
          />
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return state
}
LoggedApp = withRouter(connect(mapStateToProps)(LoggedApp))
class Preloader extends Component {
  render () {
    return (
      <div className='preloader'>
        <div className='text'>Chargement en cours...</div>
      </div>
    )
  }
}

function App (props) {
  return (
    <MuiThemeProvider theme={myTheme}>
      <Router>
        {!props.user.loaded && <Preloader></Preloader>}
        {props.user.loaded && <LoggedApp />}
      </Router>
    </MuiThemeProvider>
  )
}

export default connect(mapStateToProps)(App)
