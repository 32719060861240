import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import {addPerfume} from '../../../store/modules/profile'
import PerfumesList from '../../common/PerfumesList'
import {getRecomendations} from '../../../store/modules/profile'
import './EvaluationView.scss'
import {getUserFamilies} from '../../../store/selectors/getUserFamilies'
import NocibeButton from '../../common/NocibeButton'
import DonutColumn from './DonutColumn'
import t from '../../../i18n/strings'
import { isMobile } from 'react-device-detect';
import { eventTracker } from '../../../services/tracker';

class EvaluationView extends Component {

  state = {
    currentRatings: []
  }
  componentDidMount () {
    // ASUMIMOS QUE SE ACTUALIZA RECOMENDATIONS SIEMPRE QUE TOCA!
    if(!this.props.profile.recomendations) this.props.dispatch(getRecomendations())
  }
  onPerfumeRated = (perfume,value) => {
    let oldRatings = this.state.currentRatings || []
    let newRatings = oldRatings.slice()
    let candidate = oldRatings.find((r)=>r.perfume.id===perfume.id)
    if(candidate) candidate.value = value
    else newRatings.push({ perfume: perfume, value: value })
    this.setState({currentRatings: newRatings, applied: false})
  }
  submitRatings = () => {
    this.state.currentRatings.forEach((r) => {
      eventTracker.emitEvent('ACTION', { type: 'EVALUATE', data: { perfumeId: r.perfume.id, value: r.value }})
      this.props.dispatch(addPerfume({id: r.perfume.id, perfume: r.perfume, value: r.value}))
    })
    this.setState({currentRatings: [], applied: true})
  }
  render () {
    let tops = (this.props.profile.recomendations || []) //.slice(0,4)
    // let rest = (this.props.profile.recomendations || []).slice(4,50)
    return (
      <div className="evaluation-view">
        <div className="left">
          <div className="title">{t('my_recomendations_to_evaluate')}</div>
          <div className="scroller">
            <div className="list">
              {/* <div className="subtitle">{t('top_6_recomendations')}</ div> */}
              <PerfumesList
                data={tops}
                showCheckbox={false}
                showRater={true}
                onRated={this.onPerfumeRated}
              />
            </div>
            {/* <div className="list">
              <div className="subtitle">{t('all_recomendations')}</ div>
              <PerfumesList
                data={rest}
                showRater={true}
                onRated={this.onPerfumeRated}
              />
            </div> */}
          </div>
          {!this.state.applied &&
            <NocibeButton
              label={t('apply')}
              enabled={this.state.currentRatings.length>0}
              onClick={this.submitRatings}
            />
          }
          {this.state.applied &&
            <Link className="link-button" to="/recommendations">
              <NocibeButton label={t('view_recomendations')}></NocibeButton>
            </Link>
          }
          <div className="close-button" onClick={this.props.onClose}></div>
        </div>
        {!isMobile &&
          <div className="right">
            <DonutColumn showDesc={true}></DonutColumn>
          </div>
        }

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    userFamilies: getUserFamilies(state)
  }
}
export default connect(mapStateToProps)(EvaluationView);
