import React, {Component} from 'react';
import './SharePopup.scss'
import './Popup.scss'
import {withRouter} from 'react-router'
import qs from 'qs'
import t from '../../../i18n/strings';
import {
  FacebookShareButton,
  // LinkedinShareButton,
  TwitterShareButton,
  // TelegramShareButton,
  // WhatsappShareButton,
  PinterestShareButton,
  // VKShareButton,
  // OKShareButton,
  // RedditShareButton,
  // TumblrShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // ViberShareButton,
  // WorkplaceShareButton,
  // LineShareButton,
  // PocketShareButton,
  // InstapaperShareButton,
  EmailShareButton,
} from 'react-share';
import {
  FacebookIcon,
  TwitterIcon,
  // TelegramIcon,
  // WhatsappIcon,
  // LinkedinIcon,
  PinterestIcon,
  // VKIcon,
  // OKIcon,
  // RedditIcon,
  // TumblrIcon,
  // LivejournalIcon,
  // MailruIcon,
  // ViberIcon,
  // WorkplaceIcon,
  // LineIcon,
  // PocketIcon,
  // InstapaperIcon,
  EmailIcon,
} from 'react-share';
class SharePopup extends Component{
  state = {
  }
  onClose = () => {
    let query = qs.parse(this.props.location.search.slice(1))
    delete query.share
    this.props.history.push({search:qs.stringify(query)})
  }
  render () {
    return <div className="share-popup popup">
      <div className="title">{t('share_this_experience')}</div>
      <div className="share-buttons">
        <FacebookShareButton url="http://whatever.com">
          <FacebookIcon size={50} round></FacebookIcon>
        </FacebookShareButton>

        <TwitterShareButton url="http://whatever.com">
          <TwitterIcon size={50} round></TwitterIcon>
        </TwitterShareButton>

        <PinterestShareButton url="http://whatever.com" media="http://whatever.com">
          <PinterestIcon size={50} round></PinterestIcon>
        </PinterestShareButton>

        <EmailShareButton url="http://whatever.com">
          <EmailIcon size={50} round></EmailIcon>
        </EmailShareButton>
      </div>
      <div className="subtitle">{t("or_copy_link")}</div>
      <div className="link">
        <input readonly="readonly" value="http://whatever.com"></input>
        <div className="copy-button">{t("copy")}</div>
      </div>
      {/* <div className="buttons">
        <NocibeButton onClick={this.onClose} mode="empty">OK</NocibeButton>
      </div> */}
      <div className="close-button" onClick={this.onClose}></div>
    </div>
  }

}
export default withRouter(SharePopup)