import React from 'react'
import PropTypes from 'prop-types';
import './Login.scss'
import NocibeButton from '../../common/NocibeButton'
function Login(props) {
    const inputRef = React.createRef()
    const passwordRef = React.createRef()
    
    const onSubmit = React.useCallback((event) => {
        event.preventDefault()
        if (inputRef.current && passwordRef.current) {
            props.onLogin({ user: inputRef.current.value, password: passwordRef.current.value })
        }
    }, [props,inputRef,passwordRef])
    React.useEffect(() => {
        if (inputRef.current)
            inputRef.current.focus()
    }, [inputRef])
    
    return <div className='login'>
        <div className="login-container">
        <h3>User credentials</h3>
                
            <form onSubmit={onSubmit}>
                <table >
                    <tbody>
                    <tr>
                        <td><label>Username</label></td>
                        <td><input ref={inputRef} placeholder="User" name='email' ></input></td>                        
                    </tr>
                    <tr>
                        <td><label>Password</label></td>
                        <td><input ref={passwordRef} placeholder="Password" name='password' type="password" /></td>
                    </tr>
                    </tbody>
                </table>
                <button style={{border:'none'}} type="submit"><NocibeButton>Continue</NocibeButton></button>
            </form>
            
        </div>
    </div>
}
Login.propTypes = {
    onLogin: PropTypes.func,
}
export default Login