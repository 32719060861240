import React, { Component } from 'react'
import './NocibeButton.scss'
import ButtonWithFeedback from './ButtonWithFeedback'

export default class NocibeButton extends Component {
  handleClick = () => {
    if (this.props.onClick) this.props.onClick()
  }
  render () {
    let baseClassName = this.props.className || ''
    let classModeName = this.props.mode
      ? 'nocibe-' + this.props.mode + '-button'
      : 'nocibe-button'
    let classEnabledName = this.props.enabled === false ? 'disabled' : 'enabled'
    let classActiveName = this.props.active ? 'active' : ''
    let classSecondary = this.props.secondary ? 'secondary' : ''

    return (
      <ButtonWithFeedback
        className={
          'button ' +
          baseClassName +
          ' ' +
          classSecondary +
          ' ' +
          classModeName +
          ' ' +
          classEnabledName +
          ' ' +
          classActiveName
        }
        onClick={this.handleClick}
      >
        <div className='label'>{this.props.label || this.props.children}</div>
      </ButtonWithFeedback>
    )
  }
}
