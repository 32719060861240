import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store/store'
import 'array-flat-polyfill'

import logo from './assets/images/logo.png'


const MAINTENANCE = false
// TO-DO
// GET('serverStatus').then().error()
if (MAINTENANCE) {
  ReactDOM.render(
    <div className='maintenance-message'>
      <img className="logo" src={logo} alt="Nocibé logo"></img>
      <div className="text">Victime de notre succès,<br></br>nous revenons très vite.</div>      
    </div>,
    document.getElementById('root')
  )
} else {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
