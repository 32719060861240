import React, {Component} from 'react';
import axios from 'axios';
import './BetterInput.scss'
// import {DebounceInput} from 'react-debounce-input';
import {debounce} from 'throttle-debounce';
import t from '../../i18n/strings';

export default class BetterInput extends Component {
  signal = axios.CancelToken.source ();
  constructor (props) {
    super (props);
    this.state = {query: ''};
  }

  resetQuery = () => {
    this.onActualInputChange('')
  };
  onDelayedInputChange = debounce(500,this.onActualInputChange)
  onActualInputChange (query) {
    this.setState ({query: query});
    if (this.props.onSearchQueryChange) {
      this.props.onSearchQueryChange (query);
    }
  }
  handleInputChange = event => {
    this.setState ({query: event.target.value}, () => {
      // this.autocompleteSearchDebounced (this.state.query);
      this.onDelayedInputChange(this.state.query)
    });
  };
  handleEnter = event => {
    if (event.keyCode  === 13) event.target.blur();
  }

  render () {
    return (
      <div className="search-bar">
        <div className="input-wrapper">
          <input
            autoComplete="off"
            className={'search-input'}
            id="search"
            type="text"
            placeholder={this.props.placeholder || t('search')}
            value={this.state.query}
            onChange={this.handleInputChange}
            onKeyUp={this.handleEnter}
          />
        </div>
        <div
          className={'icon' + ((this.state.query !== null && this.state.query !== '') ? ' close' : '')}
          onClick={this.resetQuery}
        />
      </div>
    );
  }
}
