import React from 'react';
import ReactDOM from 'react-dom';
import { isMobile } from 'react-device-detect';
import './PopupFixed.scss'
import OverlayedView from './OverlayedView';
class ModalPortal extends React.Component {
  componentWillUnmount() {
    if (this.defaultNode) {
      let root = document.getElementById('root')
      root.removeChild(this.defaultNode);
    }
    this.defaultNode = null;
  }
  render() {
    if (!this.props.node && !this.defaultNode) {
      this.defaultNode = document.createElement('div');
      document.getElementById('root').appendChild(this.defaultNode);
    }
    return ReactDOM.createPortal(
      this.props.children,
      this.props.node || this.defaultNode
    );
  }
}
export default function (props) {
  return <ModalPortal>
    <OverlayedView in={props.open} onBackgroundClick = {props.onClose}>
      <div className={(isMobile ? 'mobile ' : '') + " popup-fixed"}>
      <div className="title-popup">{props.title}</div>
          
        <div className="popup-container">
          {props.children}
          <div className="buttons">
            {props.actions}
          </div>
          <div style={{flex:'1 1 0'}}/>
          {props.footer}
        </div>
        <div className="close-button" onClick={props.onClose}></div>
      </div>
    </OverlayedView>
  </ModalPortal>
}

