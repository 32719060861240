import React, { Component } from 'react'
import SearchBy from './SearchBy'
import './FindBy.scss'
import SearchPerfumeResults from './SearchPerfumeResults'
import NocibeButton from '../../common/NocibeButton'
import PerfumeFullView from '../../common/PerfumeFullView'
import PerfumeAndPanel from '../../common/PerfumeAndPanel'
import ConceptTag from '../../common/ConceptTag'
import t from '../../../i18n/strings';
import { isMobile } from 'react-device-detect'
import { eventTracker } from '../../../services/tracker'

class SearchSelection extends Component {
  render () {
    let items = this.props.data.map((d)=>{
      return <ConceptTag data={d} selected={true} key={d.id} onClick={()=>this.props.onClick(d.id)}></ConceptTag>

    })
    return <div className="selection-list">
      <div className="concept-list-wrapper">
        {items}
      </div>
    </div>
  }
}
export default class FindByConcepts extends Component {
  state = {
    selection: []
  }
  componentDidMount(){
    eventTracker.emitEvent('VIEW_OPENED', { type:'EXPLORE_BY_CONCEPTS' })
  }
  handleSelection = (selection)=> {
    this.setState({selection: selection})
  }
  removeFromSelection = (ingredientId) => {
    this.setState({selection: this.state.selection.filter((i)=>i.id !== ingredientId)})
  }
  getPerfumes = () => {
    this.setState({query:this.state.selection.map((d)=>d.id)})
    this.props.onSetQuery("true")
  }
  onPerfumeSelect = (perfume) => {
    this.setState({currentPerfume: perfume})
  }
  onPerfumeClose = (perfume) => {
    this.setState({currentPerfume: null})
  }

  UNSAFE_componentWillReceiveProps = (newProps) =>{
    if(this.state.query && !newProps.currentQuery) this.setState({query: null})
  }
  render () {

    return <div className="sub-section by-concepts">
      <div className={'search-cols '+(isMobile && this.state.query?'mobile-hidden':'')}>
        {isMobile && <div className="back-col">
          <div className="back-button" onClick={()=>{this.setState({query:null})}}>{t('back_to_concepts')}</div>
        </div>}
        <div className="main-col">
          <SearchBy endpoint="/concepts" searchParams='addOnly=categories' groupBy="categories.name" onSelect={this.handleSelection} selection={this.state.selection} clientFilterBoolean={true}></SearchBy>
        </div>
        <div className={"second-col "+(this.state.selection.length>0?'visible':'hidden')}>
          <div className="cut-content">
            <SearchSelection data={this.state.selection} onClick={this.removeFromSelection}></SearchSelection>
            <div className="go-button-wrapper">
              <NocibeButton label="FIND PERFUMES" onClick={this.getPerfumes}></NocibeButton>
            </div>
          </div>
        </div>
      </div>
      <SearchPerfumeResults query={this.state.query && `/perfume_search/by_concepts/?conceptIds=${this.state.query}`} onPerfumeSelect={this.onPerfumeSelect}></SearchPerfumeResults>
      <PerfumeAndPanel in={this.state.currentPerfume!=null} data={this.state.currentPerfume} onBackgroundClick={this.onPerfumeClose}>
        <PerfumeFullView data={this.state.currentPerfume} onClose={this.onPerfumeClose}></PerfumeFullView>
      </PerfumeAndPanel>

    </div>
  }
}

