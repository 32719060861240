import React, { Component } from 'react'
import { connect } from 'react-redux'
// import {addPerfume} from '../../store/modules/profile'
import './PerfumeFullView.scss'
import QuadPack from '../legacy/QuadPack'
import ButtonWithFeedback from './ButtonWithFeedback'
import PerfumeCard from './PerfumeCard'
import t from '../../i18n/strings'
import ConnectedWithRating from './ConnectedWithRating'
import Donut from '../sections/profile/Donut'
import { withSize } from 'react-sizeme'
import { eventTracker } from '../../services/tracker'

class PerfumeFullView extends Component {


  state = {
    // currentRating: null,
    currentSection: 'ingredients',
    currentSuperSection: 'card'
  }

  componentWillMount () {
    // let type = this.props.recType || this.props.data.type
    // let initialSection = (type === 'family')?'families':'ingredients'
    let initialSection = 'ingredients'
    this.props.onModeChange && this.props.onModeChange({section: initialSection, superSection:'card'})
    this.setState({currentSection: initialSection})
  }
  componentDidMount() {
    eventTracker.emitEvent('VIEW_OPENED', { type: 'PERFUME_VIEWED', data: { perfumeId: this.props.data.id } })
  }
  toggleSuperSection = () => {
    let newSuperSection
    if(this.state.currentSuperSection === 'card') newSuperSection = 'more'
    else newSuperSection = 'card'
    this.setState({currentSuperSection: newSuperSection})
    this.props.onModeChange && this.props.onModeChange({section: this.state.currentSection,superSection:newSuperSection})
  }
  onClose = () => {
    this.props.onClose()
  }
  onSectionClicked = (section) =>{
    this.props.onModeChange && this.props.onModeChange({section: section, superSection: this.state.superSection})
    this.setState({currentSection: section})
  }
  render () {
    let desc = this.props.data.description
    let ings = this.props.data.ingredientProportions.sort((a,b)=>b.proportion-a.proportion).map(d => (
      <div className='ingredient' key={d.ingredient.id}>{d.ingredient.name}</div>
    ))
    let currentSectionView = null
    if (this.state.currentSection === 'description') {
      currentSectionView = (
        <div className='current-section description' dangerouslySetInnerHTML = {{__html: desc}}></div>
      )
    } else if (this.state.currentSection === 'ingredients') {
      currentSectionView = (
        <div className='current-section ingredients'>
          <div className="quad-wrapper">
            <QuadPack data={this.props.data.ingredientProportions} width={400} height={300} defaultColor={this.props.data.familyColor}></QuadPack>
          </div>
          {ings.slice(5).length>0 &&
            <div className="ings-list-list">
              <div className="ings-list-label">{t("less_dominant")}</div>
              <div className="ings-list">
                {ings.slice(5)}
              </div>
            </div>
          }
        </div>
      )
    } else if (this.state.currentSection === 'families') {
      let families = [{
        family: this.props.data.family,
        notes: [{...this.props.data.secondaryFamily,value:1}],
        value:true
      }]
      currentSectionView = (
        <div className='current-section families'>
          <FamilyDonutColumn showNotes={true} data={families} onClickImage={this.toggleSuperSection}></FamilyDonutColumn>
          <div className="family-wrapper">
            <div className="family-text">{t("family")} {this.props.data.family.name}</div>
            <div className="subfamily-text">{t("subfamily")} {this.props.data.secondaryFamily.name}</div>
          </div>
        </div>
      )
    }
    let sectionMenu = <div className='menu-list'>
        <div className={'menu-item ' + (this.state.currentSection === 'ingredients' ? 'selected': 'unselected')}
          onClick={() => this.onSectionClicked('ingredients')}
        >
          {t('ingredients')}
        </div>
        <div className={'menu-item ' + (this.state.currentSection === 'families' ? 'selected': 'unselected')}
          onClick={() => this.onSectionClicked('families')}
        >
          {t('olfactive_families')}
        </div>
        <div className={'menu-item ' + (this.state.currentSection === 'description' ? 'selected': 'unselected')}
          onClick={() => this.onSectionClicked('description')}
        >
          {t('description')}
        </div>
      </div>

    let perfumeCard = <PerfumeCard
        data ={this.props.data}
        similarity={this.props.similarity}
        currentRating={this.props.currentRating}
        wished={this.props.wished}
        showEvaluate={this.props.showEvaluate}
        onEvaluatePerfume={this.props.onEvaluatePerfume}
        nameOnTop={true}
        showBuyIt={true}
        onClickImage={this.toggleSuperSection}
        // showSimilarity={true}
        showMiniDonut={false}
        showHeart={false}
        showAddToWishlist={true}
        familiesTop={true}
      />
    let moreView = <div className="right">
        {sectionMenu}
        <div className='current-section-container'>{currentSectionView}</div>
    </div>
    return (
      <div className="perfume-full-view">
        {perfumeCard}
        {moreView}
        <ButtonWithFeedback className="close-button" onClick={this.onClose}></ButtonWithFeedback>
      </div>
    )
  }

}
PerfumeFullView.defaultProps = {
  showEvaluate: false
}
const mapStateToProps = (state) => {
  return state
}
export default connect(mapStateToProps)(ConnectedWithRating(PerfumeFullView));

class _FamilyDonutColumn extends Component {

  state = {
  }
  render (){
    let radius = this.props.size.width*0.9
    return (
      <div className="family-donut-column" onClick={this.props.onClickImage}>
        <Donut {...this.props} width={radius} height={radius}></Donut>
      </div>
    )
  }
}

const FamilyDonutColumn =  withSize()(_FamilyDonutColumn)