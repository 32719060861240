import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './Header.scss'
import { connect } from 'react-redux'
import store from '../../store/store'
// import {resetUser} from '../../store/modules/user'
// import {arraysEqual} from '../../utils'
import qs from 'qs'
import t from '../../i18n/strings';
import { withSize } from 'react-sizeme'
import { isMobile } from 'react-device-detect';
import {markRecomendationsAsViewed} from '../../store/modules/profile'
import { eventTracker } from '../../services/tracker';

const ReffedLink = React.forwardRef((props, ref) => (
  <div className={"reffed-link "+(props.selected?'selected':'')} ref={ref}>
    <Link {...props}>
      {props.children}
    </Link>
  </div>
));

class Header extends Component {
  state = {
    wishCount: 0,
    flashWishes: false,
    panelOpen: false
  }
  warnTimer = null
  updateTimer = null
  onStateChange = () => {
    // let newRecomendations = store.getState().profile.recomendations || []
    let oldRecomendations = store.getState().profile.oldRecomendations || []
    let recosViewed = store.getState().profile.recosViewed
    // let areEqual = arraysEqual(oldRecomendations,newRecomendations,(d)=>d.perfume.id)
    // console.log(oldRecomendations,newRecomendations)
    if(this.warnTimer) clearTimeout(this.warnTimer)
    let newRecos = store.getState().session['for-who']==='self' && oldRecomendations.length>0 && !recosViewed
    this.setState({newRecomendations: newRecos})
    if(newRecos){
      this.warnTimer = setTimeout(()=>{
        this.resetWarn()
      },3500)
    }
  }
  resetWarn = () => {
    this.props.dispatch(markRecomendationsAsViewed())
    this.setState({newRecomendations: false})
  }
  componentDidMount () {
    store.subscribe(this.onStateChange)
    this.updateTimer = setTimeout(()=> {this.componentDidUpdate()},300)
  }
  componentWillUnmount () {
    if(this.warnTimer) clearTimeout(this.warnTimer)
    if(this.updateTimer) clearTimeout(this.updateTimer)
  }
  static getDerivedStateFromProps (props,state) {
    if(props.profile.wishes.length !== state.wishCount){
      state.newWishes = props.profile.wishes.length - state.wishCount
      state.wishCount = props.profile.wishes.length
      state.flashWishes = true
    } else {
      state.flashWishes = false
    }
    return state
  }
  componentDidUpdate () {
    // let currentButton;
    // switch (this.props.location.pathname.split('/')[1]){
    //   case 'profile':
    //     currentButton = this.profileButton
    //     break;
    //   case 'evaluations':
    //     currentButton = this.evaluationsButton
    //     break;
    //   case 'recomendations':
    //     currentButton = this.recomendationsButton
    //     break;
    //   case 'explorer':
    //     currentButton = this.explorerButton
    //     break;
    //   case 'howto':
    //     currentButton = this.howtoButton
    //     break;
    //   default:
    //     break;
    // }
    // if(this.underline){
    //   let underlineWidth = currentButton && (currentButton.clientWidth - 40)
    //   let underlinePos = currentButton && (currentButton.offsetLeft + 20)
    //   let warnPos = this.warnPopup && this.recomendationsButton && (this.recomendationsButton.offsetLeft + this.recomendationsButton.clientWidth/2 - this.warnPopup.clientWidth/2)
    //   let warnWishPos = this.warnWishPopup && this.wishListButton && (this.wishListButton.offsetLeft + this.wishListButton.clientWidth/2 - this.warnWishPopup.clientWidth/2)


    //   this.underline.style.left = underlinePos+'px'
    //   this.underline.style.width = underlineWidth+'px'
    //   if (this.warnPopup) this.warnPopup.style.left = warnPos+'px'
    //   if (this.warnWishPopup) this.warnWishPopup.style.left = warnWishPos+'px'
    //   if(this.state.flashWishes){
    //     setTimeout(()=>{
    //       this.setState({flashWishes: false, newWishes: null})
    //     },1200)
    //   }
    // }

    let warnPos = this.warnPopup && this.recomendationsButton && (this.recomendationsButton.offsetLeft + this.recomendationsButton.clientWidth/2 - this.warnPopup.clientWidth/2)
    if (this.warnPopup) this.warnPopup.style.left = warnPos+'px'
  }

  showWishList = () => {
    // this.setState({showWishList: true})
    let query = qs.parse(this.props.location.search.slice(1))
    query.wishlist = true
    this.props.history.push({search:qs.stringify(query)})
  }

  onSaveNotLogged = () => {
    eventTracker.emitEvent('ACTION', {
      type: "SAVE", data: {
        profile: {
          concepts: this.props.profile.concepts.map(c => c.id),
          perfumes: this.props.profile.perfumes.filter(p => p.value > 2).map(p => p.id)
    } } })
    // window.location.href = process.env.REACT_APP_NOCIBEURL + '/DiagParfum?connect=true'
  }
  
  showResetPopup = () => {
    let query = qs.parse(this.props.location.search.slice(1))
    query.reset = true
    this.props.history.push({search:qs.stringify(query)})
  }
  
  // not used anymore
  // onSaveLogged = () => {
  //   window.location.href = 'https://www.nocibe.fr'
  // }
  togglePanel = () => {
    this.setState({panelOpen:!this.state.panelOpen})
  }
  // hideWishList = () => {
  //   // this.setState({showWishList: true})
  //   let query = qs.parse(this.props.location.search.slice(1))
  //   delete query.wishlist
  //   this.props.history.push({search:qs.stringify(query)})
  // }
  // markRecsViewed = () =>{
  //   if(!this.props.profile.recosViewed) this.props.dispatch(markRecomendationsAsViewed())
  // }
  render () {
    let showWarn = this.state.newRecomendations && this.props.session['for-who']==='self' && this.props.location.pathname !== '/wizard' && this.props.location.pathname !== '/'
    if(isMobile){
      return <div className={"app-header noprint " + ((this.props.location.pathname === '/wizard' || this.props.location.pathname === '/') ? 'empty' : '')} onClick={this.resetWarn}>
        <div className="app-header-top">
          <div className="burger-wrapper">
            <div className="burger" onClick={()=>this.setState({panelOpen: !this.state.panelOpen})}></div>
          </div>
          <div className="logo-wrapper">
            <div onClick={this.showResetPopup} className={'logo'} />
            {/* <div className="logo"></div> */}
          </div>
          <div className="shortcuts">
            <div className="shortcut-btn wish-list-button" ref={(el)=>this.wishListButton=el} onClick={this.showWishList}>
              <div className={"wish-list-icon"+(this.props.profile.wishes.length>0?' filled':'')+(this.state.flashWishes?' flashing':'')}></div>
                <div className="wish-list-text">{this.props.profile.wishes.length}</div>
              </div>
          </div>
          {showWarn &&
            <div className="warn-popup" ref={(el)=>this.warnPopup=el}>
              <span>{t('you_have_new_recomendations')}</span>
            </div>
          }
        </div>
        <div className={"panel-wrapper "+(this.state.panelOpen?'open':'')}>
          {/* <div className="panel-bkg" onClick={()=>this.setState({panelOpen: false})}></div>
          <div className="close-btn" onClick={()=>this.setState({panelOpen: false})}>&times;</div> */}
          <div className={"panel "+(this.state.panelOpen?'open':'')}>
            <a href="https://www.nocibe.fr/" onClick={this.togglePanel} className={"nav-button"} rel="noopener noreferrer" target="_blank">
              <span>{t('header_revenir_au_site')}</span>
            </a>
            <ReffedLink ref={(el) => this.profileButton = el} to="/profile" onClick={this.togglePanel} className={"nav-button"} selected={(this.props.location.pathname === '/profile')}>
              <span>{t('header_my_olfactive_profile')}</span>
            </ReffedLink>
            <ReffedLink ref={(el)=>this.evaluationsButton=el} onClick={this.togglePanel} to="/evaluations" className={"nav-button"} selected={(this.props.location.pathname === '/evaluations')}>
              <span>{t('header_evaluations')}</span>
            </ReffedLink>
            <ReffedLink ref={(el)=>this.recomendationsButton=el} onClick={this.togglePanel} to="/recommendations" className={"nav-button"} selected={(this.props.location.pathname === '/recommendations')}>
              <span>{t('header_recomendations')}</span>
            </ReffedLink>
            <ReffedLink ref={(el)=>this.explorerButton=el} onClick={this.togglePanel} to="/explorer" className={"nav-button"} selected={(this.props.location.pathname.indexOf('/explorer')>=0)}>
              <span>{t('header_fragrance_explorer')}</span>
            </ReffedLink>
            <ReffedLink ref={(el)=>this.howtoButton=el} onClick={this.togglePanel} to="/howto" className={"nav-button"} selected={(this.props.location.pathname === '/howto')}>
              <span>{t('how_it_works')}</span>
            </ReffedLink>
            {/* {this.props.user.linked &&
              <div className="shortcut-btn  save-button" onClick={this.onSaveLogged}>{t('save_my_profile')}</div>
            } */}
            {!this.props.user.linked && this.props.session['for-who'] === 'self' &&
              <div className="shortcut-btn nav-button save-button" onClick={this.onSaveNotLogged}>{t('save_my_profile')}</div>
            }
          </div>
        </div>
      </div>
    } else {
      return <div className={"app-header "+((this.props.location.pathname==='/wizard' || this.props.location.pathname==='/')?'empty':'')}  onClick={this.resetWarn}>
        <div className="top-header">
          <a className="go-to-nocibe" href="https://www.nocibe.fr/" rel="noopener noreferrer" target="_blank">&lt; <span>{t('header_revenir_au_site')}</span></a>
          <div onClick={this.showResetPopup} className={"logo"} />
          <div className="nav-buttons">
            <ReffedLink ref={(el)=>this.profileButton=el} to="/profile" className={"nav-button"} selected={(this.props.location.pathname === '/profile')}>
              <span>{t('header_my_olfactive_profile')}</span>
            </ReffedLink>
            <ReffedLink ref={(el)=>this.evaluationsButton=el} to="/evaluations" className={"nav-button"} selected={(this.props.location.pathname === '/evaluations')}>
              <span>{t('header_evaluations')}</span>
            </ReffedLink>
            <ReffedLink ref={(el)=>this.recomendationsButton=el} to="/recommendations" className={"nav-button"} selected={(this.props.location.pathname === '/recommendations')}>
              <span>{t('header_recomendations')}</span>
            </ReffedLink>
            <ReffedLink ref={(el)=>this.explorerButton=el} to="/explorer" className={"nav-button"} selected={(this.props.location.pathname.indexOf('/explorer')>=0)}>
              <span>{t('header_fragrance_explorer')}</span>
            </ReffedLink>
          </div>
          <div className="shortcuts">
            <div className="shortcut-btn wish-list-button" ref={(el)=>this.wishListButton=el} onClick={this.showWishList}>
              <div className={"wish-list-icon"+(this.props.profile.wishes.length>0?' filled':'')+(this.state.flashWishes?' flashing':'')}></div>
              <div className="wish-list-text">{t('wishlist')} ({this.props.profile.wishes.length})</div>
              </div>
              {/* {this.props.user.linked &&
                <div className="shortcut-btn  save-button" onClick={this.onSaveLogged}>{t('save_my_profile')}</div>
              } */}
              {!this.props.user.linked && this.props.session['for-who'] === 'self' &&
                <div className="shortcut-btn  save-button" onClick={this.onSaveNotLogged}>{t('save_my_profile')}</div>
              }
              <Link className='shortcut-btn howto-icon' to='/howto'>i</Link>
          </div>
        </div>
        {showWarn &&
          <div className="warn-popup" ref={(el)=>this.warnPopup=el}>
            <span>{t('you_have_new_recomendations')}</span>
          </div>
        }
        {/* {this.state.newWishes &&
          <div className="warn-wish-popup" ref={(el)=>this.warnWishPopup=el}>
            {this.state.newWishes>0?t('added_to_wishlist'):t('removed_from_wishlist')}
          </div>
        } */}
      </div>
    }
  }
}

const mapStateToProps = (state) => {
  return state
}
export default connect(mapStateToProps)(withSize()(Header));