import React, { Component } from 'react'
import { connect } from 'react-redux'
// import {addPerfume} from '../../store/modules/profile'
import './PerfumeRemoveView.scss'
// import Rater from './Rater'
import NocibeButton from './NocibeButton'
import ButtonWithFeedback from './ButtonWithFeedback'
import PerfumeCard from './PerfumeCard'
import t from '../../i18n/strings'

class PerfumeRemoveView extends Component {


  state = {
    currentRating: null
  }

  onClose = () => {
    this.props.onClose && this.props.onClose()
  }
  onConfirm = () => {

    this.props.onConfirm && this.props.onConfirm(this.props.data)
    this.props.onClose && this.props.onClose()
  }
  render () {

    return (
      <div className="perfume-remove-view popup">
        <div className="left">
          <PerfumeCard data ={this.props.data} showStars="true"></PerfumeCard>
        </div>

        <div className="right">
          <div className="description">{t("are_you_sure_remove_perfume")}<br/>{t("this_will_modify")}</div>
          <NocibeButton mode="empty" label={t("yes_remove")} onClick={this.onConfirm}></NocibeButton>
        </div>
        <ButtonWithFeedback className="close-button" onClick={this.onClose}></ButtonWithFeedback>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return state
}
export default connect(mapStateToProps)(PerfumeRemoveView);