import axios from 'axios'
import store from '../store/store'
const CancelToken = axios.CancelToken
let cancelRecs
let instance = axios.create({
  baseURL: process.env.BASE_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
let recoInstance = axios.create({
  baseURL: process.env.BASE_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
instance.interceptors.request.use(config => {
  // config.params = Object.assign(axiosParms || {},{tempid: localStorage.getItem('nocibe-tempid')})
  config.params = Object.assign(config.params || {}, {
    userId: store.getState().user.nocibeId,
    lang: 'fr',
    gift: store.getState().session['for-who'] === 'self' ? 0 : 1,
    gender: store.getState().session['gender']
  })
  //if(config.params.gender===undefined && config.url.indexOf('gender=')<0) config.params = Object.assign(config.params || {},{gender:store.getState().session['gender']})
  if (config.params.hostId === undefined)
    config.params = Object.assign(config.params || {}, { hostId: '1' })
  if (store.getState().user.rcuId)
    config.params.rcuId = store.getState().user.rcuId
  return config
})

// let axiosParms = {
//     // hostId: 1
// }
const translateGender = e => {
  if (e === 'U') return 'MFU'
  if (e === 'M') return 'MU'
  if (e === 'F') return 'FU'
  return e
}
recoInstance.interceptors.request.use(config => {
  // config.params = Object.assign(axiosParms || {},{tempid: localStorage.getItem('nocibe-tempid')})
  config.params = Object.assign(config.params || {}, {
    userId: store.getState().user.nocibeId,
    lang: 'fr',
    gift: store.getState().session['for-who'] === 'self' ? 0 : 1,
    // gender: translateGender(store.getState().session['gender'])
  })
  //if(config.params.gender===undefined && config.url.indexOf('gender=')<0) config.params = Object.assign(config.params || {},{gender:store.getState().session['gender']})
  if (config.params.hostId === undefined)
    config.params = Object.assign(config.params || {}, { hostId: '1' })
  if (store.getState().user.rcuId)
    config.params.rcuId = store.getState().user.rcuId
  return config
})
// instance.interceptors.response.use(response => {
//   return response
// }, error => {
//   if (error.config && !(error.config.url.indexOf('user/') !== -1) && !_.isNil(error.response) && error.response.status === 401) {
//     window.location = window.location.pathname + '#login'
//     window.location.reload()
//   } else {
//     return Promise.reject(error)
//   }
// })
const getUser = async () => {
  // return instance.get(process.env.REACT_APP_APIURL+'/user/newTempUser')
  const result = instance.get(process.env.REACT_APP_APIURL + '/user/getProfile')
  return result
}

const saveUserMeta = () => {
  // let metaPayload = {
  //   gender: gender,
  //   gift: gift
  // }
  return instance.get(process.env.REACT_APP_APIURL + '/user/saveMetadata')
}

let arrayify = arr => {
  return Array.isArray(arr) ? arr : [arr]
}
const addPerfumes = perfumes => {
  perfumes = arrayify(perfumes)
  return instance.post(
    process.env.REACT_APP_APIURL + '/favorites/perfume/add',
    perfumes.map(p => {
      return { perfumeId: p.id, rating: p.value }
    })
  )
}

const removePerfumes = perfumes => {
  perfumes = arrayify(perfumes)
  return instance.delete(
    process.env.REACT_APP_APIURL + '/favorites/perfume/remove',
    { data: perfumes.map(p => p.id) }
  )
}

const addConcepts = concepts => {
  concepts = arrayify(concepts)
  return instance.post(
    process.env.REACT_APP_APIURL + '/favorites/concept/add',
    concepts.map(c => c.id)
  )
}

const removeConcepts = concepts => {
  concepts = arrayify(concepts)
  return instance.delete(
    process.env.REACT_APP_APIURL + '/favorites/concept/remove',
    { data: concepts.map(c => c.id) }
  )
}

const addWishes = wishes => {
  wishes = arrayify(wishes)
  return instance.post(
    process.env.REACT_APP_APIURL + '/favorites/wishlist/add',
    wishes.map(w => w.id)
  )
}

const removeWishes = wishes => {
  wishes = arrayify(wishes)
  return instance.delete(
    process.env.REACT_APP_APIURL + '/favorites/wishlist/remove',
    { data: wishes.map(w => w.id) }
  )
}

// instance.getRecomendations = (gender) => {
//   cancelRecs && cancelRecs()
//   // if(!gender) gender = 'U'
//   // return instance.get(process.env.REACT_APP_APIURL+`/perfume_search/by_profile/?hostId=1&gender=`+gender,{
//   let url = process.env.REACT_APP_APIURL+`/perfume_recommendations/by_profile?hostId=1`
//   if(gender && gender !== 'U') url = url + `&gender=` + gender +',U'
//   return instance.get(url,{
//     cancelToken: new CancelToken(function executor(c){cancelRecs = c})
//   })
// }
const getRecomendations = () => {
  cancelRecs && cancelRecs()
  // if(!gender) gender = 'U'
  // return instance.get(process.env.REACT_APP_APIURL+`/perfume_search/by_profile/?hostId=1&gender=`+gender,{
  // let url = process.env.REACT_APP_APIURL+`/perfume_recommendations/by_profile?hostId=1`
  let perfumes = store.getState().profile.perfumes.filter(p => p.value >= 3)
  let excludedPerfumes = store
    .getState()
    .profile.perfumes.filter(p => p.value < 3)
  let concepts = store.getState().profile.concepts
  if (perfumes.length === 0)
    return new Promise((resolve, reject) => {
      resolve({ data: [], noFeched: true })
    })
  let perfumeIds = perfumes.map(p => p.id).join(',')
  let exludedIds = excludedPerfumes.map(p => p.id).join(',')
  let conceptIds = concepts.map(c => c.id)
  let gender = translateGender(store.getState().session['gender'])
    .split('')
    .join(',')
  // console.log(gender)
  // &included.gender=${translateGender(store.getState().session['gender']).split().join(",")}`
  let url =
    process.env.REACT_APP_APIURL +
    `/v7` +
    // 'http://localhost:3001/api/nocibe'+
    `/reco?perfumes=${perfumeIds}&excluded.perfumes=${exludedIds}&intensityConcepts=${conceptIds}&limit=12&included.gender=${gender}`
  // if(gender && gender !== 'U') url = url + `&gender=` + gender +',U'
  return recoInstance
    .get(url, {
      withCredentials: false,
      cancelToken: new CancelToken(function executor (c) {
        cancelRecs = c
      })
    })
    .then(response => {
      return response.data
    })
}
// export default() => {
//   return instance
// }

// instance.postLinkItem = (linkItem) => {
//   return instance.post(process.env.REACT_APP_APIURL + '/connect', linkItem)
// }
const downloadRecomendedUser = function (perfumeId, email, password) {
  let qs = new URLSearchParams()
  qs.append('perfume', perfumeId)
  qs.append('email', email)
  qs.append('password', password)
  return instance.get(
    process.env.REACT_APP_APIURL + `/usage/generateInvRecoCSV?${qs.toString()}`
  )
}

//export default instance
export default {
  get: instance.get,
  post: instance.post,
  getUser,
  saveUserMeta,
  addPerfumes,
  removePerfumes,
  addConcepts,
  removeConcepts,
  addWishes,
  removeWishes,
  getRecomendations,
  downloadRecomendedUser
}
