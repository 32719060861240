const lang = {
  //Wizard.jsx
  what_are_you_looking_for: 'What are you looking for?',
  something_for_myself: 'Something<br>for myself',
  a_gift_for_someone_else: 'A gift for<br>someone else',
  you_are_looking_for:'You are looking for something...',
  masculine: 'Masculine',
  femenine: 'Femenine',
  both: 'Both',
  ok_lets_build_1: "OK, let's build an Olfactive Profile...",
  ok_lets_build_2: "Let's start by looking for your favourite fragrances.",
  what_is_your_favourite_fragrance: 'What is your favourite fragrance?',
  results: 'results',
  would_you_like_to_addd_another: 'Would you like to add another fragrance?',
  want_to_add_more: 'Do you want to add another perfume?',
  loading: 'Loading...',
  no_results: 'No results! Try a different search.',
  selected_fragrances: 'Selected fragrances',
  which_of_theese_words: 'Which of theese words describe your ideal fragrance?',
  at_least_3: 'Select at least 3',
  next_step: 'NEXT STEP',
  step: 'Step',
  search: 'Search',
  your_olfactive_profile: 'Your Olfactive Profile',
  my_olfactive_profile: 'My Olfactive Profile',
  //Profile.jsx
  your_favourite_ingredients: 'Your favourite ingredients',
  your_profile_description: 'Your profile description',
  you_love: "You love fragrances of the ",
  but_also: "But you also like notes from the ",
  family: "family",
  families: "families",
  
  you_love_many: 'You apprecieate many different olfactive families.',
  you_love_few: 'You have a very well defined taste when it comes to fragrances.',
  mostly_intense: 'They are mostly intense and full of character.',
  mostly_balanced: 'They are mostly well balanced. Not too strong nor too fresh and light.',
  mostly_fresh: 'They are mostly light, fresh and easy to wear.',
  furthermore_i_detect_single: 'Furthermore, I detect you have a preference for %FAMILY% nuances in your favourite fragrances',
  furthermore_i_detect_broad: 'Furthermore, I detect you have a broad pallete of nuances that complements the fragrances you love',
  ingredeints_are_favourite_singular: '%INGS% is clearly a favourite of yours',
  ingredeints_are_favourite_plural: '%INGS% are clearly favourites of yours',
  repeated_brand: 'Perfumer %BRAND% has signed %COUNT% of your favorite scents (%PERFUMES%)',
  
  reco_desc_title: 'We recomend this perfume because',
  possible_reco_title: 'You may like this perfume because',
  expert_reco: "Expert recommendation",
  recommended_by_hero: 'you love the ingredeint <b>%HERO%</b>',
  // recommended_by_ings: 'it has some of $possessive_plural favourite ingredients: <b>%INGS%</b>',
  recommended_by_ings: 'some ingredients $that_you_love dominate in this perfume:',
  recommended_by_ings_short: 'some of its less dominant ingredients: <b>%INGS%</b>',
  recommended_by_fam: 'it belongs to one of $possessive_plural favourite families, <b>%FAMILY%</b>',
  not_recommended_by_fam: 'even though it does not belong to one of $possessive_plural favourite families',
  also: 'also',
  and: 'and',
  
  view_recomendations: 'View recommendations',
  share: 'Share',
  share_this_experience: "Share this experience with your friends",
  or_copy_link: "Or copy link",
  copy: 'Copy',
  create_new_profile: 'Create New Profile',
  evaluate_my_recomendations: 'Evaluate my recommendations',
  edit_my_profile: 'Edit my profile',
  view_my_profile: 'View my profile',
  my_recomendations_to_evaluate: 'My recommendations to evaluate',
  apply: 'Apply',
  update_ratings: 'Update ratings',

  //Recomendations.jsx
  all: 'All',
  brand: 'Brand',
  olfactive_family: 'Olfactive Family',
  price: 'Price',
  season: 'Season',
  top_6_recomendations: 'Top 4 Recommendations',
  all_recomendations: 'All recommendations',
  recomendations_according: 'Recommendations according to your profile',
  if_you_didnt_find: "If you didn't find your ideal fragrance, modify your profile to get new recommendations",

  //Explorer
  by_name: 'By brand',
  by_ingredients: 'By ingredients',
  by_concepts: 'By concepts',
  find_perfumes: 'Find perfumes',

  //PerfumeRow.jsx
  add_to_profile: 'Add to my profile',
  view_details: 'View details',
  select: 'Select',
  from: 'from',
  buy_it_now: 'Buy it now',

  //PerfumeCard
  profile_similarity: 'Profile similarity',
  evaluate_recomendation: 'Evaluate recommendation',
  add_to_wishlist: 'Add to wishlist',
  remove_from_wishlist: 'Remove from wishlist',
  wished: 'Wished',
  new_recomendation: 'New recommendation',

  //PerfumeFullView
  ingredients: 'Ingredients',
  description: 'Description',
  olfactive_families: 'Olfactive families',
  your_favourite_ingredients_present:'Your favourite ingredients present in this fragrance',


  //header/wishlist
  fragrance_explorer: 'Explore the perfumes universe',
  recomendations: 'Recommendations',
  evaluations: 'Evaluations',
  wishlist: 'Wishlist',
  your_wish_list: 'Your wish list',
  your_wish_list_is_empty: 'Your list is empty',
  you_have_new_recomendations: 'You have new Recommendations',


  are_you_sure_you_want_to_create:'Are you sure you want to create a new profile without saving your current one?',
  save_my_profile:'Save My Profile',
  yes_create_new:'Yes, Create a new profile',
  your_evaluations: 'Your evaluations',
  your_fragrances: 'Your fragrances',
  your_fragrances_list: 'Your fragrances list',
  your_fragrances_subtitle: '',
  add_fragrance: 'ADD FRAGRANCE',
  words_that_describe_you: 'Words that describe you',
  add_descriptor: 'ADD DESCRIPTOR',
  cancel: 'Cancel',

  let_us_know: "Let us know how much you love this fragrance?",

  your_recs_will_change: "Your recomenations will change according to your ratings",

  thanks_for_rating: "Thank you for your rating",

  view_new_recs: "View New Recommendations",

  less_dominant: "Less dominant ingredients",
  
  also_less_dominant: "As well as others, more subtly present",

  are_you_sure_remove_perfume: "Are you sure you want to remove this perfume?",

  this_will_modify: "This will modify your olfactive profile and your recommendations",

  view_more_recs: "View more recommendations",
  welcome_to_explorer: 'Welcome to Fragrance Explorer',
  why_is_this_recommendation: 'Why is this is a recommendation?'
}
export default lang