import en from './lang/en'
import es from './lang/es'
import fr from './lang/fr'
import store from '../store/store'

let allStrings = {
  en:en,
  es:es,
  fr:fr
 };
 let storeInstance = store

 function replaceArticles (str) {
   let keys, propperKey, propperKeyNoG, g, w
   if(str.indexOf('$')<0 || str.indexOf('[')===0) return str
   else keys = str.match(/\$[a-zA-Z_]*/g)
   g = store.getState().session.gender
   if(g==='U') g = 'M'
   w = store.getState().session['for-who']
   return keys.reduce((result,key)=>{
    propperKey = w==='self'?key+'_self':key+'_other_'+g
    propperKeyNoG = w==='self'?key+'_self':key+'_other'
    return result.replace(key,flatT(propperKey) || flatT(propperKeyNoG) || '['+propperKey+']')
   },str)
 }
 function flatT (key) {
   return allStrings[storeInstance.getState().language.lang][key]
 }
 export default function t (key) {
   return replaceArticles(allStrings[storeInstance.getState().language.lang][key]|| '['+key+']')
 }
 export function currentLanguage () {
   return storeInstance.getState().language.lang
 }