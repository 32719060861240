import React from 'react'
import { connect } from "react-redux"
import {addWish,removeWish} from '../../store/modules/profile'
import {withRouter} from 'react-router'
import qs from 'qs'
const mapStateToProps = state => {
  return {
    ...state
  }
}

//Tambien sirve para {similarity:xx,perfume:{}} o sea que el nombre es un poco malo...
export default function ConnectedWithRating(WrappedComponent ) {

  let normal = class extends React.Component {
    onToggleWished = () => {
      if(this.props.profile.wishes.findIndex((w)=>w.id === this.props.data.id) >= 0){
        this.props.dispatch(removeWish({id: this.props.data.id, perfume: this.props.data}))
      } else {
        this.props.dispatch(addWish({id: this.props.data.id, perfume: this.props.data}))
        // GUARRERO que no "haya" una function "en algun sitio" OpenWishlist...
        let query = qs.parse(this.props.location.search.slice(1))
        query.wishlist = true
        this.props.history.push({search:qs.stringify(query)})
      }
    }
    // onUnWished = () => {
    //   this.props.dispatch(removeWish({id: this.props.data.id, perfume: this.props.data}))
    // }
    render() {
      let derivedProps;
      if(this.props.data && this.props.data.perfume){
        derivedProps = {
          ...this.props,
          currentRating: this.props.data.value,
          similarity: this.props.data.similarity,
          data: this.props.data.perfume,
          recType: this.props.data.type
        }
      } else {
        derivedProps = {
          ...this.props,
        }
      }
      let candidate = derivedProps.data!=null && this.props.profile.perfumes.find((p)=>p.id === derivedProps.data.id)
      if (candidate) derivedProps.currentRating = candidate.value

      derivedProps.onToggleWished = this.onToggleWished
      // derivedProps.onUnWished = this.onUnWished
      let wishCandidate = derivedProps.data!=null && this.props.profile.wishes.find((p)=>p.id === derivedProps.data.id)
      if(wishCandidate) derivedProps.wished = true

      return <WrappedComponent {...derivedProps} />;
    }
  }
  return withRouter(connect(mapStateToProps)(normal))
}
