import React from 'react'
import t from '../../../i18n/strings';
import BetterInput from '../../common/BetterInput';
import PerfumesList from '../../common/PerfumesList';
import Apis from '../../../services/Api'
import Axios from 'axios';
import './Admin.scss'
function useFetch(path) {
    const [isFetching, setIsFetching] = React.useState(false)
    const [result, setResult] = React.useState(null)
    const [error, setError] = React.useState(null)
    let token = React.useRef(null)

    React.useEffect(() => {
        if (token.current) token.current()
        setIsFetching(true)
        Apis.get(
            process.env.REACT_APP_LAMDBAURL + path,
            { cancelToken: new Axios.CancelToken(function executor(c) { token.current = c }), params: { hostId: '1' } }
        ).then(res => {
            setResult(res.data.data)
            setIsFetching(false)
        }).catch(err => setError(err))
    }, [path])
    return {
        isFetching,
        result,
        error
    }

}

export default function (props) {
    
    const [query, setQuery] = React.useState(null)
    const request = useFetch(`/nocibe/search?searchTerm=${query}`)
    return <div className='question perfumes-question'>
        <div className='perfumes-left'>

            <BetterInput
                onSearchQueryChange={setQuery}
                placeholder={t('search_a_perfume')}
            />
            <div className="results-wrapper">
                {request.result && <PerfumesList
                    data={request.result}
                    onSelect={props.onDownload}
                    showCheckbox={true}
                    isDownloaded={props.isDownloaded}
                    onCheckboxChange={props.onDownload}
                />}

                {request.isFetching &&
                    <div className="loading">
                        <div className="text">{t('loading')}</div>
                    </div>
                }
                {(!request.isFetching && request.result && request.result.length === 0) &&
                    <div className="no-results">
                        <div className="text">{t('no_results')}</div>
                    </div>
                }
            </div>
        </div>
    </div>
}