/* eslint-disable no-cond-assign */
import React, { Component } from "react";
import * as d3 from "d3";
import * as Utils from '../../../utils'
import "./Donut.scss";
import FamiliesRing from "./FamiliesRing";
import {isMobile} from "react-device-detect"
import { connect } from "react-redux"
import {getUserFamilies} from '../../../store/selectors/getUserFamilies'

// import * as Utils from "../../utils.js"
class Donut extends Component {
  constructor(props) {
    super(props);
    this.instanceId = new Date().getTime()
    this.drawDonut = this.drawDonut.bind(this);
    this.currentAngle = Math.PI;
  }

  componentDidMount() {
    this.drawDonut();
  }

  componentDidUpdate() {
    this.drawDonut();
  }

  measure() {
    const height = this.props.height;
    const width = this.props.width;
    if(isMobile) this.r = (Math.min(height, width) * 0.92) / 2;
    else this.r = (Math.min(height, width) * 0.85) / 2;
  }

  compareFamilies = (a,b) => {
    return window.familiesOrder.indexOf(a.family.name) - window.familiesOrder.indexOf(b.family.name)
  }
  compareSubFamilies = (a,b) => {
    return window.familiesOrder.indexOf(a.name) - window.familiesOrder.indexOf(b.name)
  }
  drawDonut() {
    let self = this;
    this.measure();
    const node = this.node;

    var svg = d3.select(node);

    // const selection = this.props.label;
    const height = this.props.height;
    const width = this.props.width;
    const r = this.r; //Math.min(height,width)*0.8/2
    const innerR = r * 0.35;
    const smallR = r * 0.6;
    this.innerR = innerR
    this.smallR = smallR
    this.familyDr = isMobile?this.r*0.07:this.r*0.05
    svg.attr("height", height);
    svg.attr("width", width);

    // let root = svg.selectAll('.root').data(['root'],(d)=>d)
    // let rootEnter = root.enter().append('g').attr('class','root')
    // root = root.merge(rootEnter)
    let root = svg.select(".root");

    let donutRoot = root.select(".donut-wrapper");


    let data = JSON.parse(JSON.stringify(this.props.data));
    var arc = d3
      .arc()
      .innerRadius(innerR)
      .outerRadius(r)
      .padAngle(2.5/r);

    var smallArc = d3
      .arc()
      .innerRadius(innerR-1)
      .outerRadius(()=>this.props.showNotes?smallR:innerR)
      .padAngle(0);
    var smallArcSelection = d3
      .arc()
      .innerRadius(innerR-1)
      .outerRadius(()=>this.props.showNotes?smallR:innerR)
      .padAngle(0.01);

    var smallFamilyArc = d3
      .arc()
      .innerRadius(smallR-this.familyDr)
      .outerRadius(smallR)
      .padAngle(0);

    var pie = d3
      .pie()
      .sort(null)// .sort(this.compareFamilies)
      .value(function(d) {
        return d.value;
      })
      // cuidao, hacemos "esta locura" para matchear con el familiesRing
      // .startAngle(this.sdTheta / 2 + Math.PI + Math.PI * 2)
      // .endAngle(this.sdTheta / 2 + Math.PI);
      .startAngle(0)
      .endAngle(2*Math.PI);

    var arcTween = function(a) {
      let node = this.parentNode
      var i = d3.interpolate(node._current, a);
      node._current = i(0);
      return function(t) {
        return arc(i(t));
      };
    };
    var smallArcTween = function(a) {
      let node = this.parentNode
      var i = d3.interpolate(node._current, a);
      let r0 = self.props.showNotes?smallR:innerR
      var ri = d3.interpolate(node._current.data.radius || r0, a.data.radius)
      var ease
      if (node._current.data.radius<a.data.radius) ease = d3.easeBackOut
      else ease = d3.easeBackIn
      node._current = i(0);
      node._current.data.radius = ri(0)
      return function(t) {
        return smallArc.outerRadius(ri(ease(t)))(i(t));
      };
    };
    var arcTweenDeep = function(a) {
      let node = this.parentNode.parentNode
      var i = d3.interpolate(node._current, a);
      node._current = i(0);
      return function(t) {
        return arc(i(t));
      };
    };

    function findNeighborArc(i, data0, data1, key) {
      var d;
      return (d = findPreceding(i, data0, data1, key))
        ? { data:d.data, startAngle: d.endAngle, endAngle: d.endAngle }
        : (d = findFollowing(i, data0, data1, key))
        ? { data:d.data, startAngle: d.startAngle, endAngle: d.startAngle }
        : null;
    }

    // Find the element in data0 that joins the highest preceding element in data1.
    function findPreceding(i, data0, data1, key) {
      var m = data0.length;
      while (--i >= 0) {
        var k = key(data1[i]);
        for (var j = 0; j < m; ++j) {
          if (key(data0[j]) === k) return data0[j];
        }
      }
    }

    // Find the element in data0 that joins the lowest following element in data1.
    function findFollowing(i, data0, data1, key) {
      var n = data1.length,
        m = data0.length;
      while (++i < n) {
        var k = key(data1[i]);
        for (var j = 0; j < m; ++j) {
          if (key(data0[j]) === k) return data0[j];
        }
      }
    }


    //////////////////////////////////////////
    // clip paths
    //////////////////////////////////////////
    // let clips = donutRoot
    //   .selectAll(".clip-path")
    //   .data(pie(data), function(d, i) {
    //     return d.data.id;
    //   });

    // let clipsEnter = clips
    //   .enter()
    //   .append("clipPath")
    //   .attr("class", "clip-path")
    //   .attr("id", d => "clip-" + d.data.id);
    // // .attr('clipPathUnits',"objectBoundingBox")

    // clipsEnter
    //   .append("path")
    //   .attr("fill", "#000")
    //   .attr("stroke", "#fff")
    //   .attr("d", arc)
    //   .each(function(d) {
    //     this._current = d;
    //   });

    // clips.exit().remove();

    // clips = clips.merge(clipsEnter);
    // // clips.select('path').attr('d',arc)
    // clips
    //   .select("path")
    //   .transition()
    //   .duration(900)
    //   .attrTween("d", arcTween);

    //////////////////////////////////////////
    // actual items
    // - de momento no hacen falta para nada...
    //////////////////////////////////////////

    let key = d => {
      return d.data.family.id;
    };
    let items = donutRoot.selectAll(".item");
    var data0 = items.data(),
    data1 = pie(data);

    items = items.data(data1, key);




    let itemsEnter = items
      .enter()
      .append("g")
      .attr("class", "item");
    itemsEnter
      .each(function(d, i) {
        this._current = findNeighborArc(i, data0, data1, key) || {
          data: {},
          startAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
          endAngle: d.startAngle + (d.endAngle - d.startAngle) / 2
        };
      })

    itemsEnter.append('path')
      .attr('class','main-wedge')
      .attr("fill", function(d) {
        return d.data.family.color;
      });
    itemsEnter.append('g')
      .attr('class','image-container')

    itemsEnter.append("clipPath")
      .attr("class", "clip-path")
      .attr("id", d => {
        return "donut-clip-"+this.instanceId+'-'+ d.data.family.id
      })
      .append('path')
        .attr("fill", function(d) {
          return '#000';
        })


    let instanceId = this.instanceId
    itemsEnter.each(function(d){
      let centroid = arc.centroid(d);
      let x = centroid[0];
      let y = centroid[1];
      let theta =  Math.atan2(y,x);
      let imageSize =  3*r //Math.abs(d.endAngle-d.startAngle)>Math.PI ? 3*r : 3*r
      x = Math.cos(theta)*r/2 - imageSize/2
      y = Math.sin(theta)*r/2 - imageSize/2
      // x = -imageSize/2
      // y = -imageSize/2
      // let selfNode = this
      const img = document.createElementNS('http://www.w3.org/2000/svg','image');
      // const href = Utils.getFamilyImageUrl(d.data.family.imageUrl)
      // VEYR FAKE!!!! no estan llegando...
      // const href = Utils.getFamilyImageUrl('https://projects.bestiario.org/puig/ingredient_images/lemon-4150.jpg')
      // const href = process.env.PUBLIC_URL + '/images/families/'+d.data.family.name.split(' ').join('_')+'.jpg'
      // const href = 'https://projects.bestiario.org/puig/ingredient_images/lemon-4150.jpg'
      const href = d.data.family.imageUrl
      img._currentTheta = theta
      // console.log('predecoding ',href)

      // img.decode().then(function(){
      //   // console.log(selfNode)
      //   d3.select(selfNode).select(function(){
      //     // console.log(selfNode)
      //     return d3.select(selfNode).select('.image-container').node().appendChild(img)
      //   })
      //   .attr("width", imageSize)
      //   .attr("height", imageSize)
      //   .attr("transform-origin", (x + imageSize/2) + " " + (y + imageSize/2))
      //   .attr("x", x)
      //   .attr("y", y)
      //   // .attr(
      //   //   "transform",
      //   //   "rotate(" + -(180*self.currentAngle/Math.PI)+ ") translate(" + 0 + "," + 0 + ") "
      //   // );

      //   d3.select(selfNode).attr('clip-path','url(#donut-clip-'+instanceId+'-'+d.data.family.id+')')


      // }).catch(() => {
      //     // throw new Error('Could not load/decode big image.',href);
      //     console.warn('Could not load/decode big image.',href);
      // });
      img.onload = ()=>{
        d3.select(this).select('.image-container').append(()=>img)
          .attr("width", imageSize)
          .attr("height", imageSize)
          .style("transform-origin", (x + imageSize/2) + "px " + (y + imageSize/2)+"px")
          .attr("x", x)
          .attr("y", y)
        d3.select(this).attr('clip-path','url(#donut-clip-'+instanceId+'-'+d.data.family.id+')')
      }
      img.onerror = ()=>{
        // d3.select(this).style('background-image',`url(${noImage})`)
        d3.select(this).classed('no-image',true)
      }
      if(href) img.setAttributeNS('http://www.w3.org/1999/xlink', 'href', href );

      // BASURA EXPLORER QUE NO DISPARA "onload"...
      // if (window.bowser.browser.name.indexOf('Explorer') >= 0 || window.bowser.browser.name.indexOf('Edge') >= 0 || window.bowser.browser.name.indexOf('Safari') >= 0) {
      if (true) {
        setTimeout(()=>{
          img.onload()
        },400)
      }
    })

    itemsEnter.select('.main-wedge').on('click',(d)=>{
      if (this.props.onFilterFamily && this.props.showNotes) this.props.onFilterFamily(d.data.family.id)
      else if (this.props.onSelect) this.props.onSelect(d.data.family.id)
    })

    let itemsExit = items
      .exit()
      .datum(function(d, i) {
        let result = (
          findNeighborArc(i, data1, data0, key) || {
            data: data0[i].data,
            startAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
            endAngle: d.startAngle + (d.endAngle - d.startAngle) / 2
          }
        );
        d.startAngle = result.startAngle
        d.endAngle = result.endAngle
        return d
      })
      .transition()
      .duration(900)
    itemsExit.select('path').attrTween("d", arcTween)
    itemsExit.select('.clip-path').select('path').attrTween("d", arcTweenDeep)
    itemsExit.remove();
    // items.exit().remove()

    items = items.merge(itemsEnter);
    let rawItems = items

    items.classed("selected", d => {
      return d.data.family.id === this.props.selection;
    });

    items = items
      .transition()
      .duration(900)
    items.select('path').attrTween("d", arcTween)
    items.select('.clip-path').select('path').attrTween("d", arcTweenDeep)

    // let items = donutRoot.selectAll('.item')
    //   .data(pie(data),function(d,i) {
    //     return d.data.family.id
    //   })

    // let itemsEnter = items.enter().append('g').attr('class',(d)=>'item '+d.data.name)

    // itemsEnter.append('path').attr('fill',(d)=>{return d.data.color}).attr('stroke','#fff')



    // items.exit().remove()


    // VERY FAKES ESTOS "300": Should be the actual size of the original image! (all the same, or measured at runtime... )

    items.select("image").each(function(d) {
      let centroid = arc.centroid(d);
      // let sel = d3.select(this)
      //   .transition()
      //   .duration(900)
      let x = centroid[0];
      let y = centroid[1];
      let theta = Math.atan2(y,x)
      let imageSize = 3*r //Math.abs(d.endAngle-d.startAngle)>Math.PI ? 3*r : 3*r
      x = Math.cos(theta)*r/2 - imageSize/2
      y = Math.sin(theta)*r/2 - imageSize/2
      // sel.attr("x", x - 2*r);
      // sel.attr("y", y - 2*r);

      d3.select(this).transition().duration(900).ease(d3.easeCubicInOut)
          .attr("width", imageSize)
          .attr("height", imageSize)
          .style("transform-origin", (x + imageSize/2) + "px " + (y + imageSize/2)+"px")
          .attr("x", x)
          .attr("y", y)

      // let circleXY = function() {
      //   while (this._currentTheta-theta > Math.PI) theta+=2*Math.PI
      //   while (this._currentTheta-theta < -Math.PI) theta-=2*Math.PI
      //   var i = d3.interpolate(this._currentTheta || 0, theta);
      //   this._currentTheta = i(1);
      //   let node = this
      //   return function(t) {
      //     let x = Math.cos(i(t))*r/2 - imageSize/2
      //     let y = Math.sin(i(t))*r/2 - imageSize/2
      //     node.setAttribute("x", x);
      //     node.setAttribute("y", y);
      //     // node.setAttribute("transform-origin", (x + imageSize/2) + " " + (y+imageSize/2))
      //     // node.setAttribute(
      //     //   "transform",
      //     //   "rotate(" + -(180*self.currentAngle/Math.PI)+ ") translate(" + 0 + "," + 0 + ") "
      //     // );
      //   };
      // }
      // sel.tween("attr.xy", circleXY)


      // .attr("transform-origin", (x + 2*r) + " " + (y+2*r))
      // .attr(
      //   "transform",
      //   "rotate(" + -self.currentAngle + ") translate(" + 0 + "," + 0 + ") "
      // );
    });
    // clips.each(function(d) {
    //   let centroid = arc.centroid(d);
    //   let sel = d3.select(this);
    //   let x = centroid[0];
    //   let y = centroid[1];
    //   sel.attr("x", x - 300);
    //   sel.attr("y", y - 300);
    //   sel.attr("transform-origin", x + " " + y);
    //   sel.attr("transform", "rotate(" + self.currentAngle + ")");
    // });


    // SUBWEDGES ...
    let subItems = rawItems.selectAll('.sub-wedge').data((d)=>{
        var subPie = d3
          .pie()
          .sort(null) //.sort(this.compareSubFamilies)
          .value(function(dd) {
            return dd.value
          })
          // cuidao, hacemos "esta locura" para matchear con el familiesRing
          // .startAngle(this.sdTheta / 2 + Math.PI + Math.PI * 2)
          // .endAngle(this.sdTheta / 2 + Math.PI);
          .startAngle(d.startAngle)
          .endAngle(d.endAngle)



        let sp = subPie(d.data.notes)
        sp.forEach((n,i)=>{
          // console.log(d.startAngle)
          n.data.offset = n.startAngle + Math.PI + (n.endAngle - n.startAngle)/2//-data1[0].startAngle//+(n.endAngle-n.startAngle)/2
          while (n.data.offset>2*Math.PI) n.data.offset -= 2*Math.PI
          while (n.data.offset<0) n.data.offset += 2*Math.PI
          n.data.offset = 50*n.data.offset/(2*Math.PI)
          n.data.radius = this.props.showNotes?smallR:innerR
          n.data.family = d.data.family.id
        })

        d.subdata0 = d.subdata1 || []
        d.subdata1 = sp

        return sp
      } ,
      (dd) => dd.data.id
    )
    let subItemsEnter = subItems.enter().append('g').attr('class','sub-wedge')
    subItemsEnter.append('path')
      .attr('class','sub-wedge-path')
      .attr("d", (d)=>{
        return smallArc.outerRadius(()=>this.props.showNotes?smallR:innerR)(d)
      })
      .attr('opacity','0')



    subItemsEnter
      .each(function(d, i) {
        this._current = findNeighborArc(i, d3.select(this.parentNode).datum().subdata0, d3.select(this.parentNode).datum().subdata1, (d,i)=>i) || {
          data: {},
          startAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
          endAngle: d.startAngle + (d.endAngle - d.startAngle) / 2
        };
      })
    subItemsEnter.on('click',(d)=>{
      if(this.props.onSelectSub) this.props.onSelectSub(d.data.id,d.data.family)
    })
    let subItemsWedgesEnter = subItemsEnter.append('g')
      .attr('class','sub-family-wedge')
      .attr('opacity',0)
    subItemsWedgesEnter.append('path')
        .attr('class','sub-family-wedge-path')
    subItemsWedgesEnter.append('text')
      .attr('class','sub-family-text')
      // .attr('dx',(d)=>-d.data.name.length*3.4)
      .append('textPath')
        //href={"#text-circle-"+this.instanceId}
        // startOffset={(100-textOffset)/2+'%'}
        .attr('href',"#text-small-circle-"+this.instanceId)
        .attr('dominant-baseline','middle') //for poor FireFox...
        .attr('alignment-baseline','middle')
        .attr("text-anchor","middle")
        .attr('startOffset',(d)=>{
          // let textOffset = 100*(currentOffset+(family.notes.length-1)/2)*this.props.sdTheta/(2*Math.PI)
          return d.data.offset+'%'
        })


    subItemsEnter.append('path')
      .attr('class','sub-wedge-selection')
      .attr("d", (d)=>{
        return smallArcSelection.outerRadius(smallR)(d)
      })
      .attr('stroke','#fff')
      .attr('stroke-width',4)
      .attr('fill','none')
      .attr('opacity','0')


    subItems.exit().remove()

    subItems = subItems.merge(subItemsEnter)


    subItems.select('.sub-wedge-path')
      .transition()
      .duration(700)
      // .ease(d3.easeBack)
      // .attr("d", smallArc)
      .attrTween("d", smallArcTween)
      .attr('opacity','1')
      // .attr('fill','rgba(0,0,0,0.8')
      .attr('fill',(d)=>Utils.hexToRGB(d.data.color || '#ffffff',0.7))
    subItems.select('.sub-wedge-selection')
      .attr("d", (d)=>{
        return smallArcSelection.outerRadius(smallR)(d)
      })
      .transition()
      .attr('opacity',(d)=>{
        if(this.props.showNotes && d.data.id === this.props.subSelection && d.data.family === this.props.selection) return 1
        else return 0
      })

    subItems.each(function(d){
      if(self.props.showNotes && d.data.id === self.props.subSelection && d.data.family === self.props.selection) this.parentNode.appendChild(this);
    })


    subItems.select('.sub-family-wedge').select('path')
      .attr("d", (d) => {
        return smallFamilyArc(d)
      })
      // .attr('fill','rgba(0,0,0,0.8')
      .attr('fill',(d)=>d.data.color)


    // subItems.select('.sub-family-wedge').transition()
    //   .duration(this.props.showNotes?600:200)
    //   .delay(this.props.showNotes?600:0)
    //   .attr('opacity',this.props.showNotes?0.9:0)

    // subItems.select('.sub-family-wedge').select('.sub-family-text').select('textPath')
    //   .text((d,i)=>{
    //     let wedgeLength = (d.endAngle - d.startAngle)*this.smallR
    //     let wordLength = d.data.name.length*8
    //     let familyName = wordLength<wedgeLength? d.data.name:d.data.name.substr(0,Math.floor(wedgeLength/8)-1)+'...'
    //     return familyName
    //   })
    //   .transition()
    //   .duration(900)
    //   .attr('startOffset',(d)=>{
    //     return (d.data.offset)+'%'
    //   })




    let selection
    let wedge
    if (this.props.showNotes && this.props.subSelection){
      selection = this.props.selection || (data1[0] && data1[0].data.id)
      let fam = data1.find((d)=>d.data.family.id === selection)
      wedge = fam.subdata1.find((d)=>{return d.data.id === this.props.subSelection})
    }else{
      selection = this.props.selection || (data1[0] && data1[0].data.id)
      wedge = data1.find((d)=>{return d.data.family.id === selection})
    }
    if (wedge){
      let newAngle = - (wedge.startAngle+(wedge.endAngle-wedge.startAngle)/2) - Math.PI/2 + (this.props.anchorOffset || 0)
      if(!this.currentAngle){this.currentAngle=0}
      while(newAngle - this.currentAngle > Math.PI) {newAngle = newAngle - 2*Math.PI}
      while(newAngle - this.currentAngle < -Math.PI) {newAngle = newAngle + 2*Math.PI}
      this.currentAngle = newAngle
    }

    // if(root.node().getAttribute('transform')===null) {
    //   root.node().setAttribute('transform',"translate(" +
    //     width / 2 +
    //     "," +
    //     height / 2 +
    //     ") rotate(" +
    //     (180*this.currentAngle/Math.PI + 180) +
    //     ")")
    // }

    // root.transition().duration(800).ease(d3.easeCubicInOut).attr(
    root.attr(
      "transform",
      "translate(" +
        width / 2 +
        "," +
        height / 2 +
        ") rotate(" +
        (180*this.currentAngle/Math.PI ) +
        ")"
    );
  }


  render() {
    this.sdTheta = (2 * Math.PI) / this.props.data.reduce((sum,fam)=>{return sum+fam.value},0)
    this.measure();

    // const textRadius = this.smallR - this.familyDr/2 - 1 || 0
    // const textSmallCirclePath =
    //   'M0 '+textRadius+' A '+textRadius+' '+textRadius+' 0 0 1 0 -'+textRadius+
    //   ' A '+textRadius+' '+textRadius+' 0 0 1 0 '+textRadius+
    //   ' A '+textRadius+' '+textRadius+' 0 0 1 0 -'+textRadius+
    //   ' A '+textRadius+' '+textRadius+' 0 0 1 0 '+textRadius

    // let labelAngle = 0.2
    // let outterRadius = this.r
    // let innerRadius = this.r * 0.3;
    // let start = [outterRadius*Math.sin(-labelAngle),outterRadius*Math.cos(-labelAngle)]
    // let end = [outterRadius*Math.sin(labelAngle),outterRadius*Math.cos(labelAngle)]
    // const outterCirclePath =
    //   'M'+start[0]+' '+start[1]+' A '+outterRadius+' '+outterRadius+' 0 0 1 0 -'+outterRadius+
    //   ' A '+outterRadius+' '+outterRadius+' 0 0 1 '+ end[0]+' '+end[1]

    // start = [innerRadius*Math.sin(-labelAngle),innerRadius*Math.cos(-labelAngle)]
    // end = [innerRadius*Math.sin(labelAngle),innerRadius*Math.cos(labelAngle)]
    // const innerCirclePath =
    //   'M'+start[0]+' '+start[1]+' A '+innerRadius+' '+innerRadius+' 0 0 1 0 -'+innerRadius+
    //   ' A '+innerRadius+' '+innerRadius+' 0 0 1 '+ end[0]+' '+end[1]

    // labelAngle = 0.2
    // outterRadius = this.r + 16
    // start = [outterRadius*Math.sin(-labelAngle),outterRadius*Math.cos(-labelAngle)]
    // end = [outterRadius*Math.sin(labelAngle),outterRadius*Math.cos(labelAngle)]
    // const labelPath =
    //   'M'+start[0]+' '+start[1]+' A '+outterRadius+' '+outterRadius+' 0 0 0 '+end[0]+ ' '+end[1]


    // let centerRadius = this.r*0.35

    return (
      <div className="donut" style={{transform:'scale('+this.props.scale+')'}}>

        {/* <div className={"selection-mark"+ (this.props.anchorOffset?' mark-bottom':' mark-right')}>
            {/* <div className="arrow" /> */}
            {/* <div className="line" /> */}
            {/* <div className="number">{Utils.prettyNumber(selectionValue)+'%'}</div> */}
        {/* </div> */}

        <svg
          // style={{transition: 'transform 1s',transform: 'rotate('+angle*180/Math.PI+'deg)'}}
          ref={node => (this.node = node)}
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            {/* <path
                  id={"text-small-circle-"+this.instanceId}
                  d={textSmallCirclePath}
            />
            <path
                  id={"label-circle-"+this.instanceId}
                  d={labelPath}
            /> */}
          </defs>



          {/* {!isMobile &&
            <g className="label-circle" transform={"translate("+ this.props.width / 2+","+this.props.height / 2 + ")"}>
              <path
                d={outterCirclePath}
              />
              <text className="donut-label">
                <textPath href={"#label-circle-"+this.instanceId} alignmentBaseline="middle" textAnchor="middle" startOffset="50%">
                  {"Olfactive Families"}
                </textPath>
              </text>
            </g>
          } */}
          <g className="root">
            <circle className="outer-circle" r={this.r}></circle>
            <circle className="inner-circle" r={this.r*0.35}></circle>
            <g className="donut-wrapper" />
            {/* <path className="outer-circle"
              d={outterCirclePath}
            />
            <path className="inner-circle"
              d={innerCirclePath}
            /> */}
            {this.props.showNames &&
              <g className="ring-wrapper">
                <FamiliesRing
                  // {... this.props}
                  // defs = {this.props.defs_legacy}
                  // scents={this.props.scents}
                  // simplified = {this.props.defs}
                  sdTheta={this.sdTheta}
                  radius={this.r}
                  dr={isMobile?this.r*0.1:this.r*0.07}
                  // activeDefs={simpleDefs}
                  families = {this.props.data.slice()}
                  // families = {families}
                  familyColors={this.props.familyColors}
                  // levelScale = {this.levelScale}
                />
              </g>
            }
          </g>

        </svg>
        {/* lo quitamos de momento! */}
        {/* <div className="wheel-center" style={{width: centerRadius+'px',height: centerRadius+'px'}}></div> */}
      </div>
    );
  }
}
const mapStateToProps = (state,ownProps) => {
  return {
    data: ownProps.data || getUserFamilies(state),
  }
}
export default connect(mapStateToProps)(Donut);
// export default Donut;
