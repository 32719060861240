import React, { Component } from 'react'

import './HowItWorks.scss'
import { withRouter } from 'react-router-dom'
import t from '../../../i18n/strings'

import bg_image_top from '../../../assets/images/how-it-works/bg_image_top.png'
import bg_image_middle from '../../../assets/images/how-it-works/bg_image_middle.png'
import bg_image_bottom from '../../../assets/images/how-it-works/bg_image_bottom.png'
import how_image_1 from '../../../assets/images/how-it-works/image_01.jpg'
import how_image_2 from '../../../assets/images/how-it-works/image_02.jpg'
// import how_image_3 from '../../../assets/images/how-it-works/image_03.jpg'
// import how_image_4 from '../../../assets/images/how-it-works/image_04.jpg'

function innerHTML (key) {
  return { __html: t(key) }
}

class Intro extends Component {
  state = {}

  componentDidMount () {}
  render () {
    return (
      <div className='how-container' ref={this.container}>
        <div className='how-content'>
          <div
            className='background top'
            style={{ backgroundImage: `url(${bg_image_top})` }}
          />
          <div
            className='background middle'
            style={{ backgroundImage: `url(${bg_image_middle})` }}
          />
          <div
            className='background bottom'
            style={{ backgroundImage: `url(${bg_image_bottom})` }}
          />
          <div className="opacity-layer"></div>
          <div className='how-section purpose'>
            <div className='centered-content'>
              <div
                className='title left'
                dangerouslySetInnerHTML={innerHTML('what_is_our_purpose')}
              ></div>
              <div className='steps right'>
                <div
                  className='step-title'
                  dangerouslySetInnerHTML={innerHTML('make_the_choosing')}
                ></div>
              </div>
            </div>
          </div>
          <div className='how-section purpose'>
            <div className='centered-content'>
              <div
                className='title left'
                dangerouslySetInnerHTML={innerHTML('how_dows_it_work')}
              ></div>
              <div className='steps right'>
                <div
                  className='step-title'
                  dangerouslySetInnerHTML={innerHTML(
                    'your_profile_is_composed'
                  )}
                ></div>
              </div>
            </div>
          </div>
          <div className='how-section image-and-text align-right'>
            <div className='centered-content'>
              <div className='left'>
                <div
                  className='title'
                  dangerouslySetInnerHTML={innerHTML('about_the_families')}
                ></div>
                <div
                  className='text'
                  dangerouslySetInnerHTML={innerHTML('each_perfume_belongs')}
                ></div>
              </div>
              <div className='right'>
                <div
                  className='image'
                  style={{ backgroundImage: `url(${how_image_1})` }}
                ></div>
              </div>
            </div>
          </div>
          <div className='how-section image-and-text align-left'>
            <div className='centered-content'>
              <div className='left'>
                <div
                  className='image large'
                  style={{ backgroundImage: `url(${how_image_2})` }}
                ></div>
              </div>
              <div className='right'>
                <div
                  className='title'
                  dangerouslySetInnerHTML={innerHTML('about_the_ingredients')}
                ></div>
                <div
                  className='text'
                  dangerouslySetInnerHTML={innerHTML(
                    'the_quadrificatoin_shows'
                  )}
                ></div>
              </div>
            </div>
          </div>
          {/* <div className='how-section image-and-text align-right overflow'>
            <div className='centered-content'>
              <div className='left'>
                <div
                  className='text'
                  dangerouslySetInnerHTML={innerHTML(
                    'are_ther_more_ingredients'
                  )}
                ></div>
              </div>
              <div className='right'>
                <div
                  className='image large'
                  style={{ backgroundImage: `url(${how_image_3})` }}
                ></div>
              </div>
            </div>
          </div> */}
          <div className='how-section quads'>
            <div className='centered-content'>
              <div className='quad title-quad'>
                <div
                  className='title'
                  dangerouslySetInnerHTML={innerHTML('expert_diagnostic')}
                ></div>
              </div>
              <div className='quad text-quad'>
                <div
                  className='title'
                  dangerouslySetInnerHTML={innerHTML(
                    'the_recomendations_are_100'
                  )}
                ></div>
                <div
                  className='content'
                  dangerouslySetInnerHTML={innerHTML('scents_are_recomended')}
                ></div>
              </div>
              <div className='quad text-quad'>
                <div
                  className='title'
                  dangerouslySetInnerHTML={innerHTML(
                    'hundredpercent_perfumistic_point_of_view'
                  )}
                ></div>
                <div
                  className='content'
                  dangerouslySetInnerHTML={innerHTML('perfumes_are_the_heart')}
                ></div>
              </div>
              {/* <div className="quad text-quad">
                <div className="title" dangerouslySetInnerHTML={innerHTML("partnerships")}></div>
                <div className="content" dangerouslySetInnerHTML={innerHTML("we_have_collaborated")}></div>
              </div> */}
            </div>
          </div>
          {/* <div className='how-section qa'>
            <div className='centered-content'>
              <div className="title" dangerouslySetInnerHTML={innerHTML("q_and_a")}></div>
              <div className="row">
                <div className="row-title" dangerouslySetInnerHTML={innerHTML("what_about_the_ideas")}></div>
                <div className="row-content" dangerouslySetInnerHTML={innerHTML("yes_as_much_as_language")}></div>
              </div>
              <div className="row">
                <div className="row-title" dangerouslySetInnerHTML={innerHTML("is_the_visualization")}></div>
                <div className="row-content" dangerouslySetInnerHTML={innerHTML("as_much_as_it_can_be")}></div>
              </div>
              <div className="row">
                <div className="row-title" dangerouslySetInnerHTML={innerHTML("do_users_have_a_say")}></div>
                <div className="row-content" dangerouslySetInnerHTML={innerHTML("we_gather_user_ratings")}></div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}

export default withRouter(Intro)
