import React, { Component } from 'react'
import './PerfumeRow.scss'
import * as Utils from '../../utils'
import {addPerfume, removePerfume} from '../../store/modules/profile'
// import { isMobile } from 'react-device-detect'
import Rater from './Rater'
import NocibeButton from './NocibeButton'
import ConnectedWithRating from './ConnectedWithRating'
import ButtonWithFeedback from './ButtonWithFeedback'
import t from '../../i18n/strings'
// import TextTruncate from 'react-text-truncate';
import Truncate from 'react-truncate';
import { eventTracker } from '../../services/tracker'
// import FamiliesWithIcon from './FamiliesWithIcon'

class PerfumeRow extends Component {
  state = {
    removedState: ''
  }

  handleClick = e => {
    if(e && (e.target.closest('.button-with-feedback') || e.target.closest('.rater'))) return
    if (this.props.showCheckbox){
      this.setState(prevState => ({checked: !prevState.checked }))
      setTimeout(()=>{
        this.props.onSelect(this.props.data, this.props.data.similarity)
      },500)
    } else if (this.props.highlightable) {
      if (!this.state.highlighted) {
        // this.setState({ highlighted: true })
        this.props.onHighlight(this.props.data, this.ref.offsetLeft)
      }
    } else if (this.props.onSelect) {
      if (this.props.selectionState === 'selected') {
        this.props.onSelect(null)
      } else {
        this.props.onSelect(this.props.data, this.props.data.similarity)
      }
    }
  }

  handleDetailsClick = e => {
    this.props.onViewDetails(this.props.data)
  }
  handleSelectCompare = e => {
    this.props.onSelect(this.props.data, this.props.data.similarity,{action: 'compare'})
    e.stopPropagation()
  }
  handleSelectReplace = e => {
    this.props.onSelect(this.props.data, this.props.data.similarity,{action: 'replace'})
    e.stopPropagation()
  }

  handleUnhighlight = e => {
    this.props.onHighlight(null, this.ref.offsetLeft)
    e.stopPropagation()
  }

  handleRemove = e => {
    // e.stopPropagation()
    if (this.props.onRemove) {
      this.props.onRemove(this.props.data)
      // this.setState({ removedState: 'removed' })
    }
    if(this.props.removeFrom==='wishes'){
      this.props.onToggleWished()
    }
  }
  onRated = (value) => {
    this.props.onRated(this.props.data,value)
    this.setState({tempRating: value})
  }
  buyItNow = () => {
    eventTracker.emitEvent('ACTION', {
      type: 'BUY', data: { perfumeId: this.props.data.id }
    })
    return window.open(Utils.addTrackingInfo(this.props.data.productUrl))
  }
  onAddToProfile = () => {
    this.props.dispatch(addPerfume({id: this.props.data.id, perfume: this.props.data, value: 5}));
  }
  onRemoveFromProfile = () => {
    this.props.dispatch(removePerfume({id: this.props.data.id, perfume: this.props.data, value: 5}));
  }
  render () {
    // Usar el this.props.mode, para
    // o bien, añadir una classe y hacerlo todo por css
    // o bien, tener 2 returns, que devuelvan un DOM distinto (evitar si se puede)

    //IMPORTANT; BRING BACK!!!
    // let favorited = this.props.user.favoritePerfumes.includes(this.props.data.id)
    let favorited = false;
    return (
      <div
        // style={style}
        className={
          'perfume-row prevent-hammer' +
          ' ' +
          this.props.selectionState +
          ' ' +
          this.props.mode +
          ' ' +
          this.state.removedState +
          (this.props.highlighted ? ' highlighted' : '')
        }
        ref={ref => (this.ref = ref)}
      >
        <div className="row-wrapper" onClick={this.handleClick}>
          <div className="row-top">
            <div className='main-info-wrapper'>
              {this.props.showCheckbox &&
                // <input className={"check-box "+(this.state.checked?'checked':'')} type="checkbox"></input>
                <div className={"check-box "+(this.state.checked?'checked':'')}/>
              }
              <div
                className='image'
                style={{backgroundImage: this.props.data.imageUrl? 'url('+Utils.getPerfumeImageUrl(this.props.data.imageUrl)+')': ''}}
              >
                {favorited && <div className='favorite-icon'></div>}
                {this.props.showSimilarity && (
                  <div className='similarity'>
                    <div className='value'>
                      {Math.floor(this.props.data.similarity * 100)}
                    </div>
                    <div className='unit'>%</div>
                  </div>
                )}
              </div>
              <div className='information'>
                <div className='perfume-name' key={this.props.data.name}>
                  {/* {this.props.data.name} */}
                  {/* <TextTruncate
                    line={2}
                    element="span"
                    truncateText="…"
                    text={this.props.data.name}
                    // textTruncateChild={<a href="#">Read on</a>}
                  /> */}
                  <Truncate lines={2} ellipsis="…">
                    {this.props.data.name.toUpperCase()}
                  </Truncate>
                </div>
                <div className='brand'>by {this.props.data.brand.name + (this.props.data.productLine?' - '+this.props.data.productLine:'')}</div>
                {/* <FamiliesWithIcon family={this.props.data.family} subfamily={this.props.data.secondaryFamily}/> */}
              </div>
            </div>
            {this.props.showRater && (
              <Rater value={this.state.tempRating || this.props.currentRating} onRated={this.onRated}></Rater>
              )}
            {this.props.showRemove && (
              <ButtonWithFeedback className='remove-btn' onClick={this.handleRemove}></ButtonWithFeedback>
            )}
            {this.props.showAddToFavorites && !this.props.currentRating && (
              <NocibeButton label={t('add_to_profile')} onClick={this.onAddToProfile}></NocibeButton>
            )}
            {this.props.showAddToFavorites && this.props.currentRating && (
              // <div className="alreade-fav">Already in your profile</div>
              <NocibeButton label={t('remove_from_profile')} mode="empty" onClick={this.onRemoveFromProfile}></NocibeButton>
            )}
            {this.props.showViewDetails && (
              <NocibeButton mode='text' label={t('view_details')} onClick={this.handleDetailsClick}></NocibeButton>
            )}
            {this.props.showSelectButton &&
              <NocibeButton label={t('select')} onClick={()=>this.handleClick(null)}></NocibeButton>
            }
            {this.props.showDownload &&
              <NocibeButton label={'Process perfume'} onClick={()=>this.handleClick(null)}></NocibeButton>
            }
            {this.props.alreadyDownload &&
              <NocibeButton enabled={false} label={'Processing!'} onClick={()=>this.handleClick(null)}></NocibeButton>
            }
          </div>
          <div className="row-bottom">
            {this.props.showPriceAndBuy &&
              <div className="price-and-buy">
                <div className="price"><div className="from">{t('from')}</div><div className="euros">{Utils.formatPrice(this.props.data)}</div></div>
                {this.props.data.productUrl && <NocibeButton className="buy-it-button" onClick={this.buyItNow}>{t('buy_it_now')}</NocibeButton>}
              </div>
            }
          </div>
        </div>
        {/* {isMobile && (
          <div className='actions'>
            <div className='action view'  onClick={this.handleSelectReplace}>VIEW PERFUME</div>
            {this.props.comparable && (
              <div className='action compare' onClick={this.handleSelectCompare}>COMPARE</div>
            )}
          </div>
        )}
        {isMobile && this.props.highlightable && this.props.highlighted && (
          <div className='close' onClick={this.handleUnhighlight}>&times;</div>
        )}
        {this.props.showDetailsButton && (
          <div className='details-button' onClick={this.handleDetailsClick}>View Details</div>
        )} */}
      </div>
    )
  }
}


export default ConnectedWithRating(PerfumeRow)
