import React, {Component} from 'react';
import './ButtonWithFeedback.scss'

export default class ButtonWithFeedback extends Component{
  state = {
    clicked: false
  }
  onClick = () => {
    this.setState({down: true})
    setTimeout(()=>{
      this.setState({down: false})
      this.props.onClick()
    },100)
  }
  onDown = () => {
    this.setState({down: true})
  }
  onUp = () => {
    this.setState({down: false})
  }
  render () {
    return (
      <div className={(this.props.className || '') + ' button-with-feedback'+(this.state.down?' down':'')}
        onClick={this.onClick}
        onMouseDown={this.onDown}
        onTouchStart={this.onDown}
        onMouseLeave={this.onUp}
        onMouseUp={this.onUp}
        onTouchEnd={this.onUp}
      >
        {this.props.children}
      </div>
  )}

}