import React, { Component } from 'react'
import './WishListPanel.scss'
import { connect } from 'react-redux'
import {withRouter} from 'react-router'
import qs from 'qs'
import {removeWish} from '../../store/modules/profile'
// import PerfumeCard from './PerfumeCard'
import PerfumeRow from './PerfumeRow'
// import NocibeButton from './NocibeButton'
import OverlayedView from './popups/OverlayedView'
import PerfumeFullView from './PerfumeFullView'
import t from '../../i18n/strings'

class WishListPanel extends Component {
  state={
    currentPerfume:null
  }
  onClose = () => {
    let query = qs.parse(this.props.location.search.slice(1))
    delete query.wishlist
    this.props.history.push({search:qs.stringify(query)})
  }
  onRemove = (perfume) => {
    this.props.dispatch(removeWish({id: perfume.id}))
  }
  onSelect = (perfume)=>{
    this.setState({currentPerfume:perfume})
  }
  onPerfumeClose = ()=>{
    this.setState({currentPerfume:null})
  }
  render () {
    let perfumes = this.props.profile.wishes.map((p)=>{
      return <PerfumeRow
        data={p.perfume}
        showRemove={true}
        showPriceAndBuy={false}
        key={p.id}
        removeFrom='wishes'
        onSelect={this.onSelect}
        >

        </PerfumeRow>
    })
    return (
      <div className="wish-list-panel">
        <div className="title">{t('your_wish_list')} ({perfumes.length})</div>
        <div className="close-button" onClick={this.onClose}></div>
        <div className="wish-list">
          {perfumes}
          { (!perfumes.length) &&
            <div className="wish-list-empty-text">{t('your_wish_list_is_empty')}</div>
          }
        </div>
        {/* <NocibeButton mode="text" className="add-button" onClick={()=>this.props.history.push('/wizard?step=1')}>+ {t("add_fragrance")}</NocibeButton> */}
        <OverlayedView in={this.state.currentPerfume !== null} onBackgroundClick={this.onPerfumeClose} style={{left:0, top:0}}>
          <PerfumeFullView data={this.state.currentPerfume} onClose={this.onPerfumeClose}></PerfumeFullView>
        </OverlayedView>
      </div>
    )
  }

}
const mapStateToProps = state => {
  return {
    profile: state.profile
  }
}

export default withRouter(connect(mapStateToProps)(WishListPanel))