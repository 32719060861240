let  SquarePacking = ()=>{
  var width = 1
  var height = 1

  let computeLayouts = () => {
    let tiles = [
      [6,6],
      [6,4], [4,6],
      [6,3], [3,6],
      // [6,2], [2,6],
      [4,4],
      [4,3], [3,4],
      [4,2], [2,4],
      [3,3],
      [3,2], [2,3],
      [2,2],
      [2,1], [1,2],
      [1,1]
    ]

    let layouts = []

    let initialLayout = {
      tiles: [],
      corners: [
        {
          x:0,
          y:0,
          width: 6,
          height: 6
        }
      ]
    }
    let bestCorner = (corners,tile) => {
      return corners.find((c) => {
        return c.width>=tile[0] && c.height>=tile[1]
      })
    }
    // let bestTile = (corner, maxTile) => {
    //   let i = tiles.length-1
    //   let t = tiles[i]
    //   let best = null
    //   while(i>=maxTile){
    //     i--
    //     if(t[0]<=corner.width && t[1]<=corner.height){
    //       best = t
    //     }
    //     t = tiles[i]
    //   }
    //   return best
    // }
    let addTile = (prevLayout, tileIndex) => {
      var layout = JSON.parse(JSON.stringify(prevLayout))
      var c = bestCorner(layout.corners,tiles[tileIndex])
      if (!c) {
        // layouts.push(layout)
        return null
      }
      // var t = bestTile(c,maxTile)
      // if (!t) {
      //   // layouts.push(layout)
      //   return null
      // }
      let t = tiles[tileIndex]
      layout.tiles.push({x: c.x, y: c.y, width: t[0], height: t[1]})


      if (c.width-t[0]>0) layout.corners.push( {x: c.x+t[0], y: c.y, width: c.width-t[0], height: t[1]})
      if (c.height-t[1]>0) layout.corners.push( {x: c.x, y: c.y+t[1], width: t[0], height: c.height-t[1]})

      layout.corners = layout.corners.slice(0)
      layout.corners.splice(layout.corners.indexOf(c),1)


      // EXTENDEMOS LAS CORNERS, si cabe
      layout.corners.sort((a,b)=>{
        return a.x-b.x
      })
      layout.corners.forEach((c,i)=>{
        let next = layout.corners[i+1] || {x:6, y:0, width:0, height: 0}
        if (next){
          c.width = next.x - c.x
        }
      })


      layout.corners.sort((a,b)=>{
        return a.y-b.y
      })
      layout.corners.forEach((c,i)=>{
        let next = layout.corners[i+1] || {x:0, y:6, width:0, height: 0}
        if (next){
          c.height = next.y - c.y
        }
      })

      if (layout.corners.length === 0) {
        // layouts.push(layout)
        // Layout is full
        return layout
      }
      if(layout.tiles.length>10){
        // Layout not filled
        return null
      }
      for(var i=tileIndex; i<tiles.length; i++){
        let correctLayout = addTile(layout,i)
        if (correctLayout) layouts.push(correctLayout)
      }
    }

    // let correctLayout = addTile(initialLayout, 0)
    // if (correctLayout) layouts.push(correctLayout)
    tiles.forEach((t,i)=>{
      // console.log('layouts', layouts)
      let correctLayout = addTile(initialLayout,i)
      if (correctLayout) layouts.push(correctLayout)
    })

    layouts.forEach((l)=>{
      l.tiles.sort((a,b)=>{
        return b.width*b.height-a.width*a.height
      })
    })

    layouts.forEach((l)=>{
      l.squares = l.tiles.reduce((count,t)=>{
        if(t.width === t.height) return count+1
        else return count
      },0)
    })

    layouts.forEach((l)=>{
      // l.squareness = l.tiles.reduce((value,t)=>{
      //   return value+Math.min(t.width/t.height,t.height/t.width)*Math.pow((t.width*t.height),2)
      // },0)
      let t = l.tiles[0]
      l.squareness = Math.min(t.width/t.height,t.height/t.width)*t.width*t.height
    })

    return layouts
  }

  if (!window.allLayouts){
    window.allLayouts = computeLayouts()
  }

  let compute = (data)=>{

    let layouts = window.allLayouts

    let total = data.reduce((sum,d)=>{return sum+_value(d)},0)
    data.forEach((d)=> d.proportion = 36*_value(d)/total )
    data.sort((a,b)=>b.proportion-a.proportion)
    layouts = layouts.filter((l)=>l.tiles.length === data.length)

    layouts.forEach((l) => {
      l.dist = l.tiles.reduce((dist,tile,i)=>{
        return dist + Math.pow(tile.width*tile.height-data[i].proportion,2)
      },0)
    })



    // Aqui esta el quid de la question, quien manda, y como, el squareness o el dist!?!?!?
    layouts.sort((a,b)=>{
      return a.dist - b.dist
    })

    layouts = layouts.filter((l)=>l.dist <= Math.max(15, layouts[0].dist))

    let trifecta = (layout) => {
      let dict={}
      layout.tiles.forEach((t, i)=>{
        [[t.x,t.y],[t.x+t.width,t.y],[t.x,t.y+t.height],[t.x+t.width,t.y+t.height]].forEach((c)=>{
          if(c[0] === 0 || c[0] === 6 || c[1] === 0 || c[1] === 6) return
          let coords = c[0]+'_'+c[1]
          if(!dict[coords]) dict[coords]=0
          dict[coords]++
        })
      })
      let count = Object.keys(dict).map((key)=>dict[key]).filter((c)=>c===2).length
      return count
    }
    let violations = (layout) => {
      let badness = 0
      layout.tiles.forEach((t, i)=>{
        if(data[i].orientation){
          if(data[i].orientation === 'portrait' && t.width/t.height > 1) badness+=data[i].importance
          if(data[i].orientation === 'landscape' && t.width/t.height < 1) badness+=data[i].importance
          if(data[i].orientation === 'square' && t.width/t.height !== 1) badness+=data[i].importance
        }
      })
      return badness
    }
    let niceness = (layout) => {
      return layout.squareness*3 + layout.dist - violations(layout) + trifecta(layout)*5
    }

    // only for testing...
    layouts.forEach((l)=>l.niceness = niceness(l))

    layouts.sort((a,b)=>{
      return niceness(b) - niceness(a)
    })

    layouts = layouts.filter((l)=>{
      return niceness(l) >= niceness(layouts[0])*1
    })

    // let packData = layouts[0].tiles
    let packData
    if (layouts.length>0){
      // packData = layouts[Math.floor(Math.random()*layouts.length)].tiles
      packData = layouts[0].tiles
    } else {
      packData = []
    }


    // pfff... !?!?
    packData = JSON.parse(JSON.stringify(packData))



    // BASURILLA no acaba de estar bien...
    let size = Math.min(width, height)
    let dx = 0 // (width-size)/2
    let dy = 0 // (height-size)/2
    packData.forEach((d,i) => {
      d.data = data[i]
      d.id = data[i].id
      d.cx = 0
      d.cy = 0
      d.scale = 1
      d.x0 = dx + size*d.x/6
      d.y0 = dy + size*d.y/6
      d.x1 = dx + d.x0 + size*d.width/6
      d.y1 = dy + d.y0 + size*d.height/6
    })
    return packData
  }

  let value = (d)=>d.value
  let _value = (d)=>isNaN(Number(value(d))) ? 0 : Number(value(d))

  compute.size = (sizes)=>{
    width = sizes[0]
    height = sizes[1]
    return compute
  }
  compute.value = (valFunction)=>{
    value = valFunction
    return compute
  }
  return compute
}
export default SquarePacking