import React, { Component } from 'react'
import { connect } from 'react-redux'
import './Recomendations.scss'
// import Perfumes from '../../../assets/data/perfumes.json'
import PerfumeCard from '../../common/PerfumeCard'
// import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
// import PerfumeRatingView from '../../common/PerfumeRatingView'
// import Api from '../../../services/Api'
import {getRecomendations, shouldUpdateRecosSelector, updateLastSentRecos} from '../../../store/modules/profile'
import NocibeButton from '../../common/NocibeButton'
// import OverlayedView from '../../common/popups/OverlayedView'
import PerfumeFullView from '../../common/PerfumeFullView'
import {Link, withRouter} from 'react-router-dom';
// import * as Utils from '../../../utils'
// import PriceRanges from '../../../assets/data/priceRanges.json'
import t from '../../../i18n/strings'
import PerfumesFilter from '../../common/PerfumesFilter'
import PerfumeAndPanel from '../../common/PerfumeAndPanel'
import StoreInvitationPopup from '../../common/popups/StoreInvitationPopup'
// import Fab from '../../common/Fab'
// import Tooltip from '../../common/Tooltip'
import discount from '../../../assets/discount.png'
import { eventTracker } from '../../../services/tracker'

class Recomendations extends Component {

  state = {
    tops: [],
    rest: [],
    filtered: null,
    currentPerfume: null,
    evaluating: false,
    simplified: true,
    openPopUp:false,
    print:false,
    loading:false,
  }
  discountRef = React.createRef()
  componentRef = React.createRef()
  componentDidMount() {
    const recos = this.props.profile.recomendations
    // ASUMIMOS QUE SE ACTUALIZA RECOMENDATIONS SIEMPRE QUE TOCA!
    if(!recos) this.props.dispatch(getRecomendations())
    else if (this.props.shouldUpdateRecos) {
      this.props.dispatch(updateLastSentRecos(recos))
    }
    // let headers = document.getElementsByClassName('app-header')
    // if(headers)
    //   Array.from(headers).forEach(header => header.className += ' noprint')
  }
  // componentWillUnmount(){
  //   let headers = document.getElementsByClassName('app-header')
  //   if(headers){
  //     Array.from(headers).forEach(header =>{
  //       let classes = header.className.split(' ')
  //       header.className = classes.slice(classes.indexOf(' noprint'),1).join(' ')
  //     })
  //   }

  // }

  showDetails = (perfume) => {
    this.setState({currentPerfume: this.props.profile.recomendations.find((r)=>r.id===perfume.id)})
  }
  onPerfumeClose = () => {
    this.setState({currentPerfume: null, evaluating:false})
  }
  onEvaluatePerfume = () => {
    this.setState({evaluating:true})
  }

  refilter = (results) =>{
    this.setState({filtered: results})
    this.$root.scrollTop = 0
  }

  onPrint = ()=>{
    this.setState({openPopUp:false,simplified:false},()=>{
      /**
       * WE CAN USE THIS CODE FOR CREATE A GENERIC PRINT
       */
      /*let root = document.getElementById('root')
      const classes = root.className
      root.className += ' noprint'
      let body = document.getElementsByTagName('body')[0]
      body.insertAdjacentHTML('beforeend',`<div id='print-object' class='print section recomendations'>${this.componentRef.current.outerHTML}</div>`)
      let newDiv = document.getElementById('print-object')
      this.setState({loading:true})*/
      setTimeout(() => {
        eventTracker.emitEvent('ACTION', { type: 'PRINT', data: { recos: (this.state.filtered || this.props.profile.recomendations).map(p => p.id) } })
        window.print()
      },1000)
    })
  }
  onOpenClosePopup = ()=>{
    this.setState({openPopUp: !this.state.openPopUp})
  }
  render () {

    // let famRecos = this.props.profile.recomendations.filter((r)=>r.type==="family")//.sort((a,b)=>a.similarity-b.similarity)
    // let ingRecos = this.props.profile.recomendations.filter((r)=>r.type==="ingredient")//.sort((a,b)=>a.similarity-b.similarity)
    // let sortedRecos = famRecos.slice(0,4).concat(ingRecos.slice(0,2)).concat(famRecos.slice(4,8)).concat(ingRecos.slice(2,4))
    let sortedRecos = this.props.profile.recomendations
    let isSimplified = this.state.simplified
    let isFetchingReco = this.props.isFetchingReco
    if(sortedRecos.length>0 && sortedRecos.length<5) isSimplified = false
    let filtered = this.state.filtered || sortedRecos
    let tops = filtered.slice(0,4)
    let rest = filtered.slice(4,12)
    let newRecs = this.props.profile.recomendations.filter((r)=>this.props.profile.oldRecomendations.find((rr)=>rr.id === r.id)===undefined)
    return <div ref={(ref)=>this.$root=ref} className="section recomendations">

      <div className="fix-for-sticky-and-scroll">
        {isSimplified &&
          <div className="recs-header noprint">
            <div className="title">{t('top_6_recomendations')}</div>
            <div className="description">{t('recomendations_according')}</div>
            <NocibeButton className={"test-button"} mode="light" onClick={()=>this.setState({openPopUp:!this.state.openPopUp})}>Envie de tester les parfums</NocibeButton>
          </div>
        }
        {!isSimplified &&
          <div className="recs-header more-recos noprint">
            <div className="title">{t('all_recomendations')}</div>
            <div className="description">{t('recomendations_according')}</div>
            <div className="filter-bar ">
              <PerfumesFilter data={sortedRecos} filterIds={['family','price','season']} ref={(d)=>this.filters=d} onChange={this.refilter}></PerfumesFilter>
              <NocibeButton className={"test-button"} mode="light" onClick={()=>this.setState({openPopUp:!this.state.openPopUp})}>Envie de tester les parfums</NocibeButton>
            </div>
          </div>
        }
        <div className="print-container" ref={this.componentRef}>
        <div className='print-header'>
          <h1>Mes recommandations</h1>
        </div>
        <div  className={"top " + (!isSimplified ? 'has-rest' : '')}>
          {/* <div className="title">{t('top_6_recomendations')}</div>
          <div className="description">{t('recomendations_according')}</div> */}
          <div className="card-list top-list">
            <div className="cards-wrapper">
                {isFetchingReco ? <div className="loading"><div className="text">{t('loading')}</div></div>
             :tops.map((p)=>{
              // return <div className="perfume-card">{p.name}</div>
              return <PerfumeCard
                data={p}
                key={p.id}
                isNew={newRecs.indexOf(p) >= 0}
                onViewDetails={this.showDetails}
                showShowDetails={true}
                showMiniDonut={false}
              />
            })}
            </div>
          </div>
        </div>

        {!isSimplified &&
          <>
            {/* <div className="showing-all">{t('all_recomendations')}</div> */}
            <div className="rest">
              {/* <div className="filter-bar">
                <div className="title">{t('all_recomendations')}</div>
                <PerfumesFilter data={rest} ref={(d)=>this.filters=d} onChange={this.refilter}></PerfumesFilter>
                <div className="description">{t('recomendations_according')}</div>
              </div> */}
              <div className="card-list rest-list">
                {rest.map((p)=>{
                  return <PerfumeCard
                    data={p}
                    key={p.id}
                    isNew={newRecs.indexOf(p) >= 0}
                    onViewDetails={this.showDetails}
                    showShowDetails={true}
                    showMiniDonut={false}
                  />
                })}
              </div>
              <hr className="separator" />
              <div ref={this.discountRef} className="discount">
                <div className="flex">
                  <div className="description">
                    <p>Venez poursuivre l'expérience en magasin et découvrir avec votre conseillère experte votre parfum ideal.</p>
                    <p>Faites le bon choix parmi vos recommandations et profitez d'une remise sur votre fragrance!</p>
                  </div>
                  <div className='code'>
                    <img alt="25% discount - code: 96925" className='img' src={discount}></img>
                  </div>
                </div>
                <div className="legal">
                  <p>*Remise de 25% effectuée directement en caisse sur un des parfums
                  recommandés à la suite de votre diagnostic. Offre valable une seule
                  fois dans les magasins participants, pour tout diagnostic réalisé
                  jusqu’au 31/12/2023, valable sur présentation
                  d’une carte de fidélité en cours de validité (ou pour toute nouvelle
                  souscription) et valable dans un délai de 15 jours à compter de la
                  réalisation de votre diagnostic. Hors DOM TOM et Monaco. Non valable sur les produits "Petits prix",
                  "Prix Web", "Prix Nocibé", "Points Rouges", "Produits Outlet", "Produits partenaires",
                  "Coffrets Institut", "Cartes cadeau", "Cartes Institut", "prestations institut" et "emballages payants".
                  Non cumulable et non substituable avec toutes offres promotionnelles, soldes en cours et les chèques fidélité Nocibé.</p>
                </div>
              </div>
            </div>
          </>
        }
        </div>
        <div className="after-list">
          {isSimplified &&
            <NocibeButton className="show-all" secondary={true} onClick={()=>this.setState({simplified: false})}>{t('view_more_recs')}</NocibeButton>
          }
          <div className="description">{t('if_you_didnt_find')}</div>
          {/* <Tooltip defaultOpen={true} className={'discount'} component={<div>
            <div  onClick={()=>this.setState({openPopUp:!this.state.openPopUp})}  className="tooltip-container">
              <p className="title"><strong>Envie de tester les parfums?</strong></p>
              <p className="subtitle">Cliquez ici pour en savoir plus</p>
            </div>
          </div>}>
            <Fab icon='store' label="Open Popup" onClick={()=>this.setState({openPopUp:!this.state.openPopUp})}></Fab>
          </Tooltip> */}

          <StoreInvitationPopup onClose={this.onOpenClosePopup} open={this.state.openPopUp} tops={tops.slice(0,3)} onDownLoad={this.onPrint}/>
          <Link className="link-button" to={'/profile?state=showYourFragrances'}>
            <NocibeButton label={t('edit_my_profile')}></NocibeButton>
          </Link>
        </div>
        {/* {this.state.currentPerfume &&
          <div className="perfume-view-wrapper">

          </div>
        } */}
        {/* <OverlayedView in={this.state.currentPerfume !== null} onBackgroundClick={this.onPerfumeClose}> */}

          {/* {!this.state.evaluating?
            <PerfumeFullView data={this.state.currentPerfume} className="popup" onClose={this.onPerfumeClose} showEvaluate={true} onEvaluatePerfume={this.onEvaluatePerfume}></PerfumeFullView>
          :
            <PerfumeRatingView data={this.state.currentPerfume} onClose={this.onPerfumeClose}></PerfumeRatingView>
          } */}
          <PerfumeAndPanel in={this.state.currentPerfume !== null} data={this.state.currentPerfume} onBackgroundClick={this.onPerfumeClose}>
            {/* ya no se usa el evaluating no?!*/}
            {/* {!this.state.evaluating?
              <PerfumeFullView data={this.state.currentPerfume} onClose={this.onPerfumeClose} showEvaluate={false} onEvaluatePerfume={this.onEvaluatePerfume}></PerfumeFullView>
            :
              <PerfumeRatingView data={this.state.currentPerfume} onClose={this.onPerfumeClose}></PerfumeRatingView>
            } */}
            <PerfumeFullView data={this.state.currentPerfume} onClose={this.onPerfumeClose} showEvaluate={false}></PerfumeFullView>
          </PerfumeAndPanel>
        {/* </OverlayedView> */}
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    shouldUpdateRecos: shouldUpdateRecosSelector(state),
    isFetchingReco: state.profile.isFetching
  }
}
export default connect(mapStateToProps)(withRouter(Recomendations));