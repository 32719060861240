import React, { Component } from 'react'
import SearchBy from './SearchBy'
import NocibeButton from '../../common/NocibeButton'
import './FindBy.scss'
import SearchPerfumeResults from './SearchPerfumeResults'
import PerfumeFullView from '../../common/PerfumeFullView'
import PerfumeAndPanel from '../../common/PerfumeAndPanel'
import t from '../../../i18n/strings'
import { isMobile } from 'react-device-detect'
import { eventTracker } from '../../../services/tracker'
class SearchSelection extends Component {
  state = {
    hover: {}
  }
  render () {
    let items = this.props.data.map(d => {
      return (
        <div
          className={
            'brand-item ' + (this.state.hover[d.id] ? 'brand-item-hover' : '')
          }
          key={d.id}
        >
          <div className='name'>{d.name}</div>
          <div
            className='close-item'
            onClick={() => this.props.onRemove(d)}
            onMouseEnter={() =>
              this.setState(state => ({
                hover: { ...state.hover, [d.id]: true }
              }))
            }
            onMouseLeave={state =>
              this.setState({ hover: { ...state.hover, [d.id]: false } })
            }
          />
        </div>
      )
    })
    return <div className='selection-list'>{items}</div>
  }
}

export default class FindByName extends Component {
  state = {
    selection: [],
    query: null,
    collapsed: false
  }
  componentDidMount(){
    eventTracker.emitEvent('VIEW_OPENED', { type: 'EXPLORE_BY_PERFUMES',data:{} })
  }
  handleSelection = selection => {
    selection = selection.filter(item => {
      return this.state.selection.map(b => b.id).indexOf(item.id) < 0
    })
    this.setState(state => ({ selection: [...state.selection, ...selection] }))
  }
  handleRemove = perfume => {
    this.setState(state => {
      const selection = state.selection.filter(perfume_selected => {
        return perfume_selected.id !== perfume.id
      })
      return {
        selection: selection,
        collapsed: selection.length === 0 ? false : state.collapsed
      }
    })
  }
  onPerfumeSelect = perfume => {
    this.setState({ currentPerfume: perfume })
  }
  onPerfumeClose = perfume => {
    this.setState({ currentPerfume: null })
  }
  getPerfumes = () => {
    this.setState({
      query: this.state.selection.map(b => b.id).join(','),
      collapsed: true
    })
    this.props.onSetQuery("true")
  }
  expand = () => {
    if (this.state.collapsed) this.setState({ collapsed: false })
  }
  UNSAFE_componentWillReceiveProps = (newProps) =>{
    if(this.state.query && !newProps.currentQuery) this.setState({query: null})
  }
  render () {
    return (
      <div className='sub-section by-name'>
        <div className={'search-cols '+(isMobile && this.state.query?'mobile-hidden':'')}>
          {isMobile && <div className="back-col">
            <div className="back-button" onClick={()=>{this.expand(); this.setState({query:null})}}>{t('back_to_brands')}</div>
          </div>}
          <div
            className={'main-col ' + ((this.state.query && this.state.collapsed) ? 'collapsed' : '')}
            onClick={this.expand}
          >
            <div className='cut-content'>
              {/* quitando el selection hace como "single selection" :) */}
              {/* <SearchBy endpoint="/brands" onSelect={this.handleSelection} selection={this.state.selection}></SearchBy> */}
              <SearchBy endpoint='/brands' onSelect={this.handleSelection} />
            </div>
          </div>
          <div
            className={
              'second-col ' +
              (this.state.selection.length > 0 ? 'visible' : 'hidden')
            }
          >
            <div className='cut-content'>
              <SearchSelection
                data={this.state.selection}
                onRemove={this.handleRemove}
              />
              <div className='go-button-wrapper'>
                <NocibeButton
                  label={t('find_perfumes')}
                  onClick={this.getPerfumes}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <SearchPerfumeResults query={this.state.query && `/perfumes/?searchTerm=${this.state.query}`} onPerfumeSelect={this.onPerfumeSelect}></SearchPerfumeResults> */}
        <SearchPerfumeResults
          query={
            this.state.query &&
            `/perfumes/by_brand?brand_id=${this.state.query}`
          }
          onPerfumeSelect={this.onPerfumeSelect}
        />

        <PerfumeAndPanel
          in={this.state.currentPerfume != null}
          data={this.state.currentPerfume}
          onBackgroundClick={this.onPerfumeClose}
        >
          <PerfumeFullView
            data={this.state.currentPerfume}
            onClose={this.onPerfumeClose}
          />
        </PerfumeAndPanel>
      </div>
    )
  }
}
