import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUserFamilies } from '../../../store/selectors/getUserFamilies'
import {getUserIngredients} from '../../../store/selectors/getUserIngredients'
import t from '../../../i18n/strings'
import './RecoDesc.scss'
import * as Utils from '../../../utils'
class RecoDesc extends Component {
  
  render () {
    function innerHTML (key) {
      return { __html: key }
    }
    function firstLetterCase (text) {
      // return text.replace(/^\w/, c => c.toUpperCase())
      return text.charAt(0).toUpperCase() + text.slice(1)
    }
    let descIntro,isExpert,descHero,descFamilies,descIng
    let descs = []
    
    if(this.props.isRecomended) descIntro = t('reco_desc_title')
    else descIntro = t('possible_reco_title')
    
    let famsAndSubs = Utils.getProfileFamiliesAndSubs(this.props.profile)
    let commonFams = famsAndSubs.filter((f)=>{
      return (this.props.data.family && f.family.id === this.props.data.family.id) || (this.props.secondaryFamily && f.family.id === this.props.secondaryFamily.id)
    })
    
    if(commonFams.length>0) {
      descFamilies = `${t('it')} `+ t('recommended_by_fam').replace('%FAMILY%',Utils.concatWithAnd(commonFams.map((f)=>f.family.name)))
    } else {
      descFamilies = t('not_recommended_by_fam')
    }
    
    
    let hero
    let profileHeroes = Utils.getHeroIngredients(this.props.profile)
    // let profileMains = Utils.getProfileMainIngredients(this.props.profile)
    let matchingHeroes = this.props.data.ingredientProportions.slice(0,5).filter((ip)=>profileHeroes.find((ph)=>ph.id===ip.ingredient.id)).map((ip)=>ip.ingredient)
    // ONLY TALK ABOUT HEROES if the ingredient is hero in the PROFILE!
    // if(this.props.data.heroIngredient && profileMains.findIndex((ph)=>ph.id===this.props.data.heroIngredient.id)>=0){
    //   hero = this.props.data.heroIngredient
    // } else if(matchingHeroes.length>0){
    //   hero = matchingHeroes[0]
    // }
    if(this.props.data.type==='hero' || (matchingHeroes.length>0 && commonFams.length>0)){
      hero = matchingHeroes[0]
    }
    
    if(hero) {
      descHero = t('recommended_by_hero').replace('%HERO%',hero.name)
      descs.push(descHero)
    }
    
    
    let commonIngs = this.props.data.ingredientProportions.filter((p)=>this.props.userIngredients.find((i)=>i.id === p.ingredient.id))
    if(hero) commonIngs = commonIngs.filter((i)=>i.ingredient.id !== hero.id)
    if(commonIngs.length > 0) {
      // descIng = t('recommended_by_ings').replace('%INGS%',Utils.concatWithAnd(commonIngs.map((i)=>i.ingredient.name),t('and')))
      descIng = t('recommended_by_ings').replace('%INGS%','')
      descs.push(descIng)
    }
    
    if(this.props.data.type==='expert') isExpert = true
    
    let fullDesc

    if(hero) {
      // if(commonIngs.length>0) fullDesc = descHero +`, ${t('and')} ${t('it')} `+descIng
      if(commonIngs.length>0) fullDesc = descHero +`. <br/> ${t('furthermore')}, `+descIng
      else fullDesc = descHero +`.`
    } else if(this.props.data.type==='family') {
      if(descIng) fullDesc = descFamilies+`, ${t('and')} `+descIng
      else fullDesc = descFamilies
    } else if(this.props.data.type==='ingredient' || this.props.data.type==='expert' || this.props.data.type==='goodGuy'  || this.props.data.type==='hero'){
      // if (commonFams.length>0) {
      //   if(descIng) fullDesc =  `${t('it')} ` + descIng //+`, ${t('and')} ${t('it')} `+descFamilies
      //   else fullDesc =  `${t('it')} `+descFamilies
      // } else {
      //   if(descIng) fullDesc = `${t('it')} `+ descIng //+`.<br/><br/>${descFamilies}`
      //   else fullDesc = descFamilies
      // }
      if (commonFams.length>0){
        fullDesc =  `${descFamilies}, ${t('and')} `+ descIng + `.`
      } else {
        fullDesc = `${descFamilies} `+ descIng + `.`
      }
    }
    fullDesc = firstLetterCase(fullDesc || '')
    return (
      <div className='reco-description'>
        {isExpert && <div className="expert-tag"><div className="expert-icon"/>{t('expert_reco')}</div>}
        <div className='intro' dangerouslySetInnerHTML={innerHTML(descIntro+'...')}></div>
        <div className='text' dangerouslySetInnerHTML={innerHTML(fullDesc)}></div>

      </div>
    )
  }
  
}
const mapStateToProps = state => {
  return {
    profile: state.profile,
    userFamilies: getUserFamilies(state),
    language: state.language.lang,
    userIngredients: getUserIngredients(state)
  }
}
export default connect(mapStateToProps)(RecoDesc)