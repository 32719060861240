import React, {Component} from 'react';
import './OverlayedView.scss'
import { CSSTransition } from 'react-transition-group';


class OverlayedView extends Component{
  state = {
    children: null
  }
  // MUY LOCO ESTO!!!! :D
  static getDerivedStateFromProps(props,state){
    if(props.in) state.children = props.children
    return state
  }
  render () {
    return <CSSTransition
      in={this.props.in}
      timeout={500}
      classNames="overlayed-view"
      unmountOnExit
    >
      <div className="overlayed-view" style={this.props.style}>
        <div className="background" onClick={this.props.onBackgroundClick}></div>
        {this.state.children}
      </div>
    </CSSTransition>
  }

}
export default OverlayedView