import React, {Component} from 'react';
import './FamiliesWithIcon.scss'

export default class FamiliesWithIcon extends Component {
  constructor (props) {
    super (props);
    this.state = {query: ''};
  }

  getLabel() {
    const { family, subfamily } = this.props
    if(family.name){
      if(subfamily)
        return family.name + ' / ' + subfamily.name
      return family.name
    }
    return ''
  }

  render() {
    const { family, subfamily } = this.props
    return (
      <div className="family-and-icon">
        <div className="icon">
          {subfamily && <div className="outer" style={{background: family.color}} />}
          {subfamily && <div className="inner" style={{ background: subfamily ? subfamily.color : family.color }} />}
          {!subfamily && <div className="inner" style={{ background: family.color }} />}
        </div>
        <div className="label">{this.getLabel()}</div>
      </div>
    );
  }
}
