import React, {Component} from 'react';
import './ResetPopup.scss'
import './Popup.scss'
import {withRouter} from 'react-router'
import NocibeButton from '../NocibeButton';
import qs from 'qs'
import t from '../../../i18n/strings';
import { connect } from 'react-redux'
import {getUserData} from '../../../store/modules/user'
import { eventTracker } from '../../../services/tracker';
import { EVENT_NAME_ENUM } from '@puig/event-tracker/eventTypes';
class ResetPopup extends Component{
  state = {
  }
  onClose = () => {
    let query = qs.parse(this.props.location.search.slice(1))
    delete query.reset
    this.props.history.push({search:qs.stringify(query)})
  }
  doTheReset = () => {
    this.onClose()
    eventTracker.emitEvent(EVENT_NAME_ENUM.NEW_SESSION)
    this.props.dispatch({type: 'RESET_ALL'})
    this.props.dispatch(getUserData())
    this.props.history.replace('/wizard')
  }
  render () {
    return <div className="reset-popup popup">
      <div className="title">{t('are_you_sure_you_want_to_create')}</div>
      <div className="buttons">
        <NocibeButton onClick={this.onClose} mode="empty">{t('cancel')}</NocibeButton>
        <NocibeButton onClick={this.doTheReset} mode="">{t('yes_create_new')}</NocibeButton>
      </div>
      <div className="close-button" onClick={this.onClose}></div>
    </div>
  }

}
export default connect()(withRouter(ResetPopup))