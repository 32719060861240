
import { combineReducers } from 'redux'
import profile from './profile'
import user from './user'
import language from './language'
import session from './session'

let appReducer = combineReducers({profile,user,language,session})

export default (state,action)=>{
  if (action.type === 'RESET_ALL') {
    state = undefined
    //MUY GUARRERO ESTO, pero funciona, ya revisaremos...
    action = {type: 'user/RESET_USER'}
    // localStorage.removeItem('nocibeId')
    localStorage.clear()
  }

  return appReducer(state, action)
}