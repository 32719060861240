import React, { Component } from 'react'
import './PerfumeAndPanel.scss'
import IngItem from './IngItem'
import { CSSTransition } from 'react-transition-group'
import { connect } from "react-redux"
import {getUserIngredients} from '../../store/selectors/getUserIngredients'
import t from '../../i18n/strings'
import ConnectedWithRating from './ConnectedWithRating'
import { isMobile } from 'react-device-detect'
import DonutColumn from '../sections/profile/DonutColumn'
import RecoDesc from '../sections/recomendations/RecoDesc'
import * as Utils from '../../utils'
// import ConnectedWithRating from './ConnectedWithRating'
// import * as Utils from '../../utils'
// import NocibeButton from './NocibeButton'
// import Donut from '../sections/profile/Donut'

class PerfumeAndPanel extends Component {

  state = {
    children: null,
    section: null,
    superSection: 'card'
  }
  // MUY LOCO ESTO!!!! :D
  static getDerivedStateFromProps(props,state){
    if(props.in) {
      state.data = props.data
      state.children = props.children
    }
    return state
  }
  onModeChange = (mode) => {
    this.setState({section: mode.section, superSection:mode.superSection})
  }
  render () {
    let topIngs = [],
        otherIngs = [],
        ingsImages = [],
        otherIngsImages = []
        // textIngs = []
    if(this.state.data){
      this.state.data.ingredientProportions.sort((a,b)=>b.proportion-a.proportion)
      // ingredients = this.props.userIngredients.filter((ui)=>this.state.data.ingredientProportions.findIndex((i)=>(i.ingredientId || i.ingredient.id)===ui.id)>=0)
      // let ingsWithImage = ingredients.filter((ui)=>this.state.data.ingredientProportions.slice(0,5).findIndex((i)=>(i.ingredientId || i.ingredient.id)===ui.id)>=0)
      // let otherIngs = ingredients.filter((ui)=>this.state.data.ingredientProportions.slice(0,5).findIndex((i)=>(i.ingredientId || i.ingredient.id)===ui.id)<0)
      // ingsImages = ingsWithImage.concat(otherIngs).map((i)=>{
      //   return <IngItem data={i} key={i.id}></IngItem>
      // })
      // textIngs = otherIngs.map((i)=>{
      //   return <div className="ing-text" key={i.id}>{i.name}</div>
      //   // return <IngItem data={i} key={i.id}></IngItem>
      // })
      // top ings
      let userMains = Utils.getProfileMainIngredients(this.props.profile)
      topIngs = userMains.filter((ui)=>this.state.data.ingredientProportions.slice(0,5).findIndex((i)=>(i.ingredientId || i.ingredient.id)===ui.id)>=0)
      otherIngs = userMains.filter((ui)=>this.state.data.ingredientProportions.slice(5).findIndex((i)=>(i.ingredientId || i.ingredient.id)===ui.id)>=0)
      topIngs.sort((a,b)=>this.state.data.ingredientProportions.findIndex((prp)=>prp.ingredient.id===a.id)-this.state.data.ingredientProportions.findIndex((prp)=>prp.ingredient.id===b.id))
      otherIngs.sort((a,b)=>this.state.data.ingredientProportions.findIndex((prp)=>prp.ingredient.id===a.id)-this.state.data.ingredientProportions.findIndex((prp)=>prp.ingredient.id===b.id))
      
      if(topIngs.length===0) {
        topIngs = otherIngs
        otherIngs = []
      }
      
      ingsImages = topIngs.map((i)=>{
        return <IngItem data={i} key={i.id}></IngItem>
      })
      otherIngsImages = otherIngs.map((i)=>{
        return <IngItem data={i} key={i.id}></IngItem>
      })
      
    }

    let panelContent
    let bottomComponent
    let textComponent
    // let noPanel
    // if(isMobile && this.state.data && this.state.data.type!==undefined){
    //   textComponent = <RecoDesc shortVersion={isMobile} data={this.state.data} isRecomended={this.state.data.type!==undefined}></RecoDesc>
    // } else if (this.state.section === 'ingredients' && (this.state.data.type === 'hero' || this.state.data.type === 'ingredient' || this.state.data.type === 'expert')) {
    //   textComponent = <RecoDesc shortVersion={isMobile} data={this.state.data} isRecomended={this.state.data.type!==undefined}></RecoDesc>
    // } else if (this.state.section === 'families' && this.state.data.type === 'family'){
    //   textComponent = <RecoDesc shortVersion={isMobile} data={this.state.data} isRecomended={this.state.data.type!==undefined}></RecoDesc>
    // } else {
    //   textComponent = null
    // } 
    //NEW
    if(this.state.data && this.state.data.type!==undefined && this.state.section === 'ingredients'){
      textComponent = <RecoDesc shortVersion={isMobile} data={this.state.data} isRecomended={this.state.data.type!==undefined}></RecoDesc>
    } else {
      textComponent = null
    }
    
    
    if(isMobile){
      if (this.state.panelExpanded) {
        
        let donutComponent = <DonutColumn radiusPercent={1} descType={'reco'} reco={this.state.data} showTitle={true} showDesc={false}></DonutColumn>
        
        let ingsComponent = <>
          {/* {<div className="title">{t('your_favourite_ingredients_present')}:</div>} */}
          <div className="ingredients-list">
            {ingsImages}
          </div>
          {otherIngs.length > 0 &&
            <>
              <div className="subtitle">{t('also_less_dominant')}:</div>
              <div className="ingredients-list">
                {otherIngsImages}
              </div>
            </>
          }
          
            
          {/* {!isMobile && textIngs.length>0 &&
            <>
              <div className="subtitle">{t("less_dominant")}</div>
              <div className="ingredients-text-list">
                {textIngs}
              </div>
            </>
          } */}
        </>
        if (this.state.data.type === 'family') {
          bottomComponent = <>{ingsComponent} {donutComponent}</>
        } else {
          bottomComponent = <>{ingsComponent} {donutComponent}</>
        }
        panelContent = <>
          <div className="panel-header-wrapper">
            <div className="close-button" onClick={() => this.setState({ panelExpanded: false })}></div>
            {textComponent}
          </div>
          {bottomComponent}
        </>
      }
      // } else if(this.state.data && this.state.data.type!==undefined) {
      //   panelContent = <div className="panel-teaser" onClick={()=>this.setState({panelExpanded: true})}>{t("why_is_this_recommendation")}</div>
      // } else {
      //   noPanel = true;
      // }
    } else {
      if(this.state.section === 'ingredients' && ingsImages.length>0) {
        bottomComponent = <>
          {/* {textComponent===null && <div className="title">{t('your_favourite_ingredients_present')}:</div>} */}
          <div className="ingredients-list">
            {ingsImages}
          </div>
          {otherIngs.length>0 && 
            <>
              <div className="subtitle">{t('also_less_dominant')}:</div>
              <div className="ingredients-list">
                {otherIngsImages}
              </div>
            </>
          }
          {/* {!isMobile && textIngs.length>0 &&
            <>
              <div className="subtitle">{t("less_dominant")}</div>
              <div className="ingredients-text-list">
                {textIngs}
              </div>
            </>
          } */}
        </>
      } else {
        // panelContent = <DonutColumn radiusPercent={isMobile?.3:1} descType={'reco'} reco={this.state.data}></DonutColumn>
        // panelContent = <RecoDesc data = {this.state.data}></RecoDesc>
        bottomComponent = <DonutColumn radiusPercent={isMobile?.3:1} descType={'reco'} reco={this.state.data} showTitle={textComponent===null} showDesc={false}></DonutColumn>
      }
      panelContent = <>
        {textComponent} {bottomComponent}
      </>
    }
    const childrenWithProps = React.Children.map(this.state.children, child =>
      React.cloneElement(child, { onModeChange: this.onModeChange })
    );
    return (
      <CSSTransition
        in={this.props.in}
        timeout={1000}
        classNames="perfume-and-panel"
        unmountOnExit
      >
        <div className="perfume-and-panel">
          {isMobile ? (
            <>{childrenWithProps} {panelContent}</>
          ) : (
              <>
                <div className="overlay" onClick={this.props.onBackgroundClick}></div>
                <div className="left-perfume-wrapper">
                  {childrenWithProps}
                </div>
                {/* <div className={"right-panel-wrapper visible"+((this.state.panelExpanded)?' expanded':'')+((noPanel)?' no-panel':'')}>
                  <div className="panel">
                    {panelContent}
                  </div>
                </div> */}
              </>
          )}
        </div>
      </CSSTransition>

    )
  }
}
const mapStateToProps = state => {
  return {
    userIngredients: getUserIngredients(state)
  }
}
export default connect(mapStateToProps)(ConnectedWithRating(PerfumeAndPanel))