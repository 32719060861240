import React, { Component } from 'react'
import {Route, Link} from 'react-router-dom';
import './Explorer.scss'
import FindByIngredients from './FindByIngredients';
import FindByName from './FindByName';
import FindByConcepts from './FindByConcepts';
// import * as Utils from '../../../utils'
// import {getUserFamilies} from '../../../store/selectors/getUserFamilies'
import { connect } from "react-redux"
// import Donut from '../profile/Donut'
import DonutColumn from '../profile/DonutColumn';
import t from '../../../i18n/strings';
import { isMobile } from 'react-device-detect';
import qs from 'qs'

function parseHTML (string) {
  return { __html: string }
}
class ExplorerIntro extends Component {
  render (){
    let optionsData = [
      {
        id:'by-name',
        label: 'by_name',
        path: 'by-name'
      },
      {
        id:'by-ingredients',
        label: 'by_ingredients',
        path: 'by-ingredients'
      },
      {
        id:'by-concepts',
        label: 'by_concepts',
        path: 'by-concepts'
      }
    ]
    let options = optionsData.map(o => {
      return (
        <Link to={"/explorer/"+o.path} className={'option'} key={o.id}>
          <div className='label' dangerouslySetInnerHTML={parseHTML(t(o.label))} />
        </Link>
      )
    })
    return <div className="explorer-intro">
      <div className="background"></div>
      {/* <div className="title">{t("welcome_to_explorer")}</div> */}
      <div className="options">
        {options}
      </div>
    </div>
  }
}
class Explorer extends Component {

  setQuery = (query)=>{
    if(query) this.props.history.push({search: '?query='+query})
    else this.props.history.push({search: null})
  }
  render () {
    // let families = this.props.userFamilies
    let currentQuery=qs.parse(this.props.location.search.slice(1)).query
    return <div className="section explorer">
      <div className="main-wrapper">
        {this.props.location.pathname !== '/explorer' &&
        <div className="sub-header">
          {isMobile && <div className="by-text">{t("by_")}</div>}
          <Link to="/explorer/by-name" className={"nav-button" + (this.props.location.pathname.indexOf('/by-name')>=0 ? ' selected': '')}>
            {isMobile?t('_name'):t('by_name')}
          </Link>
          <Link to="/explorer/by-ingredients" className={"nav-button" + (this.props.location.pathname.indexOf('/by-ingredient')>=0 ? ' selected': '')}>
            {isMobile?t('_ingredients'):t('by_ingredients')}
          </Link>
          <Link to="/explorer/by-concepts" className={"nav-button" + (this.props.location.pathname.indexOf('/by-concepts')>=0 ? ' selected': '')}>
            {isMobile?t('_concepts'):t('by_concepts')}
          </Link>
        </div>
        }
        <div className="sub-content">
          <Route exact strict path={"/explorer"} render={()=><ExplorerIntro></ExplorerIntro>}></Route>
          <Route path={"/explorer/by-name"} render={() => <FindByName currentQuery={currentQuery} onSetQuery={this.setQuery} />} />
          <Route path={`/explorer/by-ingredients`} render={() => <FindByIngredients currentQuery={currentQuery} onSetQuery={this.setQuery} />} />
          <Route path={`/explorer/by-concepts`} render={() => <FindByConcepts currentQuery={currentQuery} onSetQuery={this.setQuery} />} />
        </div>
      </div>
      {!isMobile && this.props.location.pathname !== '/explorer' &&
        <div className="mini-profile-wrapper">
          <DonutColumn showDesc={true}></DonutColumn>
        </div>
      }
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    // userFamilies: getUserFamilies(state),
  }
}


export default connect(mapStateToProps)(Explorer)