import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUserFamilies } from '../../../store/selectors/getUserFamilies'
import t from '../../../i18n/strings'
import './ProfileDesc.scss'
import * as Utils from '../../../utils'
function innerHTML (key) {
  return { __html: key }
}
function firstLetterCase (text) {
  return text.replace(/^\w/, c => c.toUpperCase())
}
class ProfileDesc extends Component {
  // render () {
  //   let main = this.props.userFamilies.slice(0, 2)
  //   let sub = this.props.userFamilies.slice(2, 3)
  //   let descMain, descSub
  //   if (this.props.language === 'fr') {
  //     descMain =
  //       t('you_love') +
  //       ' ' +
  //       (main.length > 1 ? 'des ' + t('families') : 'de la ' + t('family')) +
  //       ' ' +
  //       main.map(f => f.family.name).join(' et ')
  //     descSub =
  //       t('but_also') +
  //       ' ' +
  //       (sub.length > 1 ? 'des ' + t('families') : 'de la ' + t('family')) +
  //       ' ' +
  //       sub.map(f => f.family.name).join(' et ')
  //   } else {
  //     descMain =
  //       t('you_love') +
  //       main.map(f => f.family.name).join(' and ') +
  //       ' ' +
  //       (main.length > 1 ? t('families') : t('family'))
  //     descSub =
  //       t('but_also') +
  //       sub.map(f => f.family.name).join(' and ') +
  //       ' ' +
  //       (sub.length > 1 ? t('families') : t('family'))
  //   }
  //   return (
  //     <div className='profile-description'>
  //       <div className='main'>{descMain}</div>
  //       {sub.length > 0 && <div className='more'>{descSub}</div>}
  //     </div>
  //   )
  // }

  get likedPerfumes(){
    return this.props.profile.perfumes.filter((p)=>p.value>=3)
  }
  get intensities(){
    return Object.values(this.likedPerfumes.reduce((dict,p)=>{
      let int
      if(p.perfume.family.intensity<4) int = 2
      else if (p.perfume.family.intensity<6) int = 5
      else int = 8
      if(!dict[int]) dict[int] = {intensity: int, count: 0}
      dict[int].count ++
      return dict
    },{})).sort((a,b)=>b.count-a.count)
    
  }
  get intensity(){
    if(this.intensities.length>0 && (this.intensities.length === 1 || this.intensities[0].count>this.intensities[1].count)){
      return this.intensities[0].intensity
    }
    return null
  }
  get perfumers(){
    return Object.values(this.props.profile.perfumes.filter((p)=>p.value>3).reduce((perfumers,p)=>{
      if(!p.perfume.perfumer) return perfumers
      perfumers[p.perfume.perfumer] = perfumers[p.perfume.perfumer] || {name: p.perfume.perfumer,perfumes: []}
      perfumers[p.perfume.perfumer].perfumes.push(p.perfume)
      return perfumers
    },{})).filter((b)=>b.perfumes.length>1).sort((a,b)=>a.perfumes.length-b.perfumes.length)
  } 
  render () {
    if(!this.props.userFamilies || this.props.userFamilies.length === 0 ) return null
    
    // let main = this.props.userFamilies.slice(0, 2)
    // let sub = this.props.userFamilies.slice(2, 3)
    let famCount = this.props.userFamilies.length
    let likedPerfumesCount = this.likedPerfumes.length
    
    //let intensity = this.props.userFamilies.reduce((int, f) => { return int + f.family.intensity },0)/this.props.userFamilies.length    
    let subs = Utils.getProfileSubs(this.props.profile)
    let subCount = subs.length
    
    let dominantIngredients = Utils.getDominantIngredients(this.props.profile)
    let allIngredients = Utils.getProfileMainIngredients(this.props.profile)
    dominantIngredients.sort((a,b)=>{
      return allIngredients.findIndex((i)=>i.id===a.id) - allIngredients.findIndex((i)=>i.id===b.id)
    })
    dominantIngredients = dominantIngredients.slice(0,4)
    let heroIngredients = Utils.getHeroIngredients(this.props.profile)
    
    // let brands = this.props.profile.perfumes.filter((p)=>p.value>=3).reduce((brands,p)=>{
    //   let perf = p.perfume
    //   if(!brands[perf.brand.id]) brands[perf.brand.id] = {brand:perf.brand,perfumes:[perf]}
    //   else brands[perf.brand.id].perfumes.push(perf)
    //   return brands
    // },{})
    // brands = Object.values(brands).filter((b)=>b.perfumes.length>1).sort((a,b)=>a.perfumes.length-b.perfumes.length)
    let repeatedPerfumer = this.perfumers[0] 
    
    let descFamilies, descIntensity, descNuances, descIngredients, descPerfumer//descFamsExtra
    
    if(famCount>=3) descFamilies = t('you_love_many')
    else descFamilies = t('you_love_few')
    
    // if(famCount>1) descFamsExtra = t('you_love_fams_plural').replace('%FAMILY%',Utils.concatWithAnd(fams.map((f)=>'<b>'+f.family.name.toUpperCase()+'</b>'),t('and')))
    // else if(subCount>0) descFamsExtra = t('you_love_fams_singular').replace('%FAMILY%','<b>'+fams[0].family.name.toUpperCase()+'</b>')
    // if(famCount===1) descFamsExtra = t('you_love_fams_singular').replace('%FAMILY%','<b>'+subs[0].family.name.toUpperCase()+'</b>')
    // else descFamsExtra = null
    if(this.intensity){
      if(this.intensity < 4 ) descIntensity = t('mostly_fresh')
      else if (this.intensity < 6) descIntensity = t('mostly_balanced')
      else descIntensity = t('mostly_intense')
    }
    
    if (famCount<3) {
      if(subCount<3 && subs[0] && subs[0].value>1) {
        descNuances = t('furthermore_i_detect_single').replace('%FAMILY%','<b>'+subs[0].family.name.toUpperCase()+'</b>')
      } else if(subCount>=3) {
        descNuances = t('furthermore_i_detect_broad').replace('%FAMILY%',Utils.concatWithAnd(subs.map((f)=>'<b>'+f.family.name.toUpperCase()+'</b>'),t('and')))
      }
    }
    
    if (dominantIngredients.length>0 && likedPerfumesCount>1) {
      if(dominantIngredients.length === 1) descIngredients = t('ingredeints_are_favourite_singular').replace('%INGS%','<b>'+dominantIngredients[0].name+'</b>')
      else descIngredients = t('ingredeints_are_favourite_plural').replace('%INGS%',Utils.concatWithAnd(dominantIngredients.map((i)=>'<b>'+i.name+'</b>'),t('and')))
    } else if(heroIngredients.length>0) {
      if(heroIngredients.length === 1) descIngredients = t('ingredeints_are_favourite_singular').replace('%INGS%','<b>'+heroIngredients[0].name+'</b>')
      else descIngredients = t('ingredeints_are_favourite_plural').replace('%INGS%',Utils.concatWithAnd(heroIngredients.map((i)=>'<b>'+i.name+'</b>'),t('and')))
    
    }
    
    
    if (repeatedPerfumer) descPerfumer = t('repeated_perfumer').replace('%PERFUMER%','<b>'+repeatedPerfumer.name+'</b>').replace('%COUNT%','<b>'+repeatedPerfumer.perfumes.length+'</b>').replace('%PERFUMES%',Utils.concatWithAnd(repeatedPerfumer.perfumes.map((p)=>p.name),t('and')))
    
    return (
      <div className='profile-description'>
        {descFamilies && <div className='main'>{firstLetterCase(descFamilies)}</div>}
        {!this.props.shortVersion &&
          <>
            {/* {!descIntensity && !descNuances && descFamsExtra && <div className='more' dangerouslySetInnerHTML={innerHTML(firstLetterCase(descFamsExtra))}></div>} */}
            {descIntensity && <div className='more' dangerouslySetInnerHTML={innerHTML(firstLetterCase(descIntensity))}></div>}
            {descNuances && <div className='more' dangerouslySetInnerHTML={innerHTML(firstLetterCase(descNuances))}></div>}
            {descIngredients && <div className='more' dangerouslySetInnerHTML={innerHTML(firstLetterCase(descIngredients))}></div>}
            {descPerfumer && <div className='more' dangerouslySetInnerHTML={innerHTML(firstLetterCase(descPerfumer))}></div>}
          </>
        }
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    profile: state.profile,
    userFamilies: getUserFamilies(state),
    language: state.language.lang
  }
}
export default connect(mapStateToProps)(ProfileDesc)
