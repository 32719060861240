import API from '../../services/Api'
import { eventTracker } from '../../services/tracker'

export const ADD_PERFUME = 'profile/ADD_PERFUME'
export const RESET = 'profile/RESET'
export const REMOVE_PERFUME = 'profile/REMOVE_PERFUME'
export const ADD_CONCEPTS = 'profile/ADD_CONCEPTS'
export const REMOVE_CONCEPTS = 'profile/REMOVE_CONCEPTS'
export const SET_RECOMENATIONS = 'profile/SET_RECOMENATIONS'
export const ADD_WISH = 'profile/ADD_WISH'
export const REMOVE_WISH = 'profile/REMOVE_WISH'
export const REPLACE_DATA = 'profile/REPLACE_DATA'
export const MARK_RECOS_VIEWED = 'profile/MARK_RECOS_VIEWED'
export const SET_RECOS_NOT_NEW = 'profile/SET_RECOS_NOT_NEW'
export const UPDATE_LAST_SENT_RECOS = 'profile/UPDATE_LAST_SENT_RECOS'
export const SET_FETCHING = 'SET_FETCHING'
const initialState = {
  wishes: [],
  perfumes: [],
  concepts: [],
  recomendations: [],
  lastSentRecommendations: [],
  oldRecomendations: [],
  isFetching: false
}

let recosEqual = (array1, array2) => {
  return (
    array1.length === array2.length &&
    array1.every(function (value1) {
      return array2.findIndex(value2 => value1.id === value2.id) >= 0
    })
  )
}
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FETCHING:
      console.log(SET_FETCHING, action)
      return { ...state, isFetching: action.isFetching }
    case RESET:
      return initialState
    case ADD_PERFUME:
      delete action.data.from
      let candidate = state.perfumes.find(sp => sp.id === action.data.id)
      let newPerfumes
      if (candidate) {
        candidate.value = action.data.value
        newPerfumes = state.perfumes.slice()
      } else {
        newPerfumes = state.perfumes.concat([action.data])
      }
      return {
        ...state,
        perfumes: newPerfumes
      }
    case REMOVE_PERFUME:
      return {
        ...state,
        perfumes: state.perfumes.filter(p => {
          return p.id !== action.data.id
        })
      }
    case UPDATE_LAST_SENT_RECOS:
      return {
        ...state,
        lastSentRecommendations: action.data
      }
    case ADD_CONCEPTS:
      return {
        ...state,
        concepts: [...state.concepts, ...action.data]
      }
    case REMOVE_CONCEPTS:
      let newConcepts = state.concepts.filter(
        p => action.data.map(c => c.id).indexOf(p.id) < 0
      )
      return {
        ...state,
        concepts: newConcepts
      }
    case SET_RECOMENATIONS:
      return {
        ...state,
        oldRecomendations:
          state.oldRecomendations.length === 0
            ? action.data
            : state.recomendations,
        recomendations: action.data,
        recosViewed:
          state.oldRecomendations.length === 0 ||
          recosEqual(action.data, state.recomendations)
      }
    case SET_RECOS_NOT_NEW:
      return {
        ...state,
        oldRecomendations: state.recomendations
      }
    case MARK_RECOS_VIEWED:
      return {
        ...state,
        recosViewed: true
      }
    case ADD_WISH:
      let wishCandidate = state.wishes.find(sp => sp.id === action.data.id)
      let newWishes
      if (wishCandidate) {
        newWishes = state.wishes.slice()
      } else {
        newWishes = state.wishes.concat([action.data])
      }
      return {
        ...state,
        wishes: newWishes
      }
    case REMOVE_WISH:
      return {
        ...state,
        wishes: state.wishes.filter(p => {
          return p.id !== action.data.id
        })
      }
    case REPLACE_DATA:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}

let arrayify = arr => {
  return Array.isArray(arr) ? arr : [arr]
}

export const getRecomendations = options => {
  return (dispatch, getState) => {
    //let gender = getState().session.gender
    const state = getState()
    dispatch({ type: SET_FETCHING, isFetching: true })
    return API.getRecomendations()
      .then(
        response => {
          if (!response.noFeched)
            eventTracker.emitEvent('RECOMMENDATION_FETCH', {
              resultingProfile: getProfileFromState(state),
              topResults: response.data.map(perfume => perfume.id)
            })

          dispatch({
            type: SET_RECOMENATIONS,
            data: response.data
          })
          if (options && options.forceNotNew) {
            dispatch({
              type: SET_RECOS_NOT_NEW
            })
          }
        },
        error => console.log('should handle error', error)
      )
      .finally(() => dispatch({ type: SET_FETCHING, isFetching: false }))
  }
}

export const addWish = perfume => addWishes([perfume])
export const addWishes = perfumes => {
  perfumes = arrayify(perfumes)
  return (dispatch, getState) => {
    perfumes.forEach(perfume => {
      eventTracker.emitEvent('ACTION', {
        type: 'ADD_TO_FAVORITES',
        data: {
          perfumeId: perfume.id,
          wishlist: [...getState().profile.wishes.map(p => p.id), perfume.id]
        }
      })
      dispatch({
        type: ADD_WISH,
        data: perfume
      })
    })
    return API.addWishes(perfumes)
    // .then(
    //   response => dispatch(getRecomendations()),
    //   error => {
    //     console.log('should handle ADD wish error')
    //     dispatch(getRecomendations())
    //   }
    // )
  }
}
export const removeWish = perfume => removeWishes([perfume])
export const removeWishes = perfumes => {
  perfumes = arrayify(perfumes)
  return (dispatch, getState) => {
    perfumes.forEach(perfume => {
      eventTracker.emitEvent('ACTION', {
        type: 'REMOVE_FROM_FAVORITES',
        data: {
          perfumeId: perfume.id,
          wishlist: getState()
            .profile.wishes.map(p => p.id)
            .filter(id => !perfumes.map(p => p.id).includes(id))
        }
      })
      dispatch({
        type: REMOVE_WISH,
        data: perfume
      })
    })
    return API.removeWishes(perfumes)
    // .then(
    //   response => dispatch(getRecomendations()),
    //   error => {
    //     console.log('should handle REMOVE wish error')
    //     dispatch(getRecomendations())
    //   }
    // )
  }
}

// DEBERIAN PODER HANDLEAR MULTIPLES ADDS a la vez, y no disparar el "getREcomendations" mas que una...
export const addPerfume = perfume => addPerfumes([perfume])

export const getProfileFromState = state => {
  return {
    perfumes: state.profile.perfumes.filter(p => p.value > 2).map(p => p.id),
    concepts: state.profile.concepts.map(c => c.id),
    forWhom: state.session['for-who'] === 'self' ? 'ME' : 'THEM'
  }
}

const handleEditProfileEvent = (perfume, state) => {
  const profile = state.profile
  const candidate = profile.perfumes.find(p => p.id === perfume.id)
  const resultingProfile = getProfileFromState(state)
  let eventName
  if (candidate) {
    if (candidate.value <= 2 && perfume.value > 2) {
      eventName = 'ADD_TO_PROFILE'
      resultingProfile.perfumes = [...resultingProfile.perfumes, perfume.id]
    }
    if (candidate.value > 2 && perfume.value <= 2) {
      eventName = 'REMOVE_FROM_PROFILE'
      resultingProfile.perfumes = resultingProfile.perfumes.filter(
        id => id !== perfume.id
      )
    }
  } else {
    if (perfume.value > 2) {
      eventName = 'ADD_TO_PROFILE'
      resultingProfile.perfumes = [...resultingProfile.perfumes, perfume.id]
    }
  }
  eventTracker.emitEvent(eventName, {
    id: perfume.id,
    type: 'PERFUME',
    resultingProfile
  })
}

export const addPerfumes = perfumes => {
  perfumes = arrayify(perfumes)
  return (dispatch, getState) => {
    perfumes.forEach(perfume => {
      handleEditProfileEvent(perfume, getState())
      dispatch({
        type: ADD_PERFUME,
        data: perfume
      })
    })
    return API.addPerfumes(perfumes).then(
      response => dispatch(getRecomendations()),
      error => {
        console.log('should handle ADD error')
        dispatch(getRecomendations())
      }
    )
  }
}
export const removePerfume = perfume => removePerfumes([perfume])
export const removePerfumes = perfumes => {
  perfumes = arrayify(perfumes)
  return (dispatch, getState) => {
    perfumes.forEach(perfume => {
      const currentProfile = getProfileFromState(getState())
      eventTracker.emitEvent('REMOVE_FROM_PROFILE', {
        id: perfume.id,
        type: 'PERFUME',
        resultingProfile: {
          ...currentProfile,
          perfumes: currentProfile.perfumes.filter(ppid => ppid !== perfume.id)
        }
      })
      dispatch({
        type: REMOVE_PERFUME,
        data: perfume
      })
    })
    return API.removePerfumes(perfumes).then(
      response => dispatch(getRecomendations()),
      error => {
        console.log('should handle REMOVE error')
        dispatch(getRecomendations())
      }
    )
  }
}

export const addConcept = concept => addConcepts([concept])
export const addConcepts = concepts => {
  concepts = arrayify(concepts)
  return (dispatch, getState) => {
    concepts.forEach(concept => {
      const currentProfile = getProfileFromState(getState())
      eventTracker.emitEvent('ADD_TO_PROFILE', {
        id: concept.id,
        type: 'CONCEPT',
        resultingProfile: {
          ...currentProfile,
          concepts: [...currentProfile.concepts, ...concepts.map(c => c.id)]
        }
      })
    })
    dispatch({
      type: ADD_CONCEPTS,
      data: concepts
    })
    return API.addConcepts(concepts).then(
      response => dispatch(getRecomendations()),
      error => {
        console.log('should handle ADD error')
        dispatch(getRecomendations())
      }
    )
  }
}

export const removeConcept = concept => removeConcepts([concept])
export const removeConcepts = concepts => {
  concepts = arrayify(concepts)
  return (dispatch, getState) => {
    dispatch({
      type: REMOVE_CONCEPTS,
      data: concepts
    })
    concepts.forEach(concept => {
      const currentProfile = getProfileFromState(getState())
      eventTracker.emitEvent('REMOVE_FROM_PROFILE', {
        id: concept.id,
        type: 'CONCEPT',
        resultingProfile: {
          ...currentProfile,
          concepts: currentProfile.concepts.filter(
            ({ id }) => !concepts.find(c => c.id === id)
          )
        }
      })
    })
    return API.removeConcepts(concepts).then(
      response => dispatch(getRecomendations()),
      error => {
        console.log('should handle REMOVE error')
        dispatch(getRecomendations())
      }
    )
  }
}

export const computeIngredients = () => {
  return (dispatch, getState) => {
    return getState().recomendations.filter(d => d.id > 100)
  }
}

export const replaceProfile = data => {
  return dispatch => {
    dispatch({
      type: REPLACE_DATA,
      data: data
    })
  }
}

export const markRecomendationsAsViewed = () => {
  return dispatch => {
    dispatch({
      type: MARK_RECOS_VIEWED
    })
  }
}

export const updateLastSentRecos = recos => {
  return (dispatch, getState) => {
    // eventTracker.emitEvent('RECOMMENDATION_FETCH', {
    //   resultingProfile: getProfileFromState(state),
    //   topResults: state.profile.recomendations.map(p => p.id)
    // })
    dispatch({
      type: UPDATE_LAST_SENT_RECOS,
      data: recos
    })
  }
}

export const shouldUpdateRecosSelector = state => {
  const current = state.profile.recomendations.map(r => r.id)
  const previous = state.profile.lastSentRecommendations.map(r => r.id)
  const shouldUpdateRecos =
    current.some(curr => !previous.find(prev => prev === curr)) ||
    previous.some(prev => !current.find(curr => curr === prev))

  return shouldUpdateRecos
}
