import React, { Component } from 'react'
import './PerfumesList.scss'
// import './PerfumesList_mobile.scss'
import PerfumeRow from './PerfumeRow'
import { isNullOrUndefined } from 'util'

class PerfumesList extends Component {
  state = {
    data: [],
    highlightedPerfume: isNullOrUndefined
  }
  // constructor () {
  //   super()
  //   this.scroller = React.createRef()
  // }

  // reScroll = () => {
  //   if (this.props.selectedPerfume) {
  //     let h = this.scroller.current.children[0].clientHeight
  //     let target =
  //       this.props.data.findIndex(d => d.id === this.props.selectedPerfume) *
  //         h -
  //       h
  //     if (Math.abs(this.scroller.current.scrollTop - target) > h * 1.5) {
  //       this.scroller.current.scrollTop = target
  //     }
  //   }
  // }
  onHighlight = (id, left) => {
    // let previousIndex = this.props.data.findIndex((d)=>d.id === this.state.highlightedPerfume)
    this.setState({ highlightedPerfume: id })
    // ESTA MAL LA POSICION, deberia ANIMAR!!!!
    // if (left) {
    //   let scrollParent = this.scroller.current.parentNode.parentNode.parentNode.parentNode.parentNode
    //   // PFFF, no va bien, porque cuando "lo hace" el anterior highlighted aun esta expanded,
    //   // HABRIA que hacerlo con "index*itemWidth" .... !
    //   let offset
    //   if(id) offset = left - this.scroller.current.clientWidth * 0.5 + this.scroller.current.clientWidth * 0.25// * (0.5-0.25)
    //   else offset = left - this.scroller.current.clientWidth * 0.5 + this.scroller.current.clientWidth * 0.17//- this.scroller.current.clientWidth * (0.5-0.17)
    //   scrollParent.scroll({
    //     left: offset,
    //     behavior: 'smooth'
    //   })
    // }
    // FUFUFUF, no sirve para el "olfactoryprofile" porque "falta un padre entremedias..."
    // let scrollParent = this.scroller.current.parentNode.parentNode.parentNode.parentNode.parentNode
    // let parentWidth = this.scroller.current.clientWidth
    // let itemWidth
    // let highlightedWidth
    // let index
    // if (!id){
    //   itemWidth = parentWidth * 0.5 + 20
    //   highlightedWidth = parentWidth * 0.5 + 20
    //   index = previousIndex
    // } else {
    //   itemWidth = parentWidth * 0.5 + 20
    //   highlightedWidth = parentWidth * 0.75 + 20
    //   index = this.props.data.findIndex((d)=>d.id === id)
    // }
    // scrollParent.scroll({
    //   left: index * itemWidth + highlightedWidth / 2 - parentWidth / 2 + (this.props.leftOffset || 0),
    //   behavior: 'smooth'
    // })
  }
  onSelect = (perfume,similairty,options) => {
    this.setState({ highlightedPerfume: null })
    this.props.onSelect && this.props.onSelect(perfume,similairty,options)
  }
  render () {
    // const {data, isLoading, error} = this.state;
    let rows = []
    let results = this.props.data
    const {isDownloaded,...otherProps} = this.props
    results.forEach(perfume => {
      let selectionState = null
      if (this.props.selectedPerfume) {
        selectionState =
          this.props.selectedPerfume === perfume.id ? 'selected' : 'unselected'
        // similarity = this.props.perfume.similarity
      }

      let similarity = this.props.similarity ? this.props.similarity : ''
      let perfume_id = perfume.id || perfume.perfume.id
      const showDownload = isDownloaded ? !isDownloaded(perfume_id): false
      const alreadyDownload = isDownloaded ? !showDownload : false
      rows.push(
        <PerfumeRow
          {...otherProps}
          showDownload = {showDownload}
          alreadyDownload = {alreadyDownload}
          data={perfume}
          key={perfume_id}
          onClick={this.props.onClick}
          // divStyle={style}
          similarity={similarity}
          selectionState={selectionState}
          mode={this.props.useTiles ? 'tiles' : 'row'}
          showSimilarity={this.props.showSimilarity}
          onSelect={this.onSelect}
          showCheckbox={this.props.showCheckbox}
          showSelectButton={false}
          onHighlight={this.props.highlightable && this.onHighlight}
          highlighted={this.state.highlightedPerfume === perfume_id}
        />
      )
    })

    rows = rows.slice(0, 100)

    return (
      <div
        className={
          'perfumes-list' +
          (this.props.collapsed ? ' collapsed' : '') +
          (this.props.useTiles ? ' tiles' : ' row')
        }
        onClick={this.props.onClick}
      >
        <div className='list-wrapper'>
          <div className='list' ref={this.scroller} >
            {rows}
          </div>
        </div>
        <div className='arrow' />
      </div>
    )
  }
}

export default PerfumesList
